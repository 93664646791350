import { put, takeEvery, call } from 'redux-saga/effects';
import {
  GET_DISEASE_SEARCH_RESULTS,
  GET_DISEASE_SEARCH_RESULTS_SUCCESS,
  GET_DISEASE_SEARCH_RESULTS_FAIL,
} from 'diseaseModule/redux/modules/getDiseaseSearchResultsModule';
import { getDiseaseSearchResultsCall } from 'diseaseModule/infrastructure/getDiseaseSearchResultsLogic';
import history from 'commonModule/helpers/history';
import { SLUGS } from 'coreModule/infrastructure/multiLanguageSlugs';

function* getDiseaseSearchResults(action) {
  const { response, error } = yield call(getDiseaseSearchResultsCall, action?.data || {});

  if (response) {
    yield put({ type: GET_DISEASE_SEARCH_RESULTS_SUCCESS, data: response.data });
    yield call(history.push, `/${SLUGS[process.env.RAZZLE_LANGUAGE_CODE].SEARCH_RESULTS_SLUG}`);
  } else {
    yield put({ type: GET_DISEASE_SEARCH_RESULTS_FAIL, data: error });
  }
}

function* getDiseaseSearchResultsSaga() {
  yield takeEvery(GET_DISEASE_SEARCH_RESULTS, getDiseaseSearchResults);
}
export default getDiseaseSearchResultsSaga;
