export const GET_DISEASE_LIST = 'disease/GET_DISEASE_LIST';
export const GET_DISEASE_LIST_SUCCESS = 'disease/GET_DISEASE_LIST_SUCCESS';
export const GET_DISEASE_LIST_FAIL = 'disease/GET_DISEASE_LIST_FAIL';

const initialState = {
  isProcessing: false,
  isProcessed: false,
  data: [],
  message: '',
  error: '',
};

const getDiseaseListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DISEASE_LIST:
      return {
        ...state,
        isProcessed: false,
        isProcessing: true,
      };
    case GET_DISEASE_LIST_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isProcessing: false,
        isProcessed: true,
        message: action.data.message,
        error: '',
      };
    }
    case GET_DISEASE_LIST_FAIL:
      return {
        ...state,
        isProcessing: false,
        isProcessed: false,
        message: '',
        error: action.data,
      };
    default:
      return state;
  }
};

export const getDiseaseListAction = data => ({
  type: GET_DISEASE_LIST,
  data,
});

export default getDiseaseListReducer;
