import React from 'react';
import PopupContainer from 'commonModule/containers/PopupContainer';

const PopupsList = ({ popupsList }) => {
  const popupsLength = popupsList.length;
  const activeElement = popupsLength
    ? <PopupContainer key={popupsList[popupsLength - 1].frontendId} {...popupsList[popupsLength - 1]} /> : null;
  return activeElement;
};

export default PopupsList;
