import React from 'react';
import { connect } from 'react-redux';
import AddPost from 'postModule/components/AddPost';
import { addSubPostAction } from 'postModule/redux/modules/addSubPostModule';

const HeaderContainer = props => <AddPost {...props} />;

const mapStateToProps = ({ post: { addSubPostReducer } }) => ({
  addSubPostReducer,
});

const mapDispatchToProps = {
  onSubmitAction: addSubPostAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HeaderContainer);

