import { put, takeEvery, call } from 'redux-saga/effects';
import {
  ADD_COMPLICATION,
  ADD_COMPLICATION_SUCCESS,
  ADD_COMPLICATION_FAIL,
} from 'complicationModule/redux/modules/addComplicationModule';
import { addComplicationCall } from 'complicationModule/infrastructure/addComplicationLogic';

function* addComplication(action) {
  const { response, error } = yield call(addComplicationCall, action.data);

  if (response) {
    yield put({ type: ADD_COMPLICATION_SUCCESS, data: response.data });
  } else {
    yield put({ type: ADD_COMPLICATION_FAIL, data: error });
  }
}

function* addComplicationSaga() {
  yield takeEvery(ADD_COMPLICATION, addComplication);
}
export default addComplicationSaga;
