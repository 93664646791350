import React from 'react';
import { connect } from 'react-redux';

import StaticPage from 'staticPageModule/components/pages/StaticPage';
import {
  GET_SINGLE_STATIC_PAGE_FAIL,
  GET_SINGLE_STATIC_PAGE_SUCCESS,
  getSingleStaticPageAction,
} from 'staticPageModule/redux/modules/getSingleStaticPageModule';
import getSlug from 'commonModule/helpers/getSlug';
import fetchInitialData from 'commonModule/helpers/fetchInitialData';
import { getSingleStaticPageCall } from 'staticPageModule/infrastructure/getSingleStaticPageLogic';

const StaticPagePageContainer = props => <StaticPage {...props} />;

const mapStateToProps = ({ staticPage: { getSingleStaticPageReducer } }) => ({
  getSingleStaticPageReducer,
});

const mapDispatchToProps = { getSingleStaticPageAction };

StaticPagePageContainer.getInitialProps = async ({
  req, res, match, history, location, store, ...ctx
}) => Promise.all([
  await fetchInitialData(
    store,
    () => getSingleStaticPageCall({ slug: getSlug(match.url) }),
    GET_SINGLE_STATIC_PAGE_SUCCESS,
    GET_SINGLE_STATIC_PAGE_FAIL,
    res,
  ),
]);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StaticPagePageContainer);
