import React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useTranslation } from 'react-i18next';
import { Facebook } from 'react-bootstrap-icons';
import { Button } from 'reactstrap';

import './styles.scss';

const SignInFacebook = ({ signInFacebookAction }) => {
  const responseFacebook = response => {
    signInFacebookAction(response);
  };
  const { t } = useTranslation();

  return (
    <div className="pb-3 sign-in-facebook">
      <FacebookLogin
        appId={process.env.NODE_ENV === 'development' ? process.env.RAZZLE_FB_APP_ID_DEV : process.env.RAZZLE_FB_APP_ID_PROD}
        callback={responseFacebook}
        render={renderProps => (
          <Button
            className="sign-in-facebook__button"
            size="lg"
            color="primary"
            onClick={renderProps.onClick}
            disabled={renderProps.isDisabled || renderProps.isProcessing || !renderProps.isSdkLoaded}
          >
            <Facebook />
            {t('logInFacebook.logInButton')}
          </Button>
        )}
      />
    </div>
  );
};

export default SignInFacebook;
