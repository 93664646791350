import React from 'react';
import { connect } from 'react-redux';
import withAuth from 'authModule/hocs/withAuth';
import AdminAddDiagnosisPage from 'diagnosisModule/components/pages/AdminAddDiagnosisPage';
import { getDiagnosisListAction } from 'diagnosisModule/redux/modules/getDiagnosisListModule';
import { addDiagnosisAction } from 'diagnosisModule/redux/modules/addDiagnosisModule';


const AdminAddDiagnosisPageContainer = props => <AdminAddDiagnosisPage {...props} />;

const mapStateToProps = ({ diagnosis: { getDiagnosisListReducer, addDiagnosisReducer } }) => ({
  getDiagnosisListReducer,
  addDiagnosisReducer,
});

const mapDispatchToProps = {
  getDiagnosisListAction,
  addDiagnosisAction,
};

export default withAuth(connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminAddDiagnosisPageContainer));
