import React from 'react';
import { connect } from 'react-redux';
import withAuth from 'authModule/hocs/withAuth';
import ProfilePage from 'userModule/components/pages/ProfilePage';
import { getUserProfileAction } from 'userModule/redux/modules/getUserProfileModule';

const ProfilePageContainer = props => <ProfilePage {...props} />;

const mapStateToProps = ({ user: { getUserProfileReducer }, auth: { signInUserReducer } }) => ({
  userProfileData: getUserProfileReducer.data,
  userData: signInUserReducer.userDetails,
});

const mapDispatchToProps = { getUserProfileAction };

export default withAuth(connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfilePageContainer));
