import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

const TargetModelPrefix = ({ modelEnum }) => {
  const { t } = useTranslation();
  return (
    <span className="text-center modelType">
      {t(`generic.modelTitlePrefix_${modelEnum}`)}
    </span>
  );
};

export default TargetModelPrefix;
