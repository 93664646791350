import addCauseSaga from './addCauseSaga';
import getCauseListSaga from './getCauseListSaga';
import getSingleCauseAdminSaga from './getSingleCauseAdminSaga';
import updateCauseSaga from './updateCauseSaga';
import getSingleCauseSaga from './getSingleCauseSaga';

export default [
  addCauseSaga(),
  getCauseListSaga(),
  getSingleCauseAdminSaga(),
  updateCauseSaga(),
  getSingleCauseSaga(),
];
