export const GET_SINGLE_DIAGNOSIS = 'disease/GET_SINGLE_DIAGNOSIS';
export const GET_SINGLE_DIAGNOSIS_SUCCESS = 'disease/GET_SINGLE_DIAGNOSIS_SUCCESS';
export const GET_SINGLE_DIAGNOSIS_FAIL = 'disease/GET_SINGLE_DIAGNOSIS_FAIL';
export const GET_SINGLE_DIAGNOSIS_RESET = 'disease/GET_SINGLE_DIAGNOSIS_RESET';

const initialState = {
  isProcessing: false,
  isProcessed: false,
  data: {},
  message: '',
  error: '',
};

const getSingleDiagnosisReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SINGLE_DIAGNOSIS: {
      const newDiagnosis = {
        slug: action.data.slug,
        data: [],
      };

      return {
        ...state,
        isProcessing: true,
        isProcessed: false,
        data: newDiagnosis,
      };
    }
    case GET_SINGLE_DIAGNOSIS_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        isProcessed: true,
        data: action.data,
      };
    }
    case GET_SINGLE_DIAGNOSIS_FAIL:
    case GET_SINGLE_DIAGNOSIS_RESET: {
      return {
        ...state,
        isProcessing: false,
        isProcessed: false,
        data: {},
        message: '',
        error: action?.data || '',
      };
    }
    default:
      return state;
  }
};

export const getSingleDiagnosisAction = data => ({
  type: GET_SINGLE_DIAGNOSIS,
  data,
});

export const getSingleDiagnosisResetAction = () => ({
  type: GET_SINGLE_DIAGNOSIS_RESET,
});

export default getSingleDiagnosisReducer;
