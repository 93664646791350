import API from 'coreModule/infrastructure/api';
import convertRelatedModelsToBadgeFriendly from 'postModule/helpers/convertRelatedModelsToBadgeFriendly';

export const getSinglePostCall = ({ slug }) => (
  API.request({
    method: 'get',
    url: `/post/${slug}`,
  })
    .then((response) => {
      if (response.data.relatedModels) {
        response.data.relatedModels = convertRelatedModelsToBadgeFriendly(response.data.relatedModels);
      }

      return ({ response });
    })
    .catch(error => {
      console.error('!error', error);
      return { error };
    }));
