export const ADD_COMPLICATION = 'complication/ADD_COMPLICATION';
export const ADD_COMPLICATION_SUCCESS = 'complication/ADD_COMPLICATION_SUCCESS';
export const ADD_COMPLICATION_FAIL = 'complication/ADD_COMPLICATION_FAIL';


const initialState = {
  isProcessing: false,
  isProcessed: false,
  data: null,
  message: '',
  error: '',
};

const addComplicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_COMPLICATION:
      return {
        ...state,
        isProcessed: false,
        isProcessing: true,
      };
    case ADD_COMPLICATION_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isProcessing: false,
        isProcessed: true,
        message: action.data.message,
        error: '',
      };
    }
    case ADD_COMPLICATION_FAIL:
      return {
        ...state,
        isProcessing: false,
        isProcessed: false,
        message: '',
        error: action.data,
      };
    default:
      return state;
  }
};

export const addComplicationAction = complicationName => ({
  type: ADD_COMPLICATION,
  data: complicationName,
});


export default addComplicationReducer;
