import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import Layout from 'commonModule/components/Layout';
import getSlug from 'commonModule/helpers/getSlug';

const AdminUpdateTreatmentPage = (
  {
    updateTreatmentAction,
    getSingleTreatmentAdminAction,
    history,
    getSingleTreatmentAdminReducer,
    updateTreatmentReducer,
  },
) => {
  const initialState = {
    formData: new FormData(),
    processedLanguageCode: '',
    treatment: {},
    slug: getSlug(history.location.pathname),
  };
  const [values, setValues] = useState(initialState);
  useEffect(() => {
    getSingleTreatmentAdminAction({ slug: getSlug(history.location.pathname), shouldGetFullData: true });
  }, [updateTreatmentReducer.isProcessed]);

  useEffect(() => {
    if (getSingleTreatmentAdminReducer.isProcessed || updateTreatmentReducer.isProcessed) {
      setValues({ ...values, treatment: getSingleTreatmentAdminReducer.data });
    }
  }, [getSingleTreatmentAdminReducer.isProcessed, updateTreatmentReducer.isProcessed]);

  const {
    formData, treatment, slug, processedLanguageCode,
  } = values;

  if (!getSingleTreatmentAdminReducer.isProcessed || isEmpty(treatment)) {
    return null;
  }

  const handleChange = (name, value, languageCode) => {
    if (languageCode) {
      const isExistingLanguageData = treatment.language.find(treatmentLanguageData => treatmentLanguageData.languageCode === languageCode);
      if (!isExistingLanguageData) {
        const newLanguageData = {
          languageCode,
          [name]: value,
        };
        return setValues({ ...values, treatment: { ...treatment, language: [...treatment.language, newLanguageData] } });
      }

      const treatmentLanguages = treatment.language.map(languageData => {
        if (languageData.languageCode === languageCode) {
          return {
            ...languageData,
            [name]: value,
          };
        }

        return languageData;
      });
      return setValues({ ...values, treatment: { ...treatment, language: treatmentLanguages } });
    }
  };

  const handleSubmit = (e, languageCode) => {
    e.preventDefault();
    const treatmentLanguageDataToUpdate = treatment.language.find(treatmentLanguageData => treatmentLanguageData.languageCode === languageCode);
    Object.keys(treatmentLanguageDataToUpdate).forEach(key => {
      formData.set([key], treatmentLanguageDataToUpdate[key]);
    });
    setValues({ ...values, processedLanguageCode: languageCode });
    updateTreatmentAction({ slug, updatedTreatmentData: formData });
  };

  const showError = () => (updateTreatmentReducer.error ? <div className="alert alert-danger">{updateTreatmentReducer.error}</div> : '');

  const renderFormForEachLanguage = () => {
    const supportedLanguagesArray = process.env.RAZZLE_SUPPORTED_LANGUAGES.split(',');
    return supportedLanguagesArray.map(languageCode => renderNewForm(treatment?.language
      ?.find(treatment => treatment.languageCode === languageCode), languageCode));
  };

  const renderNewForm = (treatmentData = {}, languageCode) => (
    <>
      <form onSubmit={e => handleSubmit(e, languageCode)}>
        <div className="form-group">
          <label className="text-muted">Language code</label>
          <input
            type="text"
            disabled
            className="form-control"
            value={languageCode}
            required
          />
        </div>
        <div className="form-group">
          <label className="text-muted">{`${languageCode} Treatment name`}</label>
          <input
            type="text"
            className="form-control"
            onChange={e => handleChange('name', e.target.value, languageCode)}
            value={treatmentData.name}
            required
          />
        </div>
        {(updateTreatmentReducer.isProcessed && processedLanguageCode === languageCode) && <p className="text-success">Treatment is updated</p>}
        <div>
          <button type="submit" className="btn btn-primary">
						Update
          </button>
        </div>
      </form>
      <hr />
    </>
  );

  return (
    <Layout>
      <h2 className="text-center pt-4 pb-4">Update treatment</h2>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            {showError()}
            {renderFormForEachLanguage()}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AdminUpdateTreatmentPage;
