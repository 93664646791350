import { combineReducers } from 'redux';
import getStaticPageListReducer from 'staticPageModule/redux/modules/getStaticPageListModule';
import addStaticPageReducer from 'staticPageModule/redux/modules/addStaticPageModule';
import updateStaticPageReducer from 'staticPageModule/redux/modules/updateStaticPageModule';
import getSingleStaticPageAdminReducer from 'staticPageModule/redux/modules/getSingleStaticPageAdminModule';
import getSingleStaticPageReducer from 'staticPageModule/redux/modules/getSingleStaticPageModule';


const staticPage = combineReducers({
  getStaticPageListReducer,
  addStaticPageReducer,
  updateStaticPageReducer,
  getSingleStaticPageAdminReducer,
  getSingleStaticPageReducer,
});

export default staticPage;
