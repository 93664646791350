import addTreatmentSaga from './addTreatmentSaga';
import getTreatmentListSaga from './getTreatmentListSaga';
import getSingleTreatmentAdminSaga from './getSingleTreatmentAdminSaga';
import updateTreatmentSaga from './updateTreatmentSaga';
import getSingleTreatmentSaga from './getSingleTreatmentSaga';

export default [
  addTreatmentSaga(),
  getTreatmentListSaga(),
  getSingleTreatmentAdminSaga(),
  updateTreatmentSaga(),
  getSingleTreatmentSaga(),
];
