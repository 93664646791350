import React from 'react';
import { connect } from 'react-redux';
import { addVoteAction } from 'voteModule/redux/modules/addVoteModule';
import VoteButton from 'voteModule/components/VoteButton';

const VoteButtonContainer = props => <VoteButton {...props} />;

const mapStateToProps = ({ vote: { addVoteReducer }, auth: { signInUserReducer: { isAuth } } }) => ({
  addVoteReducer,
  isAuth,
});


const mapDispatchToProps = {
  addVoteAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VoteButtonContainer);
