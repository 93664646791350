import React from 'react';
import Badge from 'commonModule/components/Badge';
import './styles.scss';

const BadgeList = ({ data }) => (
  <div className="badge-list">
    {data.map((badge) => <Badge {...badge} key={badge.link + badge.label} />)}
  </div>
);

export default BadgeList;
