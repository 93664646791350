import React from 'react';
import { connect } from 'react-redux';
import SignInFacebook from 'authModule/components/SignInFacebook/SignInFacebook';
import { signInFacebookAction } from 'authModule/redux/modules/signInUserModule';

const SignInFacebookContainer = props => (
  <SignInFacebook {...props} />
);

const mapStateToProps = ({ auth: { signInUserReducer } }) => ({
  signInUserReducer,
});

const mapDispatchToProps = {
  signInFacebookAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignInFacebookContainer);

