import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

const options = {
  fallbackLng: process.env.RAZZLE_LANGUAGE_CODE,
  load: 'languageOnly',
  ns: ['translation'],
  defaultNS: 'translation',
  preload: [process.env.RAZZLE_LANGUAGE_CODE],
  lng: process.env.RAZZLE_LANGUAGE_CODE,
  saveMissing: true,
  debug: true,
  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
    format: (value, format, lng) => {
      if (format === 'uppercase') return value.toUpperCase();
      return value;
    },
  },
  wait: process && !process.release,
};

// for browser use http backend to load translations and browser lng detector
if (process && !process.release) {
  i18n
    .use(Backend)
    .use(initReactI18next);
}

// initialize if not already initialized
if (!i18n.isInitialized) {
  i18n.init(options);
}

export default i18n;
