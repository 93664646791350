import React from 'react';
import { connect } from 'react-redux';

import withAuth from 'authModule/hocs/withAuth';
import AdminCategoryListPage from 'categoryModule/components/pages/AdminCategoryListPage';
import { getCategoryListAction } from 'categoryModule/redux/modules/getCategoryListModule';


const AdminCategoryListPageContainer = props => <AdminCategoryListPage {...props} />;

const mapStateToProps = ({ category: { getCategoryListReducer } }) => ({
  getCategoryListReducer,
});

const mapDispatchToProps = { getCategoryListAction };

export default withAuth(connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminCategoryListPageContainer));
