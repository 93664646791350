export const GET_POST_LIST = 'post/GET_POST_LIST';
export const GET_POST_LIST_SUCCESS = 'post/GET_POST_LIST_SUCCESS';
export const GET_POST_LIST_FAIL = 'post/GET_POST_LIST_FAIL';

const initialState = {
  isProcessing: false,
  isProcessed: false,
  data: {},
  message: '',
  error: '',
};

const getPostListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_POST_LIST: {
      return {
        ...state,
        isProcessing: true,
        isProcessed: false,
      };
    }
    case GET_POST_LIST_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        isProcessed: true,
        data: action.data,
      };
    }
    default:
      return state;
  }
};

export const getPostListAction = (data) => ({
  type: GET_POST_LIST,
  data,
});

export default getPostListReducer;
