import React from 'react';
import { connect } from 'react-redux';

import withAuth from 'authModule/hocs/withAuth';
import AdminUpdateSymptomPage from 'symptomModule/components/pages/AdminUpdateSymptomPage';
import { updateSymptomAction } from 'symptomModule/redux/modules/updateSymptomModule';
import { getSingleSymptomAdminAction } from 'symptomModule/redux/modules/getSingleSymptomAdminModule';
import { getSymptomListAction } from 'symptomModule/redux/modules/getSymptomListModule';


const AdminUpdateSymptomPageContainer = props => <AdminUpdateSymptomPage {...props} />;

const mapStateToProps = ({ symptom: { getSingleSymptomAdminReducer, updateSymptomReducer, getSymptomListReducer } }) => ({
  getSingleSymptomAdminReducer,
  updateSymptomReducer,
  getSymptomListReducer,
});

const mapDispatchToProps = {
  updateSymptomAction,
  getSymptomListAction,
  getSingleSymptomAdminAction,
};

export default withAuth(connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminUpdateSymptomPageContainer));
