import API from 'coreModule/infrastructure/api';

export const getDiseaseSearchResultsCall = (symptoms) => API.request({
  method: 'get',
  url: '/diseases/search',
  params: {
    symptoms,
  },
})
  .then(response => ({ response }))
  .catch(error => ({ error: error?.response?.data?.error }));
