export const UPDATE_CAUSE = 'cause/UPDATE_CAUSE';
export const UPDATE_CAUSE_SUCCESS = 'cause/UPDATE_CAUSE_SUCCESS';
export const UPDATE_CAUSE_FAIL = 'cause/UPDATE_CAUSE_FAIL';


const initialState = {
  isProcessing: false,
  isProcessed: false,
  message: '',
  error: '',
};

const updateCauseReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CAUSE:
      return {
        ...state,
        isProcessed: false,
        isProcessing: true,
      };
    case UPDATE_CAUSE_SUCCESS: {
      return {
        ...state,
        isProcessed: true,
        isProcessing: false,
      };
    }
    case UPDATE_CAUSE_FAIL:
      return {
        ...state,
        isProcessing: false,
        isProcessed: false,
        message: '',
        error: action.data,
      };
    default:
      return state;
  }
};

export const updateCauseAction = data => ({
  type: UPDATE_CAUSE,
  data,
});


export default updateCauseReducer;
