import API from 'coreModule/infrastructure/api';
import { getCookie } from 'authModule/infrastructure/signInUserLogic';

export const addCauseCall = newCauseData => {
  const token = getCookie('token');
  return API.request({
    method: 'post',
    url: '/cause',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: { ...newCauseData, token, languageCode: process.env.RAZZLE_CREATE_NEW_ELEMENT_LANGUAGE_CODE },
  })
    .then(response => ({ response }))
    .catch(error => ({ error: error?.response?.data?.error }));
};
