import { put, takeEvery, call } from 'redux-saga/effects';
import {
  ADD_PREVENTION,
  ADD_PREVENTION_SUCCESS,
  ADD_PREVENTION_FAIL,
} from 'preventionModule/redux/modules/addPreventionModule';
import { addPreventionCall } from 'preventionModule/infrastructure/addPreventionLogic';


function* addPrevention(action) {
  const { response, error } = yield call(addPreventionCall, action.data);

  if (response) {
    yield put({ type: ADD_PREVENTION_SUCCESS, data: response.data });
  } else {
    yield put({ type: ADD_PREVENTION_FAIL, data: error });
  }
}

function* addPreventionSaga() {
  yield takeEvery(ADD_PREVENTION, addPrevention);
}
export default addPreventionSaga;
