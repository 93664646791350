import React from 'react';
import Layout from 'commonModule/components/Layout';
import SignInFormContainer from 'authModule/containers/SignInFormContainer';
import SignInGoogleContainer from 'authModule/containers/SignInGoogleContainer';
import SignInFacebookContainer from 'authModule/containers/SignInFacebookContainer';
import { useTranslation } from 'react-i18next';
import { Alert } from 'reactstrap';

import './styles.scss';

const SignInPage = () => {
  const { t } = useTranslation();
  const renderClassicSignInForm = () => {
    if (!process.browser || !JSON.parse(localStorage?.getItem('DEV'))) {
      return null;
    }
    return 	<SignInFormContainer />;
  };

  return (
    <Layout className="sign-in-page">
      <h2 className="text-center pt-4 pb-4">{t('logInPage.title')}</h2>
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3 sign-in-page__content">
            <Alert color="primary">{t('logInPage.privacyDisclaimer')}</Alert>
            {renderClassicSignInForm()}
            <SignInGoogleContainer />
            <SignInFacebookContainer />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SignInPage;
