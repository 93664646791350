import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import history from 'commonModule/helpers/history';
import { SLUGS } from 'coreModule/infrastructure/multiLanguageSlugs';

const withoutAuth = Component => props => {
  const { isAuth } = useSelector(state => state.auth.signInUserReducer);
  useEffect(
    () => {
      if (isAuth) {
        history.push(SLUGS[process.env.RAZZLE_LANGUAGE_CODE].PROFILE_SLUG);
      }
    },
    [isAuth],
  );

  return <Component {...props} />;
};

export default withoutAuth;
