import React from 'react';
import { Link } from 'react-router-dom';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { isArray } from 'lodash';
import createUrl from 'commonModule/helpers/createUrl';

const List = ({
  data = [], isAdmin, modelType, withLink = true,
}) => {
  if (!data || !isArray(data)) {
    return null;
  }

  const renderItem = (listItem) => {
    const item = listItem.name || listItem.title;

    if (!withLink) {
      return item;
    }

    return (
      <Link to={createUrl(modelType, isAdmin, listItem.slug)}>
        {item}
      </Link>
    );
  };

  return (
    <ListGroup>
      {data?.map((listItem) => (
        <ListGroupItem key={listItem.name || listItem.title}>
          {renderItem(listItem)}
        </ListGroupItem>
      ))}
    </ListGroup>
  );
};

export default List;
