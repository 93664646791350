import React from 'react';
import { connect } from 'react-redux';
import withAuth from 'authModule/hocs/withAuth';
import AdminAddComplicationPage from 'complicationModule/components/pages/AdminAddComplicationPage';
import { getComplicationListAction } from 'complicationModule/redux/modules/getComplicationListModule';
import { addComplicationAction } from 'complicationModule/redux/modules/addComplicationModule';


const AdminAddComplicationPageContainer = props => <AdminAddComplicationPage {...props} />;

const mapStateToProps = ({ complication: { getComplicationListReducer, addComplicationReducer } }) => ({
  getComplicationListReducer,
  addComplicationReducer,
});

const mapDispatchToProps = {
  getComplicationListAction,
  addComplicationAction,
};

export default withAuth(connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminAddComplicationPageContainer));
