import React, { useState, useEffect } from 'react';
import Layout from 'commonModule/components/Layout';

const AdminAddTreatmentPage = ({
  getTreatmentListAction, getTreatmentListReducer, addTreatmentAction, addTreatmentReducer,
}) => {
  const [values, setValues] = useState({
    newTreatmentName: '',
  });
  const { newTreatmentName } = values;

  useEffect(() => {
    getTreatmentListAction();
  }, []);

  useEffect(() => {
    if (addTreatmentReducer.isProcessed) {
      getTreatmentListAction();
    }
  }, [addTreatmentReducer.isProcessed]);

  const showTreatments = () => {
    if (getTreatmentListReducer.isProcessing || !getTreatmentListReducer.isProcessed) {
      return null;
    }

    return getTreatmentListReducer.data.map(treatment => (
      <button
        title="Double click to delete"
        key={treatment._id}
        className="btn btn-outline-primary mr-1 ml-1 mt-3"
      >
        {treatment.name}
      </button>
    ));
  };

  const handleChange = e => {
    setValues({ ...values, newTreatmentName: e.target.value });
  };

  const clickSubmit = e => {
    e.preventDefault();
    setValues({ ...values, newTreatmentName: '' });
    addTreatmentAction({ name: newTreatmentName });
  };

  const newTreatmentForm = () => (
    <form onSubmit={clickSubmit}>
      <div className="form-group">
        <label className="text-muted">Add new treatment name</label>
        <input type="text" className="form-control" onChange={handleChange} value={newTreatmentName} required />
      </div>
      <div>
        <button type="submit" className="btn btn-primary">
                    Create
        </button>
      </div>
    </form>
  );

  return (
    <Layout>
      <h2 className="text-center pt-4 pb-4">Manage Treatment</h2>
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            {newTreatmentForm()}
            {showTreatments()}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AdminAddTreatmentPage;
