import React from 'react';
import { connect } from 'react-redux';

import withAuth from 'authModule/hocs/withAuth';
import AdminUpdateTreatmentPage from 'treatmentModule/components/pages/AdminUpdateTreatmentPage';
import { updateTreatmentAction } from 'treatmentModule/redux/modules/updateTreatmentModule';
import { getSingleTreatmentAdminAction } from 'treatmentModule/redux/modules/getSingleTreatmentAdminModule';


const AdminUpdateTreatmentPageContainer = props => <AdminUpdateTreatmentPage {...props} />;

const mapStateToProps = ({ treatment: { getSingleTreatmentAdminReducer, updateTreatmentReducer } }) => ({
  getSingleTreatmentAdminReducer,
  updateTreatmentReducer,
});

const mapDispatchToProps = {
  updateTreatmentAction,
  getSingleTreatmentAdminAction,
};

export default withAuth(connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminUpdateTreatmentPageContainer));
