import React from 'react';
import { connect } from 'react-redux';
import AddPost from 'postModule/components/AddPost';
import { addPostAction } from 'postModule/redux/modules/addPostModule';

const HeaderContainer = props => <AddPost {...props} isPost />;

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  onSubmitAction: addPostAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HeaderContainer);
