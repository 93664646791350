import { combineReducers } from 'redux';
import auth from 'authModule/redux/modules';
import category from 'categoryModule/redux/modules';
import symptom from 'symptomModule/redux/modules';
import disease from 'diseaseModule/redux/modules';
import post from 'postModule/redux/modules';
import vote from 'voteModule/redux/modules';
import common from 'commonModule/redux/modules';
import user from 'userModule/redux/modules';
import complication from 'complicationModule/redux/modules';
import diagnosis from 'diagnosisModule/redux/modules';
import cause from 'causeModule/redux/modules';
import treatment from 'treatmentModule/redux/modules';
import prevention from 'preventionModule/redux/modules';
import staticPage from 'staticPageModule/redux/modules';

const rootReducer = combineReducers({
  common,
  auth,
  category,
  symptom,
  disease,
  post,
  vote,
  user,
  complication,
  diagnosis,
  cause,
  treatment,
  prevention,
  staticPage,
});

export default rootReducer;
