import API from 'coreModule/infrastructure/api';

export const getSingleDiseaseCall = ({ slug, shouldGetFullDiseaseData = false }) => API.request({
  method: 'get',
  url: `/disease/${slug}`,
  params: {
    shouldGetFullDiseaseData,
  },
})
  .then(response => ({ response }))
  .catch(error => ({ error }));
