import React from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import Layout from 'commonModule/components/Layout';

const AdminDashboardPage = () => (
  <Layout>
    <h2 className="text-center pt-4 pb-4">Admin Dashboard</h2>
    <div className="container">
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <ListGroup>
            <ListGroupItem>
              <Link to="/admin/add-static-page">Add Static Page</Link>
            </ListGroupItem>
            <ListGroupItem>
              <Link to="/admin/static-page-list">Static Page List</Link>
            </ListGroupItem>
            <hr />
            <ListGroupItem>
              <Link to="/admin/add-disease">Add Disease</Link>
            </ListGroupItem>
            <ListGroupItem>
              <Link to="/admin/disease-list">Disease List</Link>
            </ListGroupItem>
            <hr />
            <ListGroupItem>
              <Link to="/admin/add-category">Add Category</Link>
            </ListGroupItem>
            <ListGroupItem>
              <Link to="/admin/category-list">Category List</Link>
            </ListGroupItem>
            <hr />
            <ListGroupItem>
              <Link to="/admin/add-symptom">Add Symptom</Link>
            </ListGroupItem>
            <ListGroupItem>
              <Link to="/admin/symptom-list">Symptom List</Link>
            </ListGroupItem>
            <hr />
            <ListGroupItem>
              <Link to="/admin/add-complication">Add Complication</Link>
            </ListGroupItem>
            <ListGroupItem>
              <Link to="/admin/complication-list">Complication List</Link>
            </ListGroupItem>
            <hr />
            <ListGroupItem>
              <Link to="/admin/add-cause">Add Cause</Link>
            </ListGroupItem>
            <ListGroupItem>
              <Link to="/admin/cause-list">Cause List</Link>
            </ListGroupItem>
            <hr />
            <ListGroupItem>
              <Link to="/admin/add-prevention">Add Prevention</Link>
            </ListGroupItem>
            <ListGroupItem>
              <Link to="/admin/prevention-list">Prevention List</Link>
            </ListGroupItem>
            <hr />
            <ListGroupItem>
              <Link to="/admin/add-diagnosis">Add Diagnosis</Link>
            </ListGroupItem>
            <ListGroupItem>
              <Link to="/admin/diagnosis-list">Diagnosis List</Link>
            </ListGroupItem>
            <hr />
            <ListGroupItem>
              <Link to="/admin/add-treatment">Add Treatment</Link>
            </ListGroupItem>
            <ListGroupItem>
              <Link to="/admin/treatment-list">Treatment List</Link>
            </ListGroupItem>
          </ListGroup>
        </div>
      </div>
    </div>
  </Layout>
);

export default AdminDashboardPage;
