import React from 'react';
import { connect } from 'react-redux';
import PopupsList from 'commonModule/components/PopupsList/PopupsList';

const Container = props => (
  <PopupsList {...props} />
);

const mapStateToProps = ({ common: { popupsReducer: { popupsList } } }) => ({
  popupsList,
});


const PopupsListContainer = connect(mapStateToProps, null)(Container);
export default PopupsListContainer;
