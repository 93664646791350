export const GET_SINGLE_POST = 'post/GET_SINGLE_POST';
export const GET_SINGLE_POST_SUCCESS = 'post/GET_SINGLE_POST_SUCCESS';
export const GET_SINGLE_POST_FAIL = 'post/GET_SINGLE_POST_FAIL';

const initialState = {
  isProcessing: false,
  isProcessed: false,
  data: {},
  message: '',
  error: '',
};

const getSinglePostReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SINGLE_POST: {
      /*     const newPost = {
        slug: action.data.slug,
        data: [],
      }; */

      return {
        ...state,
        isProcessing: true,
        isProcessed: false,
        // data: newPost,
      };
    }
    case GET_SINGLE_POST_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        isProcessed: true,
        data: action.data,
      };
    }
    default:
      return state;
  }
};

export const getSinglePostAction = (data) => ({
  type: GET_SINGLE_POST,
  data,
});

export default getSinglePostReducer;
