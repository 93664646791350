import getDiseaseListSaga from 'diseaseModule/redux/sagas/getDiseaseListSaga';
import addDiseaseSaga from 'diseaseModule/redux/sagas/addDiseaseSaga';
import getSingleDiseaseSaga from 'diseaseModule/redux/sagas/getSingleDiseaseSaga';
import getSingleDiseaseAdminSaga from 'diseaseModule/redux/sagas/getSingleDiseaseAdminSaga';
import updateDiseaseSaga from 'diseaseModule/redux/sagas/updateDiseaseSaga';
import getDiseaseSearchResultsSaga from 'diseaseModule/redux/sagas/getDiseaseSearchResultsSaga';

export default [
  getDiseaseListSaga(),
  addDiseaseSaga(),
  getSingleDiseaseSaga(),
  getSingleDiseaseAdminSaga(),
  updateDiseaseSaga(),
  getDiseaseSearchResultsSaga(),
];

