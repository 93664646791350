import { put, takeEvery, call } from 'redux-saga/effects';
import {
  ADD_CATEGORY,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_FAIL,
} from 'categoryModule/redux/modules/addCategoryModule';
import { addCategoryCall } from 'categoryModule/infrastructure/addCategoryLogic';

function* addCategory(action) {
  const { response, error } = yield call(addCategoryCall, action.data);

  if (response) {
    yield put({ type: ADD_CATEGORY_SUCCESS, data: response.data });
  } else {
    yield put({ type: ADD_CATEGORY_FAIL, data: error });
  }
}

function* addCategorySaga() {
  yield takeEvery(ADD_CATEGORY, addCategory);
}
export default addCategorySaga;
