import React, { useState } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';

import './styles.scss';

const Popup = (
  {
    title,
    message,
    label,
    closeText,
    onCloseClicked,
    primaryButtonAction,
    onCustomButtonClicked,
    content,
  },
) => {
  const [modal, setModal] = useState(true);
  const toggle = () => setModal(!modal);
  const handleClick = (callback) => {
    toggle();
    setTimeout(() => callback(), 300);
  };

  return (
    <Modal isOpen={modal} toggle={() => handleClick(onCloseClicked)} className="popup">
      <ModalHeader>{title}</ModalHeader>
      <ModalBody className="popup__body">
        {message && message}
        {content}
      </ModalBody>
      <ModalFooter>
        {primaryButtonAction.type && (
          `${<Button color="primary" onClick={() => handleClick(onCustomButtonClicked)}>{label}</Button>} `
        )}
        <Button color="secondary" onClick={() => handleClick(onCloseClicked)}>{closeText}</Button>
      </ModalFooter>
    </Modal>
  );
};

export default Popup;
