import React from 'react';
import { connect } from 'react-redux';
import { closePopupAction } from 'commonModule/redux/modules/popupsModule';
import Popup from 'commonModule/components/Popup/Popup';

const Container = props => (
  <Popup {...props} />
);

const mapDispatchToProps = (dispatch, { frontendId, actionLink }) => ({
  onCloseClicked: () => dispatch(closePopupAction(frontendId)),
  onCustomButtonClicked: (primaryButtonAction) => dispatch({
    type: primaryButtonAction.type,
    data: { popupFrontendId: frontendId, actionLink, ...primaryButtonAction.data },
  }),
});

const PopupContainer = connect(null, mapDispatchToProps)(Container);
export default PopupContainer;
