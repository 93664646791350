import addSymptomSaga from './addSymptomSaga';
import getSymptomListSaga from './getSymptomListSaga';
import getSingleSymptomAdminSaga from './getSingleSymptomAdminSaga';
import updateSymptomSaga from './updateSymptomSaga';
import getSingleSymptomSaga from './getSingleSymptomSaga';

export default [
  addSymptomSaga(),
  getSymptomListSaga(),
  getSingleSymptomAdminSaga(),
  updateSymptomSaga(),
  getSingleSymptomSaga(),
];
