import React from 'react';
import { Button } from 'reactstrap';
import { ArrowUpSquare, ArrowDownSquare } from 'react-bootstrap-icons';

const VoteButton = ({
  isSubPostId, isPositive, targetId, targetModel, addVoteAction, handleRunningValues, isAuth,
}) => {
  const buttonContent = isPositive ? <ArrowUpSquare /> : <ArrowDownSquare />;
  const onClickPayload = {
    isSubPostId, isPositive, targetId, targetModel,
  };

  const handleVote = () => {
    if (isAuth) {
      handleRunningValues(isPositive);
    }
    addVoteAction(onClickPayload);
  };

  return (
    <Button color="link" className="d-flex" onClick={handleVote}>{buttonContent}</Button>
  );
};

export default VoteButton;
