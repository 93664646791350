import React from 'react';
import { connect } from 'react-redux';

import withAuth from 'authModule/hocs/withAuth';
import AdminComplicationListPage from 'complicationModule/components/pages/AdminComplicationListPage';
import { getComplicationListAction } from 'complicationModule/redux/modules/getComplicationListModule';


const AdminComplicationListPageContainer = props => <AdminComplicationListPage {...props} />;

const mapStateToProps = ({ complication: { getComplicationListReducer } }) => ({
  getComplicationListReducer,
});

const mapDispatchToProps = { getComplicationListAction };

export default withAuth(connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminComplicationListPageContainer));
