import cookie from 'js-cookie';
import API from 'coreModule/infrastructure/api';


export const signInUserCall = user => API.request({
  method: 'post',
  url: '/signin',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  data: user,
})
  .then(response => ({ response }))
  .catch(error => ({ error: error?.response?.data?.error }));

// set cookie
export const setCookie = (key, value) => {
  if (process.browser) {
    cookie.set(key, value, {
      expires: 1,
    });
  }
};

// get cookie
export const getCookie = key => {
  if (process.browser) {
    return cookie.get(key);
  }
};

// localstorage
export const setLocalStorage = (key, value) => {
  if (process.browser) {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

// autheticate user by pass data to cookie and localstorage
export const authenticate = data => {
  setCookie('token', data.token);
  setLocalStorage('user', data.user);
};

export const isAuth = () => {
  if (process.browser) {
    const cookieChecked = getCookie('token');
    if (cookieChecked) {
      if (localStorage.getItem('user')) {
        return JSON.parse(localStorage.getItem('user'));
      }
      return false;
    }
  }
};

export const signInGoogleUserCall = user => API.request({
  method: 'post',
  url: '/google-login',
  data: user,
})
  .then(response => ({ response }))
  .catch(error => ({ error: error?.response?.data?.error }));

export const signInFacebookCall = user => API.request({
  method: 'post',
  url: '/facebook-login',
  data: user,
})
  .then(response => ({ response }))
  .catch(error => ({ error: error?.response?.data?.error }));
