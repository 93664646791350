import { put, takeEvery, call } from 'redux-saga/effects';
import {
  GET_SINGLE_STATIC_PAGE_ADMIN,
  GET_SINGLE_STATIC_PAGE_ADMIN_SUCCESS,
  GET_SINGLE_STATIC_PAGE_ADMIN_FAIL,
} from 'staticPageModule/redux/modules/getSingleStaticPageAdminModule';
import { getSingleStaticPageCall } from 'staticPageModule/infrastructure/getSingleStaticPageLogic';

function* getSingleStaticPageAdmin(action) {
  const { response, error } = yield call(getSingleStaticPageCall, action.data);

  if (response) {
    yield put({ type: GET_SINGLE_STATIC_PAGE_ADMIN_SUCCESS, data: response.data });
  } else {
    yield put({ type: GET_SINGLE_STATIC_PAGE_ADMIN_FAIL, data: error });
  }
}

function* getSingleStaticPageAdminSaga() {
  yield takeEvery(GET_SINGLE_STATIC_PAGE_ADMIN, getSingleStaticPageAdmin);
}
export default getSingleStaticPageAdminSaga;
