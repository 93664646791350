import React from 'react';
import { Spinner } from 'reactstrap';

import './styles.scss';

const Loader = ({ isFullscreen }) => {
  const renderFullScreen = () => {
    if (isFullscreen) {
      return (
        <div className="fullscreen">
          <Spinner className="loader" color="primary" type="grow" style={{ height: '60px', width: '60px' }} />
        </div>
      );
    }

    return <Spinner className="loader" color="primary" type="grow" />;
  };


  return (
    renderFullScreen()
  );
};

export default Loader;
