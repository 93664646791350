import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import Layout from 'commonModule/components/Layout';
import getSlug from 'commonModule/helpers/getSlug';

const AdminUpdateStaticPagePage = (
  {
    updateStaticPageAction,
    getSingleStaticPageAdminAction,
    history,
    getSingleStaticPageAdminReducer,
    updateStaticPageReducer,
  },
) => {
  const initialState = {
    formData: new FormData(),
    processedLanguageCode: '',
    staticPage: {},
    slug: getSlug(history.location.pathname),
  };
  const [values, setValues] = useState(initialState);
  useEffect(() => {
    getSingleStaticPageAdminAction({ slug: getSlug(history.location.pathname), shouldGetFullData: true });
  }, [updateStaticPageReducer.isProcessed]);

  useEffect(() => {
    if (getSingleStaticPageAdminReducer.isProcessed || updateStaticPageReducer.isProcessed) {
      setValues({ ...values, staticPage: getSingleStaticPageAdminReducer.data });
    }
  }, [getSingleStaticPageAdminReducer.isProcessed, updateStaticPageReducer.isProcessed]);

  const {
    formData, staticPage, slug, processedLanguageCode,
  } = values;

  if (!getSingleStaticPageAdminReducer.isProcessed || isEmpty(staticPage)) {
    return null;
  }

  const handleChange = (name, value, languageCode) => {
    if (languageCode) {
      const isExistingLanguageData = staticPage.language.find(staticPageLanguageData => staticPageLanguageData.languageCode === languageCode);
      if (!isExistingLanguageData) {
        const newLanguageData = {
          languageCode,
          [name]: value,
        };
        return setValues({ ...values, staticPage: { ...staticPage, language: [...staticPage.language, newLanguageData] } });
      }

      const staticPageLanguages = staticPage.language.map(languageData => {
        if (languageData.languageCode === languageCode) {
          return {
            ...languageData,
            [name]: value,
          };
        }

        return languageData;
      });

      return setValues({ ...values, staticPage: { ...staticPage, language: staticPageLanguages } });
    }
  };

  const handleSubmit = (e, languageCode) => {
    e.preventDefault();
    const staticPageLanguageDataToUpdate = staticPage.language.find(staticPageLanguageData => staticPageLanguageData.languageCode === languageCode);
    Object.keys(staticPageLanguageDataToUpdate).forEach(key => {
      formData.set([key], staticPageLanguageDataToUpdate[key]);
    });
    setValues({ ...values, processedLanguageCode: languageCode });
    updateStaticPageAction({ slug, updatedStaticPageData: formData });
  };

  const showError = () => (updateStaticPageReducer.error ? <div className="alert alert-danger">{updateStaticPageReducer.error}</div> : '');

  const renderFormForEachLanguage = () => {
    const supportedLanguagesArray = process.env.RAZZLE_SUPPORTED_LANGUAGES.split(',');
    return supportedLanguagesArray.map(languageCode => renderNewForm(staticPage?.language
      ?.find(staticPage => staticPage.languageCode === languageCode), languageCode));
  };

  const renderNewForm = (staticPageData = {}, languageCode) => (
    <>
      <form onSubmit={e => handleSubmit(e, languageCode)}>
        <div className="form-group">
          <label className="text-muted">Language code</label>
          <input
            type="text"
            disabled
            className="form-control"
            value={languageCode}
            required
          />
        </div>
        <div className="form-group">
          <label className="text-muted">{`${languageCode} StaticPage title`}</label>
          <input
            type="text"
            className="form-control"
            onChange={e => handleChange('title', e.target.value, languageCode)}
            value={staticPageData.title}
            required
          />
          <div className="form-group">
            <label className="text-muted">{`${languageCode} StaticPage content`}</label>
            <textarea
              className="form-control"
              onChange={e => handleChange('content', e.target.value, languageCode)}
              value={staticPageData.content}
              required
            />
          </div>
        </div>
        {(updateStaticPageReducer.isProcessed && processedLanguageCode === languageCode) && <p className="text-success">StaticPage is updated</p>}
        <div>
          <button type="submit" className="btn btn-primary">
						Update
          </button>
        </div>
      </form>
      <hr />
    </>
  );

  return (
    <Layout>
      <h2 className="text-center pt-4 pb-4">Update staticPage</h2>
      <div className="container">
        <div className="row">
          <div className="col-12">
            {showError()}
            {renderFormForEachLanguage()}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AdminUpdateStaticPagePage;
