import React from 'react';
import { connect } from 'react-redux';
import withAuth from 'authModule/hocs/withAuth';
import AdminAddTreatmentPage from 'treatmentModule/components/pages/AdminAddTreatmentPage';
import { getTreatmentListAction } from 'treatmentModule/redux/modules/getTreatmentListModule';
import { addTreatmentAction } from 'treatmentModule/redux/modules/addTreatmentModule';


const AdminAddTreatmentPageContainer = props => <AdminAddTreatmentPage {...props} />;

const mapStateToProps = ({ treatment: { getTreatmentListReducer, addTreatmentReducer } }) => ({
  getTreatmentListReducer,
  addTreatmentReducer,
});

const mapDispatchToProps = {
  getTreatmentListAction,
  addTreatmentAction,
};

export default withAuth(connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminAddTreatmentPageContainer));
