import React from 'react';
import { connect } from 'react-redux';

import withAuth from 'authModule/hocs/withAuth';
import AdminPreventionListPage from 'preventionModule/components/pages/AdminPreventionListPage';
import { getPreventionListAction } from 'preventionModule/redux/modules/getPreventionListModule';


const AdminPreventionListPageContainer = props => <AdminPreventionListPage {...props} />;

const mapStateToProps = ({ prevention: { getPreventionListReducer } }) => ({
  getPreventionListReducer,
});

const mapDispatchToProps = { getPreventionListAction };

export default withAuth(connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminPreventionListPageContainer));
