import React from 'react';
import Layout from 'commonModule/components/Layout';
import List from 'commonModule/components/List';
import { Jumbotron } from 'reactstrap';
import modelEnums from 'commonModule/infrastructure/enums/modelEnums';
import { useTranslation } from 'react-i18next';

const SearchResults = ({ searchResults = [] }) => {
  const { t } = useTranslation();
  return (
    <Layout>
      <Jumbotron>
        <div className="container">
          <div className="row">
            <div className="col-8 offset-2">
              <h1 className="pt-4 pb-4 display-4">
                {t('resultsPage.title')}
:
              </h1>
            </div>
          </div>
        </div>
      </Jumbotron>
      <div className="container">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            {searchResults.length ? (
              <List data={searchResults} modelType={modelEnums.Disease} />
            ) : (
              <p>{t('resultsPage.noDiseasesFound')}</p>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SearchResults;
