import { put, takeEvery, call } from 'redux-saga/effects';
import {
  GET_SINGLE_SYMPTOM_ADMIN,
  GET_SINGLE_SYMPTOM_ADMIN_SUCCESS,
  GET_SINGLE_SYMPTOM_ADMIN_FAIL,
} from 'symptomModule/redux/modules/getSingleSymptomAdminModule';
import { getSingleSymptomCall } from 'symptomModule/infrastructure/getSingleSymptomLogic';

function* getSingleSymptomAdmin(action) {
  const { response, error } = yield call(getSingleSymptomCall, action.data);

  if (response) {
    yield put({ type: GET_SINGLE_SYMPTOM_ADMIN_SUCCESS, data: response.data });
  } else {
    yield put({ type: GET_SINGLE_SYMPTOM_ADMIN_FAIL, data: error });
  }
}

function* getSingleSymptomAdminSaga() {
  yield takeEvery(GET_SINGLE_SYMPTOM_ADMIN, getSingleSymptomAdmin);
}
export default getSingleSymptomAdminSaga;
