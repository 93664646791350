import React from 'react';
import { connect } from 'react-redux';
import SignInGoogle from 'authModule/components/SignInGoogle/SignInGoogle';
import { signInGoogleUserAction } from 'authModule/redux/modules/signInUserModule';

const SignInGoogleContainer = props => (
  <SignInGoogle {...props} />
);

const mapStateToProps = ({ auth: { signInUserReducer } }) => ({
  signInUserReducer,
});

const mapDispatchToProps = {
  signInGoogleUserAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignInGoogleContainer);

