import React, { useState, useEffect } from 'react';
import Layout from 'commonModule/components/Layout';

const AdminAddDiagnosisPage = ({
  getDiagnosisListAction, getDiagnosisListReducer, addDiagnosisAction, addDiagnosisReducer,
}) => {
  const [values, setValues] = useState({
    newDiagnosisName: '',
  });
  const { newDiagnosisName } = values;

  useEffect(() => {
    getDiagnosisListAction();
  }, []);

  useEffect(() => {
    if (addDiagnosisReducer.isProcessed) {
      getDiagnosisListAction();
    }
  }, [addDiagnosisReducer.isProcessed]);

  const showDiagnosiss = () => {
    if (getDiagnosisListReducer.isProcessing || !getDiagnosisListReducer.isProcessed) {
      return null;
    }

    return getDiagnosisListReducer.data.map(diagnosis => (
      <button
        title="Double click to delete"
        key={diagnosis._id}
        className="btn btn-outline-primary mr-1 ml-1 mt-3"
      >
        {diagnosis.name}
      </button>
    ));
  };

  const handleChange = e => {
    setValues({ ...values, newDiagnosisName: e.target.value });
  };

  const clickSubmit = e => {
    e.preventDefault();
    setValues({ ...values, newDiagnosisName: '' });
    addDiagnosisAction({ name: newDiagnosisName });
  };

  const newDiagnosisForm = () => (
    <form onSubmit={clickSubmit}>
      <div className="form-group">
        <label className="text-muted">Add new diagnosis name</label>
        <input type="text" className="form-control" onChange={handleChange} value={newDiagnosisName} required />
      </div>
      <div>
        <button type="submit" className="btn btn-primary">
                    Create
        </button>
      </div>
    </form>
  );

  return (
    <Layout>
      <h2 className="text-center pt-4 pb-4">Manage Diagnosis</h2>
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            {newDiagnosisForm()}
            {showDiagnosiss()}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AdminAddDiagnosisPage;
