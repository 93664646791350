import { put, takeEvery, call } from 'redux-saga/effects';
import {
  UPDATE_DISEASE,
  UPDATE_DISEASE_SUCCESS,
  UPDATE_DISEASE_FAIL,
} from '../modules/updateDiseaseModule';
import { updateDiseaseCall } from '../../infrastructure/updateDiseaseLogic';

function* updateDisease(action) {
  const { response, error } = yield call(updateDiseaseCall, action.data, action);

  if (response) {
    yield put({ type: UPDATE_DISEASE_SUCCESS, data: response.data });
  } else {
    yield put({ type: UPDATE_DISEASE_FAIL, data: error });
  }
}

function* updateDiseaseSaga() {
  yield takeEvery(UPDATE_DISEASE, updateDisease);
}
export default updateDiseaseSaga;
