import { put, takeEvery, call } from 'redux-saga/effects';
import {
  GET_PREVENTION_LIST,
  GET_PREVENTION_LIST_SUCCESS,
  GET_PREVENTION_LIST_FAIL,
} from 'preventionModule/redux/modules/getPreventionListModule';
import { getPreventionListCall } from 'preventionModule/infrastructure/getPreventionListLogic';

function* getPreventionList(action) {
  const { response, error } = yield call(getPreventionListCall, action?.data || {});

  if (response) {
    yield put({ type: GET_PREVENTION_LIST_SUCCESS, data: response.data });
  } else {
    yield put({ type: GET_PREVENTION_LIST_FAIL, data: error });
  }
}

function* getPreventionListSaga() {
  yield takeEvery(GET_PREVENTION_LIST, getPreventionList);
}
export default getPreventionListSaga;
