import { combineReducers } from 'redux';
import getCauseListReducer from 'causeModule/redux/modules/getCauseListModule';
import addCauseReducer from 'causeModule/redux/modules/addCauseModule';
import updateCauseReducer from 'causeModule/redux/modules/updateCauseModule';
import getSingleCauseAdminReducer from 'causeModule/redux/modules/getSingleCauseAdminModule';
import getSingleCauseReducer from 'causeModule/redux/modules/getSingleCauseModule';


const cause = combineReducers({
  getCauseListReducer,
  addCauseReducer,
  updateCauseReducer,
  getSingleCauseAdminReducer,
  getSingleCauseReducer,
});

export default cause;
