import React, { useEffect } from 'react';
import Layout from 'commonModule/components/Layout';
import List from 'commonModule/components/List';
import modelEnums from 'commonModule/infrastructure/enums/modelEnums';

const AdminCauseListPage = ({ getCauseListReducer = [], getCauseListAction }) => {
  useEffect(() => {
    getCauseListAction({ shouldGetFullData: true });
  }, []);

  return (
    <Layout>
      <h2 className="text-center pt-4 pb-4">Cause List</h2>
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <List isAdmin data={getCauseListReducer.data} modelType={modelEnums.Cause} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AdminCauseListPage;
