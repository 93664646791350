import React, { useEffect, useState } from 'react';
import Select from 'commonModule/components/Select';
import Layout from 'commonModule/components/Layout';


const AdminAddDiseasePage = (
  {
    getCategoryListReducer,
    addDiseaseReducer,
    getSymptomListReducer,
    getComplicationListReducer,
    getTreatmentListReducer,
    getPreventionListReducer,
    getCauseListReducer,
    getDiagnosisListReducer,
    addDiseaseAction,
    getCategoryListAction,
    getSymptomListAction,
    getComplicationListAction,
    getTreatmentListAction,
    getPreventionListAction,
    getCauseListAction,
    getDiagnosisListAction,
  },
) => {
  const initialState = {
    name: '',
    description: '',
    synonyms: '',
    categories: [],
    symptoms: [],
    complications: [],
    treatments: [],
    preventions: [],
    causes: [],
    diagnoses: [],
    formData: new FormData(),
    languageCode: process.env.RAZZLE_CREATE_NEW_ELEMENT_LANGUAGE_CODE,
  };
  const [values, setValues] = useState(initialState);

  useEffect(() => {
    setValues({ ...values, formData: new FormData() });
    getCategoryListAction();
    getSymptomListAction();
    getComplicationListAction();
    getTreatmentListAction();
    getPreventionListAction();
    getCauseListAction();
    getDiagnosisListAction();
  }, []);

  useEffect(() => {
    if (addDiseaseReducer.isProcessed) {
      setValues(initialState);
    }
  }, [addDiseaseReducer.isProcessed]);


  const {
    name,
    description,
    synonyms,
    categories,
    symptoms,
    formData,
    languageCode,
    complications,
    treatments,
    preventions,
    causes,
    diagnoses,
  } = values;

  const handleChange = (inputLabel, value) => {
    let formDataValue = value;
    if (Array.isArray(value)) {
      formDataValue = value.map(option => option._id);
    }
    formData.set(inputLabel, formDataValue);
    setValues({ ...values, [inputLabel]: value, formData });
  };

  const handleSubmit = e => {
    e.preventDefault();
    formData.set('languageCode', languageCode);
    addDiseaseAction(formData);
  };

  const showError = () => (addDiseaseReducer.error ? <div className="alert alert-danger">{addDiseaseReducer.error}</div> : '');

  const newCategoryForm = () => (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label className="text-muted">Language code</label>
        <input
          type="text"
          disabled
          className="form-control"
          value={languageCode}
          required
        />
      </div>
      <div className="form-group">
        <label className="text-muted">Disease name</label>
        <input
          type="text"
          className="form-control"
          onChange={e => handleChange('name', e.target.value)}
          value={name}
          required
        />
      </div>
      <div className="form-group">
        <label className="text-muted">Disease synonyms</label>
        <input
          type="text"
          className="form-control"
          onChange={e => handleChange('synonyms', e.target.value)}
          value={synonyms}
        />
      </div>
      <div className="form-group">
        <label className="text-muted">Disease description</label>
        <textarea
          className="form-control"
          onChange={e => handleChange('description', e.target.value)}
          value={description}
        />
      </div>
      <div className="form-group">
        <label className="text-muted">Disease categories</label>
        <Select
          value={categories}
          options={getCategoryListReducer.data}
          getOptionLabel={option => option.name}
          getOptionValue={option => option._id}
          isMulti
          onChange={options => handleChange('categories', options)}
        />
      </div>
      <div className="form-group">
        <label className="text-muted">Disease symptoms</label>
        <Select
          value={symptoms}
          options={getSymptomListReducer.data}
          getOptionLabel={option => option.name}
          getOptionValue={option => option._id}
          isMulti
          onChange={options => handleChange('symptoms', options)}
        />
      </div>
      <div className="form-group">
        <label className="text-muted">Disease complications</label>
        <Select
          value={complications}
          options={getComplicationListReducer.data}
          getOptionLabel={option => option.name}
          getOptionValue={option => option._id}
          isMulti
          onChange={options => handleChange('complications', options)}
        />
      </div>
      <div className="form-group">
        <label className="text-muted">Disease Treatments</label>
        <Select
          value={treatments}
          options={getTreatmentListReducer.data}
          getOptionLabel={option => option.name}
          getOptionValue={option => option._id}
          isMulti
          onChange={options => handleChange('treatments', options)}
        />
      </div>
      <div className="form-group">
        <label className="text-muted">Disease preventions</label>
        <Select
          value={preventions}
          options={getPreventionListReducer.data}
          getOptionLabel={option => option.name}
          getOptionValue={option => option._id}
          isMulti
          onChange={options => handleChange('preventions', options)}
        />
      </div>
      <div className="form-group">
        <label className="text-muted">Disease causes</label>
        <Select
          value={causes}
          options={getCauseListReducer.data}
          getOptionLabel={option => option.name}
          getOptionValue={option => option._id}
          isMulti
          onChange={options => handleChange('causes', options)}
        />
      </div>
      <div className="form-group">
        <label className="text-muted">Disease diagnoses</label>
        <Select
          value={diagnoses}
          options={getDiagnosisListReducer.data}
          getOptionLabel={option => option.name}
          getOptionValue={option => option._id}
          isMulti
          onChange={options => handleChange('diagnoses', options)}
        />
      </div>
      <div>
        <button type="submit" className="btn btn-primary">
					Create
        </button>
      </div>
    </form>
  );

  return (
    <Layout>
      <h2 className="text-center pt-4 pb-4">Add disease</h2>
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            {showError()}
            {newCategoryForm()}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AdminAddDiseasePage;
