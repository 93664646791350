import { put, takeEvery, call } from 'redux-saga/effects';
import {
  ADD_DIAGNOSIS,
  ADD_DIAGNOSIS_SUCCESS,
  ADD_DIAGNOSIS_FAIL,
} from 'diagnosisModule/redux/modules/addDiagnosisModule';
import { addDiagnosisCall } from 'diagnosisModule/infrastructure/addDiagnosisLogic';

function* addDiagnosis(action) {
  const { response, error } = yield call(addDiagnosisCall, action.data);

  if (response) {
    yield put({ type: ADD_DIAGNOSIS_SUCCESS, data: response.data });
  } else {
    yield put({ type: ADD_DIAGNOSIS_FAIL, data: error });
  }
}

function* addDiagnosisSaga() {
  yield takeEvery(ADD_DIAGNOSIS, addDiagnosis);
}
export default addDiagnosisSaga;
