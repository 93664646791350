export const ADD_SYMPTOM = 'symptom/ADD_SYMPTOM';
export const ADD_SYMPTOM_SUCCESS = 'symptom/ADD_SYMPTOM_SUCCESS';
export const ADD_SYMPTOM_FAIL = 'symptom/ADD_SYMPTOM_FAIL';


const initialState = {
  isProcessing: false,
  isProcessed: false,
  data: null,
  message: '',
  error: '',
};

const addSymptomReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SYMPTOM:
      return {
        ...state,
        isProcessed: false,
        isProcessing: true,
      };
    case ADD_SYMPTOM_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isProcessing: false,
        isProcessed: true,
        message: action.data.message,
        error: '',
      };
    }
    case ADD_SYMPTOM_FAIL:
      return {
        ...state,
        isProcessing: false,
        isProcessed: false,
        message: '',
        error: action.data,
      };
    default:
      return state;
  }
};

export const addSymptomAction = symptomName => ({
  type: ADD_SYMPTOM,
  data: symptomName,
});


export default addSymptomReducer;
