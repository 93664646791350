import React from 'react';
import { connect } from 'react-redux';

import withAuth from 'authModule/hocs/withAuth';
import AdminStaticPageListPage from 'staticPageModule/components/pages/AdminStaticPageList';
import { getStaticPageListAction } from 'staticPageModule/redux/modules/getStaticPageListModule';


const AdminStaticPageListContainer = props => <AdminStaticPageListPage {...props} />;

const mapStateToProps = ({ staticPage: { getStaticPageListReducer } }) => ({
  getStaticPageListReducer,
});

const mapDispatchToProps = { getStaticPageListAction };

export default withAuth(connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminStaticPageListContainer));
