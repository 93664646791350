export const ADMIN_GET_SINGLE_DISEASE = 'disease/ADMIN_GET_SINGLE_DISEASE';
export const ADMIN_GET_SINGLE_DISEASE_SUCCESS = 'disease/ADMIN_GET_SINGLE_DISEASE_SUCCESS';
export const ADMIN_GET_SINGLE_DISEASE_FAIL = 'disease/ADMIN_GET_SINGLE_DISEASE_FAIL';


const initialState = {
  isProcessing: false,
  isProcessed: false,
  data: {},
  message: '',
  error: '',
};

const adminGetSingleDiseaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_GET_SINGLE_DISEASE:
      return {
        ...state,
        isProcessed: false,
        isProcessing: true,
      };
    case ADMIN_GET_SINGLE_DISEASE_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        isProcessed: true,
        data: action.data,
      };
    }
    default:
      return state;
  }
};


export const getSingleDiseaseAdminAction = data => ({
  type: ADMIN_GET_SINGLE_DISEASE,
  data,
});

export default adminGetSingleDiseaseReducer;
