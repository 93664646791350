import React from 'react';
import { connect } from 'react-redux';

import withAuth from 'authModule/hocs/withAuth';
import AdminUpdatePreventionPage from 'preventionModule/components/pages/AdminUpdatePreventionPage';
import { updatePreventionAction } from 'preventionModule/redux/modules/updatePreventionModule';
import { getSinglePreventionAdminAction } from 'preventionModule/redux/modules/getSinglePreventionAdminModule';


const AdminUpdatePreventionPageContainer = props => <AdminUpdatePreventionPage {...props} />;

const mapStateToProps = ({ prevention: { getSinglePreventionAdminReducer, updatePreventionReducer } }) => ({
  getSinglePreventionAdminReducer,
  updatePreventionReducer,
});

const mapDispatchToProps = {
  updatePreventionAction,
  getSinglePreventionAdminAction,
};

export default withAuth(connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminUpdatePreventionPageContainer));
