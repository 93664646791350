import signInUserSaga from './signInUserSaga';
import signInGoogleUserSaga from './signInGoogleUserSaga';
import signInFacebookSaga from './signInFacebookSaga';
import signOutUserSaga from './signOutUserSaga';
import signUpUserSaga from './signUpUserSaga';

export default [
  signInUserSaga(),
  signInGoogleUserSaga(),
  signOutUserSaga(),
  signUpUserSaga(),
  signInFacebookSaga(),
];

