import { put, takeEvery, call } from 'redux-saga/effects';
import {
  GET_CATEGORY_LIST,
  GET_CATEGORY_LIST_SUCCESS,
  GET_CATEGORY_LIST_FAIL,
} from 'categoryModule/redux/modules/getCategoryListModule';
import { getCategoryListCall } from 'categoryModule/infrastructure/getCategoryListLogic';

function* getCategoryList(action) {
  const { response, error } = yield call(getCategoryListCall, action?.data || {});

  if (response) {
    yield put({ type: GET_CATEGORY_LIST_SUCCESS, data: response.data });
  } else {
    yield put({ type: GET_CATEGORY_LIST_FAIL, data: error });
  }
}

function* getCategoryListSaga() {
  yield takeEvery(GET_CATEGORY_LIST, getCategoryList);
}
export default getCategoryListSaga;
