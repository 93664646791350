import API from 'coreModule/infrastructure/api';

export const getModelPostListCall = ({ slug, modelEnum, lang }) => (
  API.request(
    {
      method: 'get',
      url: `/model-posts/${slug}`,
      params: {
        model: modelEnum,
        ...(lang === null && { lang: undefined }),
      },
    },
  )
    .then((response) => ({ response }))
    .catch(error => ({ error: error?.response?.data?.error })));
