import { put, takeEvery, call } from 'redux-saga/effects';
import {
  UPDATE_STATIC_PAGE,
  UPDATE_STATIC_PAGE_SUCCESS,
  UPDATE_STATIC_PAGE_FAIL,
} from 'staticPageModule/redux/modules/updateStaticPageModule';
import { updateStaticPageCall } from 'staticPageModule/infrastructure/updateStaticPageLogic';

function* updateStaticPage(action) {
  const { response, error } = yield call(updateStaticPageCall, action.data, action);

  if (response) {
    yield put({ type: UPDATE_STATIC_PAGE_SUCCESS, data: response.data });
  } else {
    yield put({ type: UPDATE_STATIC_PAGE_FAIL, data: error });
  }
}

function* updateStaticPageSaga() {
  yield takeEvery(UPDATE_STATIC_PAGE, updateStaticPage);
}
export default updateStaticPageSaga;
