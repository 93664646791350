import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import Layout from 'commonModule/components/Layout';
import getSlug from 'commonModule/helpers/getSlug';

const AdminUpdateDiagnosisPage = (
  {
    updateDiagnosisAction,
    getSingleDiagnosisAdminAction,
    history,
    getSingleDiagnosisAdminReducer,
    updateDiagnosisReducer,
  },
) => {
  const initialState = {
    formData: new FormData(),
    processedLanguageCode: '',
    diagnosis: {},
    slug: getSlug(history.location.pathname),
  };
  const [values, setValues] = useState(initialState);
  useEffect(() => {
    getSingleDiagnosisAdminAction({ slug: getSlug(history.location.pathname), shouldGetFullData: true });
  }, [updateDiagnosisReducer.isProcessed]);

  useEffect(() => {
    if (getSingleDiagnosisAdminReducer.isProcessed || updateDiagnosisReducer.isProcessed) {
      setValues({ ...values, diagnosis: getSingleDiagnosisAdminReducer.data });
    }
  }, [getSingleDiagnosisAdminReducer.isProcessed, updateDiagnosisReducer.isProcessed]);

  const {
    formData, diagnosis, slug, processedLanguageCode,
  } = values;

  if (!getSingleDiagnosisAdminReducer.isProcessed || isEmpty(diagnosis)) {
    return null;
  }

  const handleChange = (name, value, languageCode) => {
    if (languageCode) {
      const isExistingLanguageData = diagnosis.language.find(diagnosisLanguageData => diagnosisLanguageData.languageCode === languageCode);
      if (!isExistingLanguageData) {
        const newLanguageData = {
          languageCode,
          [name]: value,
        };
        return setValues({ ...values, diagnosis: { ...diagnosis, language: [...diagnosis.language, newLanguageData] } });
      }

      const diagnosisLanguages = diagnosis.language.map(languageData => {
        if (languageData.languageCode === languageCode) {
          return {
            ...languageData,
            [name]: value,
          };
        }

        return languageData;
      });
      return setValues({ ...values, diagnosis: { ...diagnosis, language: diagnosisLanguages } });
    }
  };

  const handleSubmit = (e, languageCode) => {
    e.preventDefault();
    const diagnosisLanguageDataToUpdate = diagnosis.language.find(diagnosisLanguageData => diagnosisLanguageData.languageCode === languageCode);
    Object.keys(diagnosisLanguageDataToUpdate).forEach(key => {
      formData.set([key], diagnosisLanguageDataToUpdate[key]);
    });
    setValues({ ...values, processedLanguageCode: languageCode });
    updateDiagnosisAction({ slug, updatedDiagnosisData: formData });
  };

  const showError = () => (updateDiagnosisReducer.error ? <div className="alert alert-danger">{updateDiagnosisReducer.error}</div> : '');

  const renderFormForEachLanguage = () => {
    const supportedLanguagesArray = process.env.RAZZLE_SUPPORTED_LANGUAGES.split(',');
    return supportedLanguagesArray.map(languageCode => renderNewForm(diagnosis?.language
      ?.find(diagnosis => diagnosis.languageCode === languageCode), languageCode));
  };

  const renderNewForm = (diagnosisData = {}, languageCode) => (
    <>
      <form onSubmit={e => handleSubmit(e, languageCode)}>
        <div className="form-group">
          <label className="text-muted">Language code</label>
          <input
            type="text"
            disabled
            className="form-control"
            value={languageCode}
            required
          />
        </div>
        <div className="form-group">
          <label className="text-muted">{`${languageCode} Diagnosis name`}</label>
          <input
            type="text"
            className="form-control"
            onChange={e => handleChange('name', e.target.value, languageCode)}
            value={diagnosisData.name}
            required
          />
        </div>
        {(updateDiagnosisReducer.isProcessed && processedLanguageCode === languageCode) && <p className="text-success">Diagnosis is updated</p>}
        <div>
          <button type="submit" className="btn btn-primary">
						Update
          </button>
        </div>
      </form>
      <hr />
    </>
  );

  return (
    <Layout>
      <h2 className="text-center pt-4 pb-4">Update diagnosis</h2>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            {showError()}
            {renderFormForEachLanguage()}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AdminUpdateDiagnosisPage;
