import { put, takeEvery, call } from 'redux-saga/effects';
import {
  UPDATE_SYMPTOM,
  UPDATE_SYMPTOM_SUCCESS,
  UPDATE_SYMPTOM_FAIL,
} from 'symptomModule/redux/modules/updateSymptomModule';
import { updateSymptomCall } from 'symptomModule/infrastructure/updateSymptomLogic';

function* updateSymptom(action) {
  const { response, error } = yield call(updateSymptomCall, action.data, action);

  if (response) {
    yield put({ type: UPDATE_SYMPTOM_SUCCESS, data: response.data });
  } else {
    yield put({ type: UPDATE_SYMPTOM_FAIL, data: error });
  }
}

function* updateSymptomSaga() {
  yield takeEvery(UPDATE_SYMPTOM, updateSymptom);
}
export default updateSymptomSaga;
