import { put, takeEvery, call } from 'redux-saga/effects';
import {
  GET_STATIC_PAGE_LIST,
  GET_STATIC_PAGE_LIST_SUCCESS,
  GET_STATIC_PAGE_LIST_FAIL,
} from 'staticPageModule/redux/modules/getStaticPageListModule';
import { getStaticPageListCall } from 'staticPageModule/infrastructure/getStaticPageListLogic';

function* getStaticPageList(action) {
  const { response, error } = yield call(getStaticPageListCall, action?.data || {});

  if (response) {
    yield put({ type: GET_STATIC_PAGE_LIST_SUCCESS, data: response.data });
  } else {
    yield put({ type: GET_STATIC_PAGE_LIST_FAIL, data: error });
  }
}

function* getStaticPageListSaga() {
  yield takeEvery(GET_STATIC_PAGE_LIST, getStaticPageList);
}
export default getStaticPageListSaga;
