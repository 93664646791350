import API from 'coreModule/infrastructure/api';
import { getCookie } from 'authModule/infrastructure/signInUserLogic';

export const updateTreatmentCall = ({ slug, updatedTreatmentData }) => {
  const token = getCookie('token');
  return API.request({
    method: 'put',
    url: `/treatment/${slug}`,
    headers: {
      headers: { 'Content-Type': 'multipart/form-data' },
      Authorization: `Bearer ${token}`,
    },
    data: updatedTreatmentData,
  })
    .then(response => ({ response }))
    .catch(error => ({ error: error?.response?.data?.error }));
};
