import React from 'react';
import { connect } from 'react-redux';

import withAuth from 'authModule/hocs/withAuth';
import AdminDiseaseListPage from 'diseaseModule/components/pages/AdminDiseaseListPage';
import { getDiseaseListAction } from 'diseaseModule/redux/modules/getDiseaseListModule';

const AdminDiseaseListPageContainer = props => <AdminDiseaseListPage {...props} />;

const mapStateToProps = ({ disease: { getDiseaseListReducer } }) => ({
  diseaseListData: getDiseaseListReducer.data,
});

const mapDispatchToProps = { getDiseaseListAction };

export default withAuth(connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminDiseaseListPageContainer));
