import { put, takeEvery, call } from 'redux-saga/effects';
import {
  GET_SINGLE_TREATMENT_ADMIN,
  GET_SINGLE_TREATMENT_ADMIN_SUCCESS,
  GET_SINGLE_TREATMENT_ADMIN_FAIL,
} from 'treatmentModule/redux/modules/getSingleTreatmentAdminModule';
import { getSingleTreatmentCall } from 'treatmentModule/infrastructure/getSingleTreatmentLogic';

function* getSingleTreatmentAdmin(action) {
  const { response, error } = yield call(getSingleTreatmentCall, action.data);

  if (response) {
    yield put({ type: GET_SINGLE_TREATMENT_ADMIN_SUCCESS, data: response.data });
  } else {
    yield put({ type: GET_SINGLE_TREATMENT_ADMIN_FAIL, data: error });
  }
}

function* getSingleTreatmentAdminSaga() {
  yield takeEvery(GET_SINGLE_TREATMENT_ADMIN, getSingleTreatmentAdmin);
}
export default getSingleTreatmentAdminSaga;
