import { combineReducers } from 'redux';
import getTreatmentListReducer from 'treatmentModule/redux/modules/getTreatmentListModule';
import addTreatmentReducer from 'treatmentModule/redux/modules/addTreatmentModule';
import updateTreatmentReducer from 'treatmentModule/redux/modules/updateTreatmentModule';
import getSingleTreatmentAdminReducer from 'treatmentModule/redux/modules/getSingleTreatmentAdminModule';
import getSingleTreatmentReducer from 'treatmentModule/redux/modules/getSingleTreatmentModule';


const treatment = combineReducers({
  getTreatmentListReducer,
  addTreatmentReducer,
  updateTreatmentReducer,
  getSingleTreatmentAdminReducer,
  getSingleTreatmentReducer,
});

export default treatment;
