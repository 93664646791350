import React, { useState, useEffect } from 'react';
import Layout from 'commonModule/components/Layout';

const AdminAddPreventionPage = ({
  getPreventionListAction, getPreventionListReducer, addPreventionAction, addPreventionReducer,
}) => {
  const [values, setValues] = useState({
    newPreventionName: '',
  });
  const { newPreventionName } = values;

  useEffect(() => {
    getPreventionListAction();
  }, []);

  useEffect(() => {
    if (addPreventionReducer.isProcessed) {
      getPreventionListAction();
    }
  }, [addPreventionReducer.isProcessed]);

  const showPreventions = () => {
    if (getPreventionListReducer.isProcessing || !getPreventionListReducer.isProcessed) {
      return null;
    }

    return getPreventionListReducer.data.map(prevention => (
      <button
        title="Double click to delete"
        key={prevention._id}
        className="btn btn-outline-primary mr-1 ml-1 mt-3"
      >
        {prevention.name}
      </button>
    ));
  };

  const handleChange = e => {
    setValues({ ...values, newPreventionName: e.target.value });
  };

  const clickSubmit = e => {
    e.preventDefault();
    setValues({ ...values, newPreventionName: '' });
    addPreventionAction({ name: newPreventionName });
  };

  const newPreventionForm = () => (
    <form onSubmit={clickSubmit}>
      <div className="form-group">
        <label className="text-muted">Add new prevention name</label>
        <input type="text" className="form-control" onChange={handleChange} value={newPreventionName} required />
      </div>
      <div>
        <button type="submit" className="btn btn-primary">
                    Create
        </button>
      </div>
    </form>
  );

  return (
    <Layout>
      <h2 className="text-center pt-4 pb-4">Manage Prevention</h2>
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            {newPreventionForm()}
            {showPreventions()}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AdminAddPreventionPage;
