import { put, takeEvery, call } from 'redux-saga/effects';
import {
  GET_SINGLE_TREATMENT,
  GET_SINGLE_TREATMENT_SUCCESS,
  GET_SINGLE_TREATMENT_FAIL,
} from '../modules/getSingleTreatmentModule';
import { getSingleTreatmentCall } from '../../infrastructure/getSingleTreatmentLogic';

function* getSingleTreatment(action) {
  const { response, error } = yield call(getSingleTreatmentCall, action.data, action);

  if (response) {
    yield put({ type: GET_SINGLE_TREATMENT_SUCCESS, data: response.data });
  } else {
    yield put({ type: GET_SINGLE_TREATMENT_FAIL, data: error });
  }
}

function* getSingleTreatmentSaga() {
  yield takeEvery(GET_SINGLE_TREATMENT, getSingleTreatment);
}
export default getSingleTreatmentSaga;
