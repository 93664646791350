import { put, takeEvery, call } from 'redux-saga/effects';
import {
  UPDATE_PREVENTION,
  UPDATE_PREVENTION_SUCCESS,
  UPDATE_PREVENTION_FAIL,
} from 'preventionModule/redux/modules/updatePreventionModule';
import { updatePreventionCall } from 'preventionModule/infrastructure/updatePreventionLogic';

function* updatePrevention(action) {
  const { response, error } = yield call(updatePreventionCall, action.data, action);

  if (response) {
    yield put({ type: UPDATE_PREVENTION_SUCCESS, data: response.data });
  } else {
    yield put({ type: UPDATE_PREVENTION_FAIL, data: error });
  }
}

function* updatePreventionSaga() {
  yield takeEvery(UPDATE_PREVENTION, updatePrevention);
}
export default updatePreventionSaga;
