import addDiagnosisSaga from './addDiagnosisSaga';
import getDiagnosisListSaga from './getDiagnosisListSaga';
import getSingleDiagnosisAdminSaga from './getSingleDiagnosisAdminSaga';
import updateDiagnosisSaga from './updateDiagnosisSaga';
import getSingleDiagnosisSaga from './getSingleDiagnosisSaga';

export default [
  addDiagnosisSaga(),
  getDiagnosisListSaga(),
  getSingleDiagnosisAdminSaga(),
  updateDiagnosisSaga(),
  getSingleDiagnosisSaga(),
];
