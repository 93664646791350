import { put, takeEvery, call } from 'redux-saga/effects';
import {
  GET_SINGLE_DISEASE,
  GET_SINGLE_DISEASE_SUCCESS,
  GET_SINGLE_DISEASE_FAIL,
} from '../modules/getSingleDiseaseModule';
import { getSingleDiseaseCall } from '../../infrastructure/getSingleDiseaseLogic';

function* getSingleDisease(action) {
  const { response, error } = yield call(getSingleDiseaseCall, action.data, action);

  if (response) {
    yield put({ type: GET_SINGLE_DISEASE_SUCCESS, data: response.data });
  } else {
    yield put({ type: GET_SINGLE_DISEASE_FAIL, data: error });
  }
}

function* getSingleDiseaseSaga() {
  yield takeEvery(GET_SINGLE_DISEASE, getSingleDisease);
}
export default getSingleDiseaseSaga;
