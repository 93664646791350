import modelEnums from 'commonModule/infrastructure/enums/modelEnums';
import { SLUGS } from 'coreModule/infrastructure/multiLanguageSlugs';


export default (modelType, isAdmin, slug, differentLanguageCode) => {
  const languagePrefix = process.env.RAZZLE_LANGUAGE_CODE || differentLanguageCode;
  if (!languagePrefix || !SLUGS[languagePrefix]) {
    return null;
  }
  const getPrefix = () => {
    switch (modelType) {
      case modelEnums.Treatment:
        return SLUGS[languagePrefix][modelEnums.Treatment];
      case modelEnums.Cause:
        return SLUGS[languagePrefix][modelEnums.Cause];
      case modelEnums.Diagnosis:
        return SLUGS[languagePrefix][modelEnums.Diagnosis];
      case modelEnums.Prevention:
        return SLUGS[languagePrefix][modelEnums.Prevention];
      case modelEnums.Complication:
        return SLUGS[languagePrefix][modelEnums.Complication];
      case modelEnums.Symptom:
        return SLUGS[languagePrefix][modelEnums.Symptom];
      case modelEnums.Category:
        return SLUGS[languagePrefix][modelEnums.Category];
      case modelEnums.Post:
        return SLUGS[languagePrefix][modelEnums.Post];
      case modelEnums.StaticPage:
        return 'static-page';
      case modelEnums.Disease:
        return SLUGS[languagePrefix][modelEnums.Disease];
      default:
        return null;
    }
  };

  const externalUrl = differentLanguageCode ? `//${differentLanguageCode}.${process.env.RAZZLE_URL}` : '';
  return `${externalUrl}${isAdmin ? '/admin' : ''}/${getPrefix()}/${slug}`;
};
