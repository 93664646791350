export const GET_SINGLE_TREATMENT = 'disease/GET_SINGLE_TREATMENT';
export const GET_SINGLE_TREATMENT_SUCCESS = 'disease/GET_SINGLE_TREATMENT_SUCCESS';
export const GET_SINGLE_TREATMENT_FAIL = 'disease/GET_SINGLE_TREATMENT_FAIL';
export const GET_SINGLE_TREATMENT_RESET = 'disease/GET_SINGLE_TREATMENT_RESET';

const initialState = {
  isProcessing: false,
  isProcessed: false,
  data: {},
  message: '',
  error: '',
};

const getSingleTreatmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SINGLE_TREATMENT: {
      const newTreatment = {
        slug: action.data.slug,
        data: [],
      };

      return {
        ...state,
        isProcessing: true,
        isProcessed: false,
        data: newTreatment,
      };
    }
    case GET_SINGLE_TREATMENT_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        isProcessed: true,
        data: action.data,
      };
    }
    case GET_SINGLE_TREATMENT_FAIL:
    case GET_SINGLE_TREATMENT_RESET: {
      return {
        ...state,
        isProcessing: false,
        isProcessed: false,
        data: {},
        message: '',
        error: action?.data || '',
      };
    }
    default:
      return state;
  }
};

export const getSingleTreatmentAction = data => ({
  type: GET_SINGLE_TREATMENT,
  data,
});

export const getSingleTreatmentResetAction = () => ({
  type: GET_SINGLE_TREATMENT_RESET,
});

export default getSingleTreatmentReducer;
