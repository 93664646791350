import React from 'react';
import GoogleLogin from 'react-google-login';
import { useTranslation } from 'react-i18next';
import { Google } from 'react-bootstrap-icons';
import { Button } from 'reactstrap';

import './styles.scss';


const SignInGoogle = ({ signInGoogleUserAction }) => {
  const responseGoogle = response => {
    const { tokenId } = response;
    signInGoogleUserAction({ tokenId });
  };
  const { t } = useTranslation();
  return (
    <div className="pb-3 sign-in-google">
      <GoogleLogin
        clientId={process.env.NODE_ENV === 'development' ? process.env.RAZZLE_GOOGLE_CLIENT_ID_DEV : process.env.RAZZLE_GOOGLE_CLIENT_ID_PROD}
        buttonText="Login with Google"
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        theme="dark"
        render={renderProps => (
          <Button
            className="sign-in-google__button"
            size="lg"
            color="primary"
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
          >
            <Google />
            {t('logInGoogle.logInButton')}
          </Button>
        )}
      />
    </div>
  );
};

export default SignInGoogle;
