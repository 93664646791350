import API from 'coreModule/infrastructure/api';
import { getCookie } from 'authModule/infrastructure/signInUserLogic';

export const getUserProfileCall = (userData) => {
  const token = getCookie('token');
  return API.request({
    method: 'get',
    url: `/user/profile/${userData._id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then(response => ({ response }))
    .catch(error => ({ error: error?.response?.data?.error }));
};
