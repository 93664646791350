import React from 'react';
import deepClassNames from 'commonModule/helpers/deepClassNames';
import './styles/Avatar.scss';

const Avatar = (
  {
    classNameModifier,
    text,
    className,
  },
) => {
  const avatarClassName = deepClassNames({
    [className]: true,
    avatar: {
      [`--${classNameModifier}`]: true,
    },
  });

  const colors = ['#b2adcd', '#cbadcd', '#adcdb5', '#b0cdad', '#b8cdad', '#adc6cd'];
  const randomBgColor = colors[Math.floor(Math.random() * colors.length)];

  return (
    <div className={avatarClassName} style={{ backgroundColor: randomBgColor }}>
      {text}
    </div>
  );
};

export default Avatar;
