export const GET_SINGLE_CAUSE = 'disease/GET_SINGLE_CAUSE';
export const GET_SINGLE_CAUSE_SUCCESS = 'disease/GET_SINGLE_CAUSE_SUCCESS';
export const GET_SINGLE_CAUSE_FAIL = 'disease/GET_SINGLE_CAUSE_FAIL';
export const GET_SINGLE_CAUSE_RESET = 'disease/GET_SINGLE_CAUSE_RESET';

const initialState = {
  isProcessing: false,
  isProcessed: false,
  data: {},
  message: '',
  error: '',
};

const getSingleCauseReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SINGLE_CAUSE: {
      const newCause = {
        slug: action.data.slug,
        data: [],
      };

      return {
        ...state,
        isProcessing: true,
        isProcessed: false,
        data: newCause,
      };
    }
    case GET_SINGLE_CAUSE_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        isProcessed: true,
        data: action.data,
      };
    }
    case GET_SINGLE_CAUSE_FAIL:
    case GET_SINGLE_CAUSE_RESET: {
      return {
        ...state,
        isProcessing: false,
        isProcessed: false,
        data: {},
        message: '',
        error: action?.data || '',
      };
    }
    default:
      return state;
  }
};

export const getSingleCauseAction = data => ({
  type: GET_SINGLE_CAUSE,
  data,
});

export const getSingleCauseResetAction = () => ({
  type: GET_SINGLE_CAUSE_RESET,
});

export default getSingleCauseReducer;
