export const GET_SINGLE_SYMPTOM_ADMIN = 'symptom/GET_SINGLE_SYMPTOM_ADMIN';
export const GET_SINGLE_SYMPTOM_ADMIN_SUCCESS = 'symptom/GET_SINGLE_SYMPTOM_ADMIN_SUCCESS';
export const GET_SINGLE_SYMPTOM_ADMIN_FAIL = 'symptom/GET_SINGLE_SYMPTOM_ADMIN_FAIL';

const initialState = {
  isProcessing: false,
  isProcessed: false,
  data: {},
  message: '',
  error: '',
};

const getSingleSymptomAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SINGLE_SYMPTOM_ADMIN: {
      const newSymptom = {
        slug: action.data.slug,
        data: {},
      };
      return {
        ...state,
        isProcessing: true,
        isProcessed: false,
        data: newSymptom,
      };
    }
    case GET_SINGLE_SYMPTOM_ADMIN_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        isProcessed: true,
        data: action.data,
      };
    }
    default:
      return state;
  }
};

export const getSingleSymptomAdminAction = data => ({
  type: GET_SINGLE_SYMPTOM_ADMIN,
  data,
});

export default getSingleSymptomAdminReducer;
