import { put, takeEvery, call } from 'redux-saga/effects';
import {
  GET_SINGLE_STATIC_PAGE,
  GET_SINGLE_STATIC_PAGE_SUCCESS,
  GET_SINGLE_STATIC_PAGE_FAIL,
} from '../modules/getSingleStaticPageModule';
import { getSingleStaticPageCall } from '../../infrastructure/getSingleStaticPageLogic';

function* getSingleStaticPage(action) {
  const { response, error } = yield call(getSingleStaticPageCall, action.data, action);

  if (response) {
    yield put({ type: GET_SINGLE_STATIC_PAGE_SUCCESS, data: response.data });
  } else {
    yield put({ type: GET_SINGLE_STATIC_PAGE_FAIL, data: error });
  }
}

function* getSingleStaticPageSaga() {
  yield takeEvery(GET_SINGLE_STATIC_PAGE, getSingleStaticPage);
}
export default getSingleStaticPageSaga;
