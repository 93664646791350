import { combineReducers } from 'redux';
import addCategoryReducer from 'categoryModule/redux/modules/addCategoryModule';
import getCategoryListReducer from 'categoryModule/redux/modules/getCategoryListModule';
import getSingleCategoryAdminReducer from 'categoryModule/redux/modules/getSingleCategoryAdminModule';
import updateCategoryReducer from 'categoryModule/redux/modules/updateCategoryModule';

const category = combineReducers({
  addCategoryReducer,
  getCategoryListReducer,
  getSingleCategoryAdminReducer,
  updateCategoryReducer,
});

export default category;
