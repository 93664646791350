export const GET_TREATMENT_LIST = 'treatment/GET_TREATMENT_LIST';
export const GET_TREATMENT_LIST_SUCCESS = 'treatment/GET_TREATMENT_LIST_SUCCESS';
export const GET_TREATMENT_LIST_FAIL = 'treatment/GET_TREATMENT_LIST_FAIL';

const initialState = {
  data: [],
  isProcessing: false,
  isProcessed: false,
  message: '',
  error: '',
};

const getTreatmentListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TREATMENT_LIST:
      return {
        ...state,
        isProcessing: true,
      };
    case GET_TREATMENT_LIST_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isProcessing: false,
        isProcessed: true,
        message: action.data?.message,
        error: '',
      };
    }
    case GET_TREATMENT_LIST_FAIL:
      return {
        ...state,
        isProcessing: false,
        isProcessed: false,
        message: '',
        error: action.data,
      };
    default:
      return state;
  }
};

export const getTreatmentListAction = data => ({
  type: GET_TREATMENT_LIST,
  data,
});

export default getTreatmentListReducer;
