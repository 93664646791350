export const GET_STATIC_PAGE_LIST = 'staticPage/GET_STATIC_PAGE_LIST';
export const GET_STATIC_PAGE_LIST_SUCCESS = 'staticPage/GET_STATIC_PAGE_LIST_SUCCESS';
export const GET_STATIC_PAGE_LIST_FAIL = 'staticPage/GET_STATIC_PAGE_LIST_FAIL';

const initialState = {
  data: [],
  isProcessing: false,
  isProcessed: false,
  message: '',
  error: '',
};

const getStaticPageListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STATIC_PAGE_LIST:
      return {
        ...state,
        isProcessing: true,
      };
    case GET_STATIC_PAGE_LIST_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isProcessing: false,
        isProcessed: true,
        message: action.data?.message,
        error: '',
      };
    }
    case GET_STATIC_PAGE_LIST_FAIL:
      return {
        ...state,
        isProcessing: false,
        isProcessed: false,
        message: '',
        error: action.data,
      };
    default:
      return state;
  }
};

export const getStaticPageListAction = data => ({
  type: GET_STATIC_PAGE_LIST,
  data,
});

export default getStaticPageListReducer;
