import React from 'react';
import { ListGroupItem, ListGroupItemHeading, ListGroupItemText } from 'reactstrap';
import VoteButtonsWrapper from 'voteModule/components/VoteButtonsWrapper';
import modelEnums from 'commonModule/infrastructure/enums/modelEnums';
import { Link } from 'react-router-dom';
import createUrl from 'commonModule/helpers/createUrl';
import postLevelEnum from 'postModule/infrastructure/postLevelEnum';
import { useTranslation } from 'react-i18next';

import './styles.scss';
import Avatar from 'commonModule/components/Avatar/Avatar';

const renderAvatar = (authorInitials) => {
  if (!authorInitials) {
    return null;
  }

  return <Avatar text={authorInitials} className="mr-2" />;
};

const Post = ({
  postData, children, targetId, isSubPostId = null, isParentPost, isParentPostVisible, postLevel, hideVoteButtons, showAnswersCounter,
}) => {
  const { t } = useTranslation();
  const renderAnswersCounter = () => {
    if (!showAnswersCounter || postLevel !== postLevelEnum.Post) {
      return null;
    }

    return (
      <div className="post__answers-counter">
        {t('postComponent.answers')}
:
        {' '}
        {postData.answersCounter || postData.subPosts.length}
      </div>
    );
  };

  const renderPostBody = () => {
    if (isParentPost && !isParentPostVisible) {
      return null;
    }

    const isDifferentLanguage = postData.languageCode && process.env.RAZZLE_LANGUAGE_CODE !== postData.languageCode;
    const linkTarget = isDifferentLanguage ? '_blank' : '_self';

    return (
      <>
        {renderAnswersCounter()}
        {postData.title && (
          <ListGroupItemHeading target={linkTarget} to={createUrl(modelEnums.Post, false, postData.slug, isDifferentLanguage ? postData.languageCode : null)} tag={Link}>
            {postData.title}
          </ListGroupItemHeading>
        )}
        <ListGroupItemText>
          {postData.content}
        </ListGroupItemText>
        {postData?.postedBy?.username && (
          <i className="d-flex align-self-end mt-auto p-1 w-100 justify-content-end align-items-center">
            {renderAvatar(postData?.postedBy?.initials)}
            {' '}
            {postData?.postedBy?.username}
          </i>
        )}
      </>
    );
  };

  return (
    <ListGroupItem className={`d-flex ${hideVoteButtons ? '' : 'pl-0'}`} key={postData._id}>
      <VoteButtonsWrapper
        hideVoteButtons={hideVoteButtons}
        postLevel={postLevel}
        targetModel={modelEnums.Post}
        targetId={targetId}
        votes={postData.votes}
        isSubPostId={isSubPostId}
      />
      <div className="d-flex flex-column w-100">
        {renderPostBody()}
        {children}
      </div>
    </ListGroupItem>

  );
};

export default Post;
