import API from 'coreModule/infrastructure/api';

export const getSingleSymptomCall = ({ slug, shouldGetFullData = false }) => API.request({
  method: 'get',
  url: `/symptom/${slug}`,
  params: {
    shouldGetFullData,
  },
})
  .then(response => ({ response }))
  .catch(error => ({ error }));
