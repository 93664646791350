import { put, takeEvery, call } from 'redux-saga/effects';
import {
  UPDATE_CAUSE,
  UPDATE_CAUSE_SUCCESS,
  UPDATE_CAUSE_FAIL,
} from 'causeModule/redux/modules/updateCauseModule';
import { updateCauseCall } from 'causeModule/infrastructure/updateCauseLogic';

function* updateCause(action) {
  const { response, error } = yield call(updateCauseCall, action.data, action);

  if (response) {
    yield put({ type: UPDATE_CAUSE_SUCCESS, data: response.data });
  } else {
    yield put({ type: UPDATE_CAUSE_FAIL, data: error });
  }
}

function* updateCauseSaga() {
  yield takeEvery(UPDATE_CAUSE, updateCause);
}
export default updateCauseSaga;
