export const UPDATE_SYMPTOM = 'symptom/UPDATE_SYMPTOM';
export const UPDATE_SYMPTOM_SUCCESS = 'symptom/UPDATE_SYMPTOM_SUCCESS';
export const UPDATE_SYMPTOM_FAIL = 'symptom/UPDATE_SYMPTOM_FAIL';


const initialState = {
  isProcessing: false,
  isProcessed: false,
  message: '',
  error: '',
};

const updateSymptomReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SYMPTOM:
      return {
        ...state,
        isProcessed: false,
        isProcessing: true,
      };
    case UPDATE_SYMPTOM_SUCCESS: {
      return {
        ...state,
        isProcessed: true,
        isProcessing: false,
      };
    }
    case UPDATE_SYMPTOM_FAIL:
      return {
        ...state,
        isProcessing: false,
        isProcessed: false,
        message: '',
        error: action.data,
      };
    default:
      return state;
  }
};

export const updateSymptomAction = data => ({
  type: UPDATE_SYMPTOM,
  data,
});


export default updateSymptomReducer;
