export const ADD_VOTE = 'vote/ADD_VOTE';
export const ADD_VOTE_SUCCESS = 'vote/ADD_VOTE_SUCCESS';
export const ADD_VOTE_FAIL = 'vote/ADD_VOTE_FAIL';


const initialState = {
  isProcessing: false,
  isProcessed: false,
  message: '',
  error: '',
};

const addVoteReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_VOTE:
      return {
        ...state,
        isProcessed: false,
        isProcessing: true,
      };
    case ADD_VOTE_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isProcessing: false,
        isProcessed: true,
        message: action.data.message,
        error: '',
      };
    }
    case ADD_VOTE_FAIL:
      return {
        ...state,
        isProcessing: false,
        isProcessed: false,
        message: '',
        error: action.data,
      };
    default:
      return state;
  }
};

export const addVoteAction = voteData => ({
  type: ADD_VOTE,
  data: voteData,
});


export default addVoteReducer;
