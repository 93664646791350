import { put, takeEvery, call } from 'redux-saga/effects';
import {
  GET_SINGLE_DIAGNOSIS,
  GET_SINGLE_DIAGNOSIS_SUCCESS,
  GET_SINGLE_DIAGNOSIS_FAIL,
} from '../modules/getSingleDiagnosisModule';
import { getSingleDiagnosisCall } from '../../infrastructure/getSingleDiagnosisLogic';

function* getSingleDiagnosis(action) {
  const { response, error } = yield call(getSingleDiagnosisCall, action.data, action);

  if (response) {
    yield put({ type: GET_SINGLE_DIAGNOSIS_SUCCESS, data: response.data });
  } else {
    yield put({ type: GET_SINGLE_DIAGNOSIS_FAIL, data: error });
  }
}

function* getSingleDiagnosisSaga() {
  yield takeEvery(GET_SINGLE_DIAGNOSIS, getSingleDiagnosis);
}
export default getSingleDiagnosisSaga;
