export const ADD_CAUSE = 'cause/ADD_CAUSE';
export const ADD_CAUSE_SUCCESS = 'cause/ADD_CAUSE_SUCCESS';
export const ADD_CAUSE_FAIL = 'cause/ADD_CAUSE_FAIL';


const initialState = {
  isProcessing: false,
  isProcessed: false,
  data: null,
  message: '',
  error: '',
};

const addCauseReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CAUSE:
      return {
        ...state,
        isProcessed: false,
        isProcessing: true,
      };
    case ADD_CAUSE_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isProcessing: false,
        isProcessed: true,
        message: action.data.message,
        error: '',
      };
    }
    case ADD_CAUSE_FAIL:
      return {
        ...state,
        isProcessing: false,
        isProcessed: false,
        message: '',
        error: action.data,
      };
    default:
      return state;
  }
};

export const addCauseAction = causeName => ({
  type: ADD_CAUSE,
  data: causeName,
});


export default addCauseReducer;
