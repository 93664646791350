import { put, takeEvery, call } from 'redux-saga/effects';
import {
  GET_SINGLE_CATEGORY_ADMIN,
  GET_SINGLE_CATEGORY_ADMIN_SUCCESS,
  GET_SINGLE_CATEGORY_ADMIN_FAIL,
} from 'categoryModule/redux/modules/getSingleCategoryAdminModule';
import { getSingleCategoryCall } from 'categoryModule/infrastructure/getSingleCategoryLogic';

function* getSingleCategoryAdmin(action) {
  const { response, error } = yield call(getSingleCategoryCall, action.data);

  if (response) {
    yield put({ type: GET_SINGLE_CATEGORY_ADMIN_SUCCESS, data: response.data });
  } else {
    yield put({ type: GET_SINGLE_CATEGORY_ADMIN_FAIL, data: error });
  }
}

function* getSingleCategoryAdminSaga() {
  yield takeEvery(GET_SINGLE_CATEGORY_ADMIN, getSingleCategoryAdmin);
}
export default getSingleCategoryAdminSaga;
