import React from 'react';
import HeaderContainer from 'commonModule/containers/HeaderContainer';
import Footer from 'commonModule/components/Layout/Footer';

import './styles.scss';

const Layout = ({ children, className = '' }) => (
  <>
    <HeaderContainer />
    <div className={`content-wrapper ${className}`}>
      {children}
    </div>
    <Footer />
  </>
);

export default Layout;
