import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import Layout from 'commonModule/components/Layout';
import getSlug from 'commonModule/helpers/getSlug';
import Select from 'commonModule/components/Select';
import modelEnums from 'commonModule/infrastructure/enums/modelEnums';
import createUrl from 'commonModule/helpers/createUrl';

const AdminUpdateSymptomPage = (
  {
    updateSymptomAction,
    getSingleSymptomAdminAction,
    history,
    getSingleSymptomAdminReducer,
    updateSymptomReducer,
    getSymptomListAction,
    getSymptomListReducer,
  },
) => {
  const initialState = {
    formData: new FormData(),
    processedLanguageCode: '',
    symptom: {},
    slug: getSlug(history.location.pathname),
  };
  const [values, setValues] = useState(initialState);
  useEffect(() => {
    getSymptomListAction();
  }, []);
  useEffect(() => {
    getSingleSymptomAdminAction({ slug: getSlug(history.location.pathname), shouldGetFullData: true });
  }, [updateSymptomReducer.isProcessed]);

  useEffect(() => {
    if (getSingleSymptomAdminReducer.isProcessed || updateSymptomReducer.isProcessed) {
      setValues({
        ...values,
        symptom: getSingleSymptomAdminReducer.data,
        relatedSymptoms: getSingleSymptomAdminReducer?.data?.relatedSymptoms || [],
      });
    }
  }, [getSingleSymptomAdminReducer.isProcessed, updateSymptomReducer.isProcessed]);

  const {
    formData, symptom, slug, processedLanguageCode,
  } = values;

  if (!getSingleSymptomAdminReducer.isProcessed || isEmpty(symptom)) {
    return null;
  }

  const handleChange = (name, value, languageCode) => {
    if (name === 'relatedSymptoms') {
      return setValues({
        ...values,
        symptom: { ...symptom, relatedSymptoms: value },
      });
    }


    if (languageCode) {
      const isExistingLanguageData = symptom.language.find(symptomLanguageData => symptomLanguageData.languageCode === languageCode);
      if (!isExistingLanguageData) {
        const newLanguageData = {
          languageCode,
          [name]: value,
        };
        return setValues({
          ...values,
          symptom: { ...symptom, language: [...symptom.language, newLanguageData] },
        });
      }

      const symptomLanguages = symptom.language.map(languageData => {
        if (languageData.languageCode === languageCode) {
          return {
            ...languageData,
            [name]: value,
          };
        }

        return languageData;
      });
      return setValues({ ...values, symptom: { ...symptom, language: symptomLanguages } });
    }
  };

  const handleSubmit = (e, languageCode) => {
    e.preventDefault();
    const symptomLanguageDataToUpdate = symptom.language.find(symptomLanguageData => symptomLanguageData.languageCode === languageCode);
    Object.keys(symptomLanguageDataToUpdate).forEach(key => {
      formData.set([key], symptomLanguageDataToUpdate[key]);
    });
    formData.set('relatedSymptoms', symptom?.relatedSymptoms?.map(option => option._id));
    setValues({ ...values, processedLanguageCode: languageCode });
    updateSymptomAction({ slug, updatedSymptomData: formData });
  };

  const showError = () => (updateSymptomReducer.error
    ? <div className="alert alert-danger">{updateSymptomReducer.error}</div> : '');

  const renderFormForEachLanguage = () => {
    const supportedLanguagesArray = process.env.RAZZLE_SUPPORTED_LANGUAGES.split(',');
    return supportedLanguagesArray.map(languageCode => renderNewForm(symptom?.language
      ?.find(symptom => symptom.languageCode === languageCode), languageCode));
  };

  const renderNewForm = (symptomData = {}, languageCode) => (
    <>
      <a
        target="_blank"
        rel="noreferrer"
        href={createUrl(
          modelEnums.Symptom,
          false,
          symptomData.slug,
          languageCode !== 'en' && languageCode,
        )}
      >
        {symptomData.name}
      </a>
      <form onSubmit={e => handleSubmit(e, languageCode)} key={languageCode}>
        <div className="form-group">
          <label className="text-muted">Language code</label>
          <input
            type="text"
            disabled
            className="form-control"
            value={languageCode}
            required
          />
        </div>
        {languageCode === 'en' && (
          <div className="form-group">
            <label className="text-muted">Related symptoms</label>
            <Select
              value={symptom.relatedSymptoms}
              options={getSymptomListReducer.data}
              getOptionLabel={option => option.name}
              getOptionValue={option => option._id}
              isMulti
              onChange={options => handleChange('relatedSymptoms', options)}
            />
          </div>
        )}
        <div className="form-group">
          <label className="text-muted">{`${languageCode} Symptom name`}</label>
          <input
            type="text"
            className="form-control"
            onChange={e => handleChange('name', e.target.value, languageCode)}
            value={symptomData.name}
            required
          />
        </div>
        {(updateSymptomReducer.isProcessed && processedLanguageCode === languageCode)
                && <p className="text-success">Symptom is updated</p>}
        <div>
          <button type="submit" className="btn btn-primary">
                        Update
          </button>
        </div>
      </form>
      <hr />
    </>
  );

  return (
    <Layout>
      <h2 className="text-center pt-4 pb-4">Update symptom</h2>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            {showError()}
            {renderFormForEachLanguage()}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AdminUpdateSymptomPage;
