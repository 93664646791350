import {
  put,
  takeEvery,
  call,
  fork,
  select,
  delay,
} from 'redux-saga/effects';
import {
  ADD_POST,
  ADD_POST_SUCCESS,
  ADD_POST_FAIL,
} from 'postModule/redux/modules/addPostModule';
import { addPostCall } from 'postModule/infrastructure/addPostLogic';
import loginRequiredPopupFork from 'authModule/redux/sagas/loginRequiredPopupFork';
import history from 'commonModule/helpers/history';
import createUrl from 'commonModule/helpers/createUrl';
import modelEnums from 'commonModule/infrastructure/enums/modelEnums';

function* addPost(action) {
  const { isAuth } = yield select(({ auth }) => auth.signInUserReducer);
  if (!isAuth) {
    yield fork(loginRequiredPopupFork, action);
    return;
  }

  const { response, error } = yield call(addPostCall, action.data, action.isFormData);

  if (response) {
    yield put({ type: ADD_POST_SUCCESS, data: response.data });
    yield call(history.push, createUrl(modelEnums.Post, false, response.data.slug));
  } else {
    yield put({ type: ADD_POST_FAIL, data: error });
  }
}

function* addPostSaga() {
  yield takeEvery(ADD_POST, addPost);
}
export default addPostSaga;
