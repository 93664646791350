import React, { useState, useEffect } from 'react';


const SignupForm = ({ signUpUserAction, signUpUserReducer }) => {
  const [values, setValues] = useState({
    name: '',
	    lastName: '',
    email: '',
    password: '',
  });
  const {
    isProcessing, isProcessed, error, message,
  } = signUpUserReducer;
  useEffect(() => {
    if (!isProcessing && isProcessed) {
      setValues({
        name: '',
	            lastName: '',
        email: '',
        password: '',
      });
    }
    if (error) {
      setValues({
        ...values,
      });
    }
  }, [isProcessing, isProcessed, error]);

  const {
    name, email, password, lastName,
  } = values;

  const handleSubmit = e => {
    e.preventDefault();
    setValues({ ...values, loading: true, error: false });
    const user = {
      name, email, password, lastName,
    };
    signUpUserAction(user);
  };

  const handleChange = name => e => {
    setValues({ ...values, error: false, [name]: e.target.value });
  };

  const showLoading = () => (isProcessing ? <div className="alert alert-info">Loading...</div> : '');
  const showError = () => (error ? <div className="alert alert-danger">{error}</div> : '');
  const showMessage = () => (message ? <div className="alert alert-info">{message}</div> : '');

  const signupForm = () => (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <input
          value={name}
          onChange={handleChange('name')}
          type="text"
          className="form-control"
          placeholder="Type your name"
        />
      </div>

	            <div className="form-group">
		            <input
			            value={lastName}
			            onChange={handleChange('lastName')}
			            type="text"
			            className="form-control"
			            placeholder="Type your lastName"
		            />
	            </div>

      <div className="form-group">
        <input
          value={email}
          onChange={handleChange('email')}
          type="email"
          className="form-control"
          placeholder="Type your email"
        />
      </div>

      <div className="form-group">
        <input
          value={password}
          onChange={handleChange('password')}
          type="password"
          className="form-control"
          placeholder="Type your password"
        />
      </div>

      <div>
        <button className="btn btn-primary">Signup</button>
      </div>
    </form>
  );

  return (
    <>
      {showError()}
      {showLoading()}
      {showMessage()}
      {signupForm()}
    </>
  );
};

export default SignupForm;
