import { combineReducers } from 'redux';
import genericReducer from 'commonModule/redux/modules/genericModule';
import popupsReducer from './popupsModule';

const common = combineReducers({
  popupsReducer,
  genericReducer,
});

export default common;
