import { put, takeEvery, call } from 'redux-saga/effects';
import {
  GET_SINGLE_DIAGNOSIS_ADMIN,
  GET_SINGLE_DIAGNOSIS_ADMIN_SUCCESS,
  GET_SINGLE_DIAGNOSIS_ADMIN_FAIL,
} from 'diagnosisModule/redux/modules/getSingleDiagnosisAdminModule';
import { getSingleDiagnosisCall } from 'diagnosisModule/infrastructure/getSingleDiagnosisLogic';

function* getSingleDiagnosisAdmin(action) {
  const { response, error } = yield call(getSingleDiagnosisCall, action.data);

  if (response) {
    yield put({ type: GET_SINGLE_DIAGNOSIS_ADMIN_SUCCESS, data: response.data });
  } else {
    yield put({ type: GET_SINGLE_DIAGNOSIS_ADMIN_FAIL, data: error });
  }
}

function* getSingleDiagnosisAdminSaga() {
  yield takeEvery(GET_SINGLE_DIAGNOSIS_ADMIN, getSingleDiagnosisAdmin);
}
export default getSingleDiagnosisAdminSaga;
