import { put, takeEvery, call } from 'redux-saga/effects';
import {
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAIL,
} from 'categoryModule/redux/modules/updateCategoryModule';
import { updateCategoryCall } from 'categoryModule/infrastructure/updateCategoryLogic';

function* updateCategory(action) {
  const { response, error } = yield call(updateCategoryCall, action.data, action);

  if (response) {
    yield put({ type: UPDATE_CATEGORY_SUCCESS, data: response.data });
  } else {
    yield put({ type: UPDATE_CATEGORY_FAIL, data: error });
  }
}

function* updateCategorySaga() {
  yield takeEvery(UPDATE_CATEGORY, updateCategory);
}
export default updateCategorySaga;
