import { combineReducers } from 'redux';
import signInUserReducer from 'authModule/redux/modules/signInUserModule';
import signUpUserReducer from 'authModule/redux/modules/signUpUserModule';
import actionFreezeUntilLoggedReducer from 'authModule/redux/modules/actionFreezeUntilLoggedModule';

const auth = combineReducers({
  signInUserReducer,
  signUpUserReducer,
  actionFreezeUntilLoggedReducer,
});

export default auth;
