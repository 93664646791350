import React from 'react';
import { connect } from 'react-redux';

import { getDiseaseSearchResultsAction } from 'diseaseModule/redux/modules/getDiseaseSearchResultsModule';
import SearchResults from 'diseaseModule/components/pages/SearchResults';

const SearchContainer = props => <SearchResults {...props} />;

const mapStateToProps = ({ disease: { getDiseaseSearchResultsReducer } }) => ({
  searchResults: getDiseaseSearchResultsReducer.data,
});

const mapDispatchToProps = {
  getDiseaseSearchResultsAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchContainer);
