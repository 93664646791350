import React from 'react';
import Layout from 'commonModule/components/Layout';
import './styles.scss';

const StaticPagePage = ({ getSingleStaticPageReducer }) => {
  const { title, content } = getSingleStaticPageReducer.data;
  return (
    <Layout className="pt-5 pb-4">
      <h1 className="text-center pt-5 pb-4">{title}</h1>
      <div className="container">
        <div className="row">
          <div className="col-12 pt-5 pb-4" dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </div>
    </Layout>
  );
};

export default StaticPagePage;
