import { put, takeEvery, call } from 'redux-saga/effects';
import {
  ADD_CAUSE,
  ADD_CAUSE_SUCCESS,
  ADD_CAUSE_FAIL,
} from 'causeModule/redux/modules/addCauseModule';
import { addCauseCall } from 'causeModule/infrastructure/addCauseLogic';

function* addCause(action) {
  const { response, error } = yield call(addCauseCall, action.data);

  if (response) {
    yield put({ type: ADD_CAUSE_SUCCESS, data: response.data });
  } else {
    yield put({ type: ADD_CAUSE_FAIL, data: error });
  }
}

function* addCauseSaga() {
  yield takeEvery(ADD_CAUSE, addCause);
}
export default addCauseSaga;
