import { put, takeEvery, call } from 'redux-saga/effects';
import {
  SIGN_UP_USER,
  SIGN_UP_USER_SUCCESS,
  SIGN_UP_USER_FAIL,
} from 'authModule/redux/modules/signUpUserModule';
import { signUpUserCall } from 'authModule/infrastructure/signUpUserLogic';
import history from 'commonModule/helpers/history';

function* signUpUser(action) {
  const { response, error } = yield call(signUpUserCall, action.data);

  if (response) {
    yield put({ type: SIGN_UP_USER_SUCCESS, data: response.data.message });
    yield call(history.push, '/');
  } else {
    yield put({ type: SIGN_UP_USER_FAIL, data: error });
  }
}

function* signUpUserSaga() {
  yield takeEvery(SIGN_UP_USER, signUpUser);
}
export default signUpUserSaga;
