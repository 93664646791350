import { combineReducers } from 'redux';
import getDiagnosisListReducer from 'diagnosisModule/redux/modules/getDiagnosisListModule';
import addDiagnosisReducer from 'diagnosisModule/redux/modules/addDiagnosisModule';
import updateDiagnosisReducer from 'diagnosisModule/redux/modules/updateDiagnosisModule';
import getSingleDiagnosisAdminReducer from 'diagnosisModule/redux/modules/getSingleDiagnosisAdminModule';
import getSingleDiagnosisReducer from 'diagnosisModule/redux/modules/getSingleDiagnosisModule';


const diagnosis = combineReducers({
  getDiagnosisListReducer,
  addDiagnosisReducer,
  updateDiagnosisReducer,
  getSingleDiagnosisAdminReducer,
  getSingleDiagnosisReducer,
});

export default diagnosis;
