import { put, takeEvery, call } from 'redux-saga/effects';
import {
  GET_POST_LIST,
  GET_POST_LIST_SUCCESS,
  GET_POST_LIST_FAIL,
} from 'postModule/redux/modules/getPostListModule';
import { getPostListCall } from 'postModule/infrastructure/getPostListLogic';

function* getPostList() {
  const { response, error } = yield call(getPostListCall);

  if (response) {
    yield put({ type: GET_POST_LIST_SUCCESS, data: response.data });
  } else {
    yield put({ type: GET_POST_LIST_FAIL, data: error });
  }
}

function* getPostListSaga() {
  yield takeEvery(GET_POST_LIST, getPostList);
}
export default getPostListSaga;
