export const SIGN_UP_USER = 'auth/SIGN_UP_USER';
export const SIGN_UP_USER_SUCCESS = 'auth/SIGN_UP_USER_SUCCESS';
export const SIGN_UP_USER_FAIL = 'auth/SIGN_UP_USER_FAIL';


const initialState = {
  isProcessing: false,
  isProcessed: false,
  message: '',
  error: '',
};

const signUpUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_UP_USER:
      return {
        ...state,
        signUpStoreData: {
          ...state.signUpStoreData,
          isProcessing: true,
        },
      };
    case SIGN_UP_USER_SUCCESS: {
      return {
        ...state,
        signUpStoreData: {
          ...state.signUpStoreData,
          isProcessing: false,
          isProcessed: true,
          message: action.data,
          error: '',
        },
      };
    }
    case SIGN_UP_USER_FAIL:
      return {
        ...state,
        signUpStoreData: {
          ...state.signUpStoreData,
          isProcessing: false,
          isProcessed: false,
          message: '',
          error: action.data,
        },
      };
    default:
      return state;
  }
};

export const signUpUserAction = user => ({
  type: SIGN_UP_USER,
  data: user,
});

export default signUpUserReducer;
