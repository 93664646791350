import React from 'react';
import { connect } from 'react-redux';

import withAuth from 'authModule/hocs/withAuth';
import AdminUpdateComplicationPage from 'complicationModule/components/pages/AdminUpdateComplicationPage';
import { updateComplicationAction } from 'complicationModule/redux/modules/updateComplicationModule';
import { getSingleComplicationAdminAction } from 'complicationModule/redux/modules/getSingleComplicationAdminModule';


const AdminUpdateComplicationPageContainer = props => <AdminUpdateComplicationPage {...props} />;

const mapStateToProps = ({ complication: { getSingleComplicationAdminReducer, updateComplicationReducer } }) => ({
  getSingleComplicationAdminReducer,
  updateComplicationReducer,
});

const mapDispatchToProps = {
  updateComplicationAction,
  getSingleComplicationAdminAction,
};

export default withAuth(connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminUpdateComplicationPageContainer));
