import React from 'react';
import { connect } from 'react-redux';

import withAuth from 'authModule/hocs/withAuth';
import AdminUpdateDiseasePage from 'diseaseModule/components/pages/AdminUpdateDiseasePage';
import { updateDiseaseAction } from 'diseaseModule/redux/modules/updateDiseaseModule';
import { getSingleDiseaseAdminAction } from 'diseaseModule/redux/modules/adminGetSingleDiseaseModule';
import { getCategoryListAction } from 'categoryModule/redux/modules/getCategoryListModule';
import { getSymptomListAction } from 'symptomModule/redux/modules/getSymptomListModule';


const AdminUpdateDiseasePageContainer = props => <AdminUpdateDiseasePage {...props} />;

const mapStateToProps = ({ category: { getCategoryListReducer }, disease: { updateDiseaseReducer, adminGetSingleDiseaseReducer }, symptom: { getSymptomListReducer } }) => ({
  getCategoryListReducer,
  updateDiseaseReducer,
  getSymptomListReducer,
  adminGetSingleDiseaseReducer,
});

const mapDispatchToProps = {
  updateDiseaseAction,
  getCategoryListAction,
  getSymptomListAction,
  getSingleDiseaseAdminAction,
};

export default withAuth(connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminUpdateDiseasePageContainer));
