export const GET_SINGLE_DIAGNOSIS_ADMIN = 'diagnosis/GET_SINGLE_DIAGNOSIS_ADMIN';
export const GET_SINGLE_DIAGNOSIS_ADMIN_SUCCESS = 'diagnosis/GET_SINGLE_DIAGNOSIS_ADMIN_SUCCESS';
export const GET_SINGLE_DIAGNOSIS_ADMIN_FAIL = 'diagnosis/GET_SINGLE_DIAGNOSIS_ADMIN_FAIL';

const initialState = {
  isProcessing: false,
  isProcessed: false,
  data: {},
  message: '',
  error: '',
};

const getSingleDiagnosisAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SINGLE_DIAGNOSIS_ADMIN: {
      const newDiagnosis = {
        slug: action.data.slug,
        data: {},
      };
      return {
        ...state,
        isProcessing: true,
        isProcessed: false,
        data: newDiagnosis,
      };
    }
    case GET_SINGLE_DIAGNOSIS_ADMIN_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        isProcessed: true,
        data: action.data,
      };
    }
    default:
      return state;
  }
};

export const getSingleDiagnosisAdminAction = data => ({
  type: GET_SINGLE_DIAGNOSIS_ADMIN,
  data,
});

export default getSingleDiagnosisAdminReducer;
