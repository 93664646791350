import { combineReducers } from 'redux';
import getSymptomListReducer from 'symptomModule/redux/modules/getSymptomListModule';
import addSymptomReducer from 'symptomModule/redux/modules/addSymptomModule';
import updateSymptomReducer from 'symptomModule/redux/modules/updateSymptomModule';
import getSingleSymptomAdminReducer from 'symptomModule/redux/modules/getSingleSymptomAdminModule';
import getSingleSymptomReducer from 'symptomModule/redux/modules/getSingleSymptomModule';


const symptom = combineReducers({
  getSymptomListReducer,
  addSymptomReducer,
  updateSymptomReducer,
  getSingleSymptomAdminReducer,
  getSingleSymptomReducer,
});

export default symptom;
