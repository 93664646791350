import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import Layout from 'commonModule/components/Layout';
import getSlug from 'commonModule/helpers/getSlug';

const AdminUpdateCategoryPage = (
  {
    updateCategoryAction,
    getSingleCategoryAdminAction,
    history,
    getSingleCategoryAdminReducer,
    updateCategoryReducer,
  },
) => {
  const initialState = {
    formData: new FormData(),
    processedLanguageCode: '',
    category: {},
    slug: getSlug(history.location.pathname),
  };
  const [values, setValues] = useState(initialState);
  useEffect(() => {
    getSingleCategoryAdminAction({ slug: getSlug(history.location.pathname), shouldGetFullData: true });
  }, [updateCategoryReducer.isProcessed]);

  useEffect(() => {
    if (getSingleCategoryAdminReducer.isProcessed || updateCategoryReducer.isProcessed) {
      setValues({ ...values, category: getSingleCategoryAdminReducer.data });
    }
  }, [getSingleCategoryAdminReducer.isProcessed, updateCategoryReducer.isProcessed]);

  const {
    formData, category, slug, processedLanguageCode,
  } = values;

  if (!getSingleCategoryAdminReducer.isProcessed || isEmpty(category)) {
    return null;
  }

  const handleChange = (name, value, languageCode) => {
    if (languageCode) {
      const isExistingLanguageData = category.language.find(categoryLanguageData => categoryLanguageData.languageCode === languageCode);
      if (!isExistingLanguageData) {
        const newLanguageData = {
          languageCode,
          [name]: value,
        };
        return setValues({ ...values, category: { ...category, language: [...category.language, newLanguageData] } });
      }

      const categoryLanguages = category.language.map(languageData => {
        if (languageData.languageCode === languageCode) {
          return {
            ...languageData,
            [name]: value,
          };
        }

        return languageData;
      });
      return setValues({ ...values, category: { ...category, language: categoryLanguages } });
    }
  };

  const handleSubmit = (e, languageCode) => {
    e.preventDefault();
    const categoryLanguageDataToUpdate = category.language.find(categoryLanguageData => categoryLanguageData.languageCode === languageCode);
    Object.keys(categoryLanguageDataToUpdate).forEach(key => {
      formData.set([key], categoryLanguageDataToUpdate[key]);
    });
    setValues({ ...values, processedLanguageCode: languageCode });
    updateCategoryAction({ slug, updatedCategoryData: formData });
  };

  const showError = () => (updateCategoryReducer.error ? <div className="alert alert-danger">{updateCategoryReducer.error}</div> : '');

  const renderFormForEachLanguage = () => {
    const supportedLanguagesArray = process.env.RAZZLE_SUPPORTED_LANGUAGES.split(',');
    return supportedLanguagesArray.map(languageCode => renderNewForm(category?.language
      ?.find(category => category.languageCode === languageCode), languageCode));
  };

  const renderNewForm = (categoryData = {}, languageCode) => (
    <form onSubmit={e => handleSubmit(e, languageCode)}>
      <div className="form-group">
        <label className="text-muted">Language code</label>
        <input
          type="text"
          disabled
          className="form-control"
          value={languageCode}
          required
        />
      </div>
      <div className="form-group">
        <label className="text-muted">{`${languageCode} Category name`}</label>
        <input
          type="text"
          className="form-control"
          onChange={e => handleChange('name', e.target.value, languageCode)}
          value={categoryData.name}
          required
        />
      </div>
      {(updateCategoryReducer.isProcessed && processedLanguageCode === languageCode) && <p className="text-success">Category is updated</p>}
      <div>
        <button type="submit" className="btn btn-primary">
					Update
        </button>
      </div>
    </form>
  );

  return (
    <Layout>
      <h2 className="text-center pt-4 pb-4">Update category</h2>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            {showError()}
            {renderFormForEachLanguage()}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AdminUpdateCategoryPage;
