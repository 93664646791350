export const FREEZE_ACTION_UNTIL_LOGGED_IN = 'auth/FREEZE_ACTION_UNTIL_LOGGED_IN';
export const UNFREEZE_ACTION_AFTER_LOGGED_IN = 'auth/UNFREEZE_ACTION_AFTER_LOGGED_IN';

const initialState = {
  frozenAction: null,
};

const actionFreezeUntilLoggedReducer = (state = initialState, action) => {
  switch (action.type) {
    case FREEZE_ACTION_UNTIL_LOGGED_IN:
      return {
        ...state,
        frozenAction: action.data,
      };
    case UNFREEZE_ACTION_AFTER_LOGGED_IN:
      return {
        ...state,
        frozenAction: null,
      };
    default:
      return state;
  }
};


export const freezeAction = action => ({
  type: FREEZE_ACTION_UNTIL_LOGGED_IN,
  data: action,
});

export const unfreezeAction = action => ({
  type: UNFREEZE_ACTION_AFTER_LOGGED_IN,
  data: action,
});


export default actionFreezeUntilLoggedReducer;
