import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import getSlug from 'commonModule/helpers/getSlug';
import modelEnums from 'commonModule/infrastructure/enums/modelEnums';
import ModelPageContent from 'commonModule/components/ModelPageContent';

import './styles.scss';

const SymptomPage = (
  {
    getSingleSymptomReducer,
    addPostReducer,
    addSubPostReducer,
    getSingleSymptomAction,
    history,
    addPostResetAction,
    addSubPostResetAction,
    getModelPostListReducer,
  },
) => {
  useEffect(() => {
    if (addPostReducer.isProcessed || addSubPostReducer.isProcessed) {
      getSingleSymptomAction({ slug: getSlug(history.location.pathname) });
      return () => {
        addPostResetAction();
        addSubPostResetAction();
      };
    }
  }, []);
  const { t } = useTranslation();
  const {
    _id = '', name = '', diseases = [], slug = '', relatedSymptoms = [],
  } = getSingleSymptomReducer.data;

  return (
    <ModelPageContent
      slug={slug}
      _id={_id}
      name={name}
      related={relatedSymptoms}
      relatedTitle={t('symptomPage.related')}
      diseases={diseases}
      posts={getModelPostListReducer?.data || []}
      targetModel={modelEnums.Symptom}
    />
  );
};

export default SymptomPage;
