import React, { useState } from 'react';
import {
  Button, Form, FormGroup, Input,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

import './styles.scss';

const AddPost = ({
  isPost, targetModel, targetId, onSubmitAction, isSubPostId = '', postLevel,
}) => {
  const { t } = useTranslation();
  const initialState = {
    title: '',
    content: '',
  };
  const [values, setValues] = useState(initialState);
  const { title, content } = values;

  const textAreaPlaceholderValue = isPost ? t('addPostComponent.writeAQuestion') : t('addPostComponent.writeAComment');
  const payload = {
    title,
    content,
    targetModel,
    targetId,
    isSubPostId,
    postLevel,
  };

  const handleChange = (name, value) => {
    setValues({ ...values, [name]: value });
  };

  const onSubmit = e => {
    e.preventDefault();
    handleChange('title', '');
    handleChange('content', '');
    onSubmitAction(payload);
  };

  return (
    <Form onSubmit={onSubmit}>
      <FormGroup row>
        {isPost && (
          <div className="col-sm-12">
            <Input value={title} type="text" name="text" onChange={e => handleChange('title', e.target.value)} placeholder={t('addPostComponent.writeATitle')} />
          </div>
        )}
        <div className="col-sm-12 position-relative mt-2">
          <Input value={content} className="add-post__text-area" type="textarea" name="text" onChange={e => handleChange('content', e.target.value)} placeholder={textAreaPlaceholderValue} />
          <Button type="submit" className="position-absolute" style={{ right: '30px', bottom: '10px' }}>{t('addPostComponent.submit')}</Button>
        </div>
      </FormGroup>
    </Form>
  );
};

export default AddPost;
