import { put, takeEvery, call } from 'redux-saga/effects';
import {
  ADD_DISEASE,
  ADD_DISEASE_SUCCESS,
  ADD_DISEASE_FAIL,
} from '../modules/addDiseaseModule';
import { addDiseaseCall } from '../../infrastructure/addDiseaseLogic';

function* addDisease(action) {
  const { response, error } = yield call(addDiseaseCall, action.data);

  if (response) {
    yield put({ type: ADD_DISEASE_SUCCESS, data: response.data });
  } else {
    yield put({ type: ADD_DISEASE_FAIL, data: error });
  }
}

function* addDiseaseSaga() {
  yield takeEvery(ADD_DISEASE, addDisease);
}
export default addDiseaseSaga;
