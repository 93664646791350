import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Layout from 'commonModule/components/Layout';

const NoMatch = () => {
  const { t } = useTranslation();
  return (
    <Layout className="pt-5 pb-4">
      <h2 className="text-center pt-5 pb-4">
        {t('generic.404Title')}
        {' '}
(404)
      </h2>
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3 pt-5 pb-4 text-center">
            <Link to="/">
              {t('generic.backToHomepage')}
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NoMatch;
