import React from 'react';
import { connect } from 'react-redux';
import withAuth from 'authModule/hocs/withAuth';
import AdminAddStaticPage from 'staticPageModule/components/pages/AdminAddStaticPage/AdminAddStaticPage';
import { addStaticPageAction } from 'staticPageModule/redux/modules/addStaticPageModule';

const AdminAddStaticPageContainer = props => <AdminAddStaticPage {...props} />;

const mapDispatchToProps = { addStaticPageAction };

export default withAuth(connect(
  null,
  mapDispatchToProps,
)(AdminAddStaticPageContainer));
