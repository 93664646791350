import React from 'react';
import { Badge as RSBadge } from 'reactstrap';
import { Link } from 'react-router-dom';
import BadgeEnums from 'commonModule/infrastructure/enums/badgeEnums';
import './styles.scss';

const Badge = ({ label = '', type = BadgeEnums.Info, link = '' }) => (
  <RSBadge color={type} title={label}>
    <Link to={link}>
      {label}
    </Link>
  </RSBadge>
);

export default Badge;
