import addPostSaga from './addPostSaga';
import addSubPostSaga from './addSubPostSaga';
import getSinglePostSaga from './getSinglePostSaga';
import getPostListSaga from './getPostListSaga';
import getModelPostListSaga from './getModelPostListSaga';

export default [
  addPostSaga(),
  addSubPostSaga(),
  getSinglePostSaga(),
  getPostListSaga(),
  getModelPostListSaga(),
];
