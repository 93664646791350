export const GET_USER_PROFILE = 'user/GET_USER_PROFILE';
export const GET_USER_PROFILE_SUCCESS = 'user/GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAIL = 'user/GET_USER_PROFILE_FAIL';

const initialState = {
  isProcessing: false,
  isProcessed: false,
  data: null,
  error: '',
};

const getUserProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_PROFILE:
      return {
        ...state,
        isProcessing: true,
      };
    case GET_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        isProcessed: true,
        data: action.data,
        error: '',
      };
    }
    case GET_USER_PROFILE_FAIL:
      return {
        ...state,
        isProcessing: false,
        isProcessed: false,
        data: null,
        error: action.data,
      };
    default:
      return state;
  }
};

export const getUserProfileAction = user => ({
  type: GET_USER_PROFILE,
  data: user,
});

export default getUserProfileReducer;
