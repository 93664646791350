import React from 'react';
import { connect } from 'react-redux';

import withAuth from 'authModule/hocs/withAuth';
import AdminAddDiseasePage from 'diseaseModule/components/pages/AdminAddDiseasePage';
import { addDiseaseAction } from 'diseaseModule/redux/modules/addDiseaseModule';
import { getCategoryListAction } from 'categoryModule/redux/modules/getCategoryListModule';
import { getSymptomListAction } from 'symptomModule/redux/modules/getSymptomListModule';
import { getComplicationListAction } from 'complicationModule/redux/modules/getComplicationListModule';
import { getTreatmentListAction } from 'treatmentModule/redux/modules/getTreatmentListModule';
import { getPreventionListAction } from 'preventionModule/redux/modules/getPreventionListModule';
import { getCauseListAction } from 'causeModule/redux/modules/getCauseListModule';
import { getDiagnosisListAction } from 'diagnosisModule/redux/modules/getDiagnosisListModule';


const AdminAddDiseasePageContainer = props => <AdminAddDiseasePage {...props} />;
const mapStateToProps = (
  {
    category: { getCategoryListReducer },
    disease: { addDiseaseReducer },
    symptom: { getSymptomListReducer },
    complication: { getComplicationListReducer },
    treatment: { getTreatmentListReducer },
    prevention: { getPreventionListReducer },
    cause: { getCauseListReducer },
    diagnosis: { getDiagnosisListReducer },
  },
) => ({
  getCategoryListReducer,
  addDiseaseReducer,
  getSymptomListReducer,
  getComplicationListReducer,
  getTreatmentListReducer,
  getPreventionListReducer,
  getCauseListReducer,
  getDiagnosisListReducer,
});

const mapDispatchToProps = {
  addDiseaseAction,
  getCategoryListAction,
  getSymptomListAction,
  getComplicationListAction,
  getTreatmentListAction,
  getPreventionListAction,
  getCauseListAction,
  getDiagnosisListAction,
};

export default withAuth(connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminAddDiseasePageContainer));
