import { put, takeEvery, call } from 'redux-saga/effects';
import {
  UPDATE_DIAGNOSIS,
  UPDATE_DIAGNOSIS_SUCCESS,
  UPDATE_DIAGNOSIS_FAIL,
} from 'diagnosisModule/redux/modules/updateDiagnosisModule';
import { updateDiagnosisCall } from 'diagnosisModule/infrastructure/updateDiagnosisLogic';

function* updateDiagnosis(action) {
  const { response, error } = yield call(updateDiagnosisCall, action.data, action);

  if (response) {
    yield put({ type: UPDATE_DIAGNOSIS_SUCCESS, data: response.data });
  } else {
    yield put({ type: UPDATE_DIAGNOSIS_FAIL, data: error });
  }
}

function* updateDiagnosisSaga() {
  yield takeEvery(UPDATE_DIAGNOSIS, updateDiagnosis);
}
export default updateDiagnosisSaga;
