import React, { useState, useEffect } from 'react';
import Layout from 'commonModule/components/Layout';

const AdminAddSymptomPage = ({
  getSymptomListAction, getSymptomListReducer, addSymptomAction, addSymptomReducer,
}) => {
  const [values, setValues] = useState({
    newSymptomName: '',
  });
  const { newSymptomName } = values;

  useEffect(() => {
    getSymptomListAction();
  }, []);

  useEffect(() => {
    if (addSymptomReducer.isProcessed) {
      getSymptomListAction();
    }
  }, [addSymptomReducer.isProcessed]);

  const showSymptoms = () => {
    if (getSymptomListReducer.isProcessing || !getSymptomListReducer.isProcessed) {
      return null;
    }

    return getSymptomListReducer.data.map(symptom => (
      <button
        title="Double click to delete"
        key={symptom._id}
        className="btn btn-outline-primary mr-1 ml-1 mt-3"
      >
        {symptom.name}
      </button>
    ));
  };

  const handleChange = e => {
    setValues({ ...values, newSymptomName: e.target.value });
  };

  const clickSubmit = e => {
    e.preventDefault();
    setValues({ ...values, newSymptomName: '' });
    addSymptomAction({ name: newSymptomName });
  };

  const newSymptomForm = () => (
    <form onSubmit={clickSubmit}>
      <div className="form-group">
        <label className="text-muted">Add new symptom name</label>
        <input type="text" className="form-control" onChange={handleChange} value={newSymptomName} required />
      </div>
      <div>
        <button type="submit" className="btn btn-primary">
                    Create
        </button>
      </div>
    </form>
  );

  return (
    <Layout>
      <h2 className="text-center pt-4 pb-4">Manage Symptom</h2>
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            {newSymptomForm()}
            {showSymptoms()}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AdminAddSymptomPage;
