export const GET_SINGLE_DISEASE = 'disease/GET_SINGLE_DISEASE';
export const GET_SINGLE_DISEASE_SUCCESS = 'disease/GET_SINGLE_DISEASE_SUCCESS';
export const GET_SINGLE_DISEASE_FAIL = 'disease/GET_SINGLE_DISEASE_FAIL';
export const GET_SINGLE_DISEASE_RESET = 'disease/GET_SINGLE_DISEASE_RESET';

const initialState = {
  isProcessing: false,
  isProcessed: false,
  data: {},
  message: '',
  error: '',
};

const getSingleDiseaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SINGLE_DISEASE: {
      const newDisease = {
        slug: action.data.slug,
        data: [],
      };

      return {
        ...state,
        isProcessing: true,
        isProcessed: false,
        data: newDisease,
      };
    }
    case GET_SINGLE_DISEASE_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        isProcessed: true,
        data: action.data,
      };
    }
    case GET_SINGLE_DISEASE_FAIL: {
      return {
        ...state,
        isProcessing: false,
        isProcessed: false,
        data: { shouldRedirect: true },
        message: '',
        error: action?.data || '',
      };
    }
    case GET_SINGLE_DISEASE_RESET: {
      return {
        ...state,
        isProcessing: false,
        isProcessed: false,
        data: {},
        message: '',
        error: action?.data || '',
      };
    }
    default:
      return state;
  }
};

export const getSingleDiseaseAction = data => ({
  type: GET_SINGLE_DISEASE,
  data,
});

export const getSingleDiseaseResetAction = () => ({
  type: GET_SINGLE_DISEASE_RESET,
});

export default getSingleDiseaseReducer;
