import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Layout from 'commonModule/components/Layout';
import { useTranslation } from 'react-i18next';
import modelEnums from 'commonModule/infrastructure/enums/modelEnums';
import { ListGroup, ListGroupItem } from 'reactstrap';

import createUrl from 'commonModule/helpers/createUrl';


const ProfilePage = ({ userData, getUserProfileAction, userProfileData }) => {
  const { t } = useTranslation();
  useEffect(() => {
    getUserProfileAction(userData);
  }, []);

  const renderRecentActivityList = () => userProfileData?.recentActivity?.map((el, i) => {
    let titlePrefix;
    let title;
    const date = new Date(el.createdAt).toLocaleString();
    if (el.model === modelEnums.Disease) {
      titlePrefix = t('profilePage.addedDiseaseActivity');
      title = el.name;
    }

    if (el.model === modelEnums.Post && el.isCommented) {
      titlePrefix = t('profilePage.commentedActivity');
      title = el.title;
    }

    if (el.model === modelEnums.Post && !el.isCommented) {
      titlePrefix = t('profilePage.addedPostActivity');
      title = el.title;
    }

    if (el.model === modelEnums.Post && el.isPostVoted) {
      titlePrefix = t('profilePage.votedOnPostActivity');
      title = el.title;
    }

    if (el.model === modelEnums.Post && el.isSubPostVoted) {
      titlePrefix = t('profilePage.votedOnPostReplyActivity');
      title = el.title;
    }

    return (
      <ListGroupItem key={el._id + i + title}>
        {`${titlePrefix} `}
        <Link to={createUrl(el.model, false, el.slug)}>
          {title}
        </Link>
        {` (${date})`}
      </ListGroupItem>
    );
  });


  const renderProfileActivity = () => {
    if (!userProfileData?.recentActivity?.length) {
      return <h4 className="text-center">{t('profilePage.noActivityYet')}</h4>;
    }

    return renderRecentActivityList();
  };

  return (
    <Layout>
      <h1 className="text-center pt-4 pb-4">{`${t('profilePage.hello')} ${userData.firstName}`}</h1>
      <h2 className="text-center pt-4 pb-4">{t('profilePage.yourRecentActivity')}</h2>
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <ListGroup>
              {renderProfileActivity()}
            </ListGroup>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ProfilePage;
