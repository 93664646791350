import { put, takeEvery, call } from 'redux-saga/effects';
import {
  GET_TREATMENT_LIST,
  GET_TREATMENT_LIST_SUCCESS,
  GET_TREATMENT_LIST_FAIL,
} from 'treatmentModule/redux/modules/getTreatmentListModule';
import { getTreatmentListCall } from 'treatmentModule/infrastructure/getTreatmentListLogic';

function* getTreatmentList(action) {
  const { response, error } = yield call(getTreatmentListCall, action?.data || {});

  if (response) {
    yield put({ type: GET_TREATMENT_LIST_SUCCESS, data: response.data });
  } else {
    yield put({ type: GET_TREATMENT_LIST_FAIL, data: error });
  }
}

function* getTreatmentListSaga() {
  yield takeEvery(GET_TREATMENT_LIST, getTreatmentList);
}
export default getTreatmentListSaga;
