import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import history from 'commonModule/helpers/history';
import { SLUGS } from 'coreModule/infrastructure/multiLanguageSlugs';

const withAuth = Component => props => {
  const { isAuth } = useSelector(state => state.auth.signInUserReducer);
  useEffect(
    () => {
      if (!isAuth) {
        history.push(SLUGS[process.env.RAZZLE_LANGUAGE_CODE].LOG_IN_SLUG);
      }
    },
    [isAuth],
  );

  return isAuth && <Component {...props} />;
};

export default withAuth;
