import { put, takeEvery, call } from 'redux-saga/effects';
import {
  GET_SINGLE_CAUSE_ADMIN,
  GET_SINGLE_CAUSE_ADMIN_SUCCESS,
  GET_SINGLE_CAUSE_ADMIN_FAIL,
} from 'causeModule/redux/modules/getSingleCauseAdminModule';
import { getSingleCauseCall } from 'causeModule/infrastructure/getSingleCauseLogic';

function* getSingleCauseAdmin(action) {
  const { response, error } = yield call(getSingleCauseCall, action.data);

  if (response) {
    yield put({ type: GET_SINGLE_CAUSE_ADMIN_SUCCESS, data: response.data });
  } else {
    yield put({ type: GET_SINGLE_CAUSE_ADMIN_FAIL, data: error });
  }
}

function* getSingleCauseAdminSaga() {
  yield takeEvery(GET_SINGLE_CAUSE_ADMIN, getSingleCauseAdmin);
}
export default getSingleCauseAdminSaga;
