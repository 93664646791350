import API from 'coreModule/infrastructure/api';

export const getSingleComplicationCall = ({ slug, shouldGetFullData = false }) => API.request({
  method: 'get',
  url: `/complication/${slug}`,
  params: {
    shouldGetFullData,
  },
})
  .then(response => ({ response }))
	    .catch(error => ({ error }));
