export const GET_DISEASE_SEARCH_RESULTS = 'disease/GET_DISEASE_SEARCH_RESULTS';
export const GET_DISEASE_SEARCH_RESULTS_SUCCESS = 'disease/GET_DISEASE_SEARCH_RESULTS_SUCCESS';
export const GET_DISEASE_SEARCH_RESULTS_FAIL = 'disease/GET_DISEASE_SEARCH_RESULTS_FAIL';

const initialState = {
  isProcessing: false,
  isProcessed: false,
  data: [],
  message: '',
  error: '',
};

const getDiseaseSearchResultsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DISEASE_SEARCH_RESULTS:
      return {
        ...state,
        isProcessed: false,
        isProcessing: true,
      };
    case GET_DISEASE_SEARCH_RESULTS_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isProcessing: false,
        isProcessed: true,
        message: action.data.message,
        error: '',
      };
    }
    case GET_DISEASE_SEARCH_RESULTS_FAIL:
      return {
        ...state,
        isProcessing: false,
        isProcessed: false,
        message: '',
        error: action.data,
      };
    default:
      return state;
  }
};

export const getDiseaseSearchResultsAction = data => ({
  type: GET_DISEASE_SEARCH_RESULTS,
  data,
});

export default getDiseaseSearchResultsReducer;
