export const UPDATE_STATIC_PAGE = 'staticPage/UPDATE_STATIC_PAGE';
export const UPDATE_STATIC_PAGE_SUCCESS = 'staticPage/UPDATE_STATIC_PAGE_SUCCESS';
export const UPDATE_STATIC_PAGE_FAIL = 'staticPage/UPDATE_STATIC_PAGE_FAIL';


const initialState = {
  isProcessing: false,
  isProcessed: false,
  message: '',
  error: '',
};

const updateStaticPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_STATIC_PAGE:
      return {
        ...state,
        isProcessed: false,
        isProcessing: true,
      };
    case UPDATE_STATIC_PAGE_SUCCESS: {
      return {
        ...state,
        isProcessed: true,
        isProcessing: false,
      };
    }
    case UPDATE_STATIC_PAGE_FAIL:
      return {
        ...state,
        isProcessing: false,
        isProcessed: false,
        message: '',
        error: action.data,
      };
    default:
      return state;
  }
};

export const updateStaticPageAction = data => ({
  type: UPDATE_STATIC_PAGE,
  data,
});


export default updateStaticPageReducer;
