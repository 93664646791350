import {
  put, takeEvery, call, select,
} from 'redux-saga/effects';
import {
  SIGN_IN_USER,
  SIGN_IN_USER_SUCCESS,
  SIGN_IN_USER_FAIL,
} from 'authModule/redux/modules/signInUserModule';
import history from 'commonModule/helpers/history';
import { signInUserCall, authenticate } from 'authModule/infrastructure/signInUserLogic';
import { closeAllPopupsAction } from 'commonModule/redux/modules/popupsModule';
import { unfreezeAction } from 'authModule/redux/modules/actionFreezeUntilLoggedModule';


function* signInUser(action) {
  const { response, error } = yield call(signInUserCall, action.data);

  if (response) {
    yield call(authenticate, response.data);
    yield put({ type: SIGN_IN_USER_SUCCESS, data: response.data });
    const { frozenAction } = yield select(state => state.auth.actionFreezeUntilLoggedReducer);
    if (!frozenAction) {
      yield call(history.push, '/');
    } else {
      yield put(frozenAction);
      yield put(unfreezeAction());
    }
    yield put(closeAllPopupsAction());
  } else {
    yield put({ type: SIGN_IN_USER_FAIL, data: error });
  }
}

function* signInUserSaga() {
  yield takeEvery(SIGN_IN_USER, signInUser);
}
export default signInUserSaga;
