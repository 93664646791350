export const SET_IS_LOADING = 'common/SET_IS_LOADING';

const initialState = {
  isLoading: false,
};

const genericReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.data || false,
      };
    }
    default:
      return state;
  }
};

export const setIsLoading = isLoading => ({
  type: SET_IS_LOADING,
  data: isLoading,
});

export default genericReducer;
