import React from 'react';
import ReactSelect, { createFilter } from 'react-select';
import { useTranslation } from 'react-i18next';

const Select = ({
  value, options, onChange, placeholder, isMulti = false, inputId, className, getOptionLabel, getOptionValue,
}) => {
  const { t } = useTranslation();
  const shouldStartTypingToSeeResults = (input) => {
    const inputValueLength = input.trim().length;
    if (options?.length < 200 && inputValueLength >= 0) {
      return false;
    }

    if (options?.length > 200 && inputValueLength < 1) {
      return true;
    }

    if (options?.length > 400 && inputValueLength < 2) {
      return true;
    }

    if (options?.length > 1200 && inputValueLength < 3) {
      return true;
    }
  };

  const modifyFilterOptions = (e, input) => {
    if (shouldStartTypingToSeeResults(input)) {
      return;
    }
    return createFilter({ ignoreAccents: false })(e, input);
  };

  const showOptionsLabel = (e) => {
    if (shouldStartTypingToSeeResults(e.inputValue)) {
      return t('generic.startTypingToSeeResults');
    }
    return t('generic.noOptions');
  };
  return (
    <ReactSelect
      inputId={inputId}
      className={className}
      noOptionsMessage={(inputValue) => showOptionsLabel(inputValue)}
      value={value}
      placeholder={placeholder || t('generic.select')}
      options={options}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      isMulti={isMulti}
      onChange={onChange}
      filterOption={(e, input) => modifyFilterOptions(e, input)}
    />
  );
};

export default Select;
