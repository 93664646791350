import { put, takeEvery, call } from 'redux-saga/effects';
import {
  GET_USER_PROFILE,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAIL,
} from 'userModule/redux/modules/getUserProfileModule';
import { getUserProfileCall } from 'userModule/infrastructure/getProfile';

function* getProfile(action) {
  const { response, error } = yield call(getUserProfileCall, action.data);

  if (response) {
    yield put({ type: GET_USER_PROFILE_SUCCESS, data: response.data });
  } else {
    yield put({ type: GET_USER_PROFILE_FAIL, data: error });
  }
}

function* getUserProfileSaga() {
  yield takeEvery(GET_USER_PROFILE, getProfile);
}
export default getUserProfileSaga;
