export const UPDATE_CATEGORY = 'category/UPDATE_CATEGORY';
export const UPDATE_CATEGORY_SUCCESS = 'category/UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAIL = 'category/UPDATE_CATEGORY_FAIL';


const initialState = {
  isProcessing: false,
  isProcessed: false,
  message: '',
  error: '',
};

const updateCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CATEGORY:
      return {
        ...state,
        isProcessed: false,
        isProcessing: true,
      };
    case UPDATE_CATEGORY_SUCCESS: {
      return {
        ...state,
        isProcessed: true,
        isProcessing: false,
      };
    }
    case UPDATE_CATEGORY_FAIL:
      return {
        ...state,
        isProcessing: false,
        isProcessed: false,
        message: '',
        error: action.data,
      };
    default:
      return state;
  }
};

export const updateCategoryAction = data => ({
  type: UPDATE_CATEGORY,
  data,
});


export default updateCategoryReducer;
