import React from 'react';
import { Link } from 'react-router-dom';
import {
  Card, CardHeader, Row, Col,
} from 'reactstrap';
import createUrl from 'commonModule/helpers/createUrl';

import './styles.scss';


const TileList = ({ data, modelType, isLink }) => {
  const renderCard = (item) => {
    const card = (
      <Card>
        <CardHeader>{item.name}</CardHeader>
      </Card>
    );

    if (isLink) {
      return (
        <Link to={createUrl(modelType, false, item.slug)}>
          {card}
        </Link>
      );
    }
    return card;
  };


  return (
    <Row className="tile-list">
      {data.map(item => (
        <Col sm="6" lg="4" className="tile-list__tile" key={item._id}>
          {renderCard(item)}
        </Col>
      ))}
    </Row>
  );
};

export default TileList;
