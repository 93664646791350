import React from 'react';
import { connect } from 'react-redux';

import ComplicationPage from 'complicationModule/components/pages/ComplicationPage';
import {
  GET_SINGLE_COMPLICATION_FAIL,
  GET_SINGLE_COMPLICATION_SUCCESS,
  getSingleComplicationAction,
} from 'complicationModule/redux/modules/getSingleComplicationModule';
import getSlug from 'commonModule/helpers/getSlug';
import fetchInitialData from 'commonModule/helpers/fetchInitialData';
import { getSingleComplicationCall } from 'complicationModule/infrastructure/getSingleComplicationLogic';
import { addPostResetAction } from 'postModule/redux/modules/addPostModule';
import { addSubPostResetAction } from 'postModule/redux/modules/addSubPostModule';
import { getModelPostListCall } from 'postModule/infrastructure/getModelPostListLogic';
import modelEnums from 'commonModule/infrastructure/enums/modelEnums';
import {
  GET_MODEL_POST_LIST_FAIL,
  GET_MODEL_POST_LIST_SUCCESS,
} from 'postModule/redux/modules/getModelPostListModule';
import Loader from 'commonModule/components/Loader';

const ComplicationPageContainer = props => props.isLoading ? <Loader isFullscreen /> : <ComplicationPage {...props} />;

const mapStateToProps = ({ complication: { getSingleComplicationReducer }, post: { addPostReducer, addSubPostReducer, getModelPostListReducer }, common: { genericReducer } }) => ({
  getSingleComplicationReducer,
  addPostReducer,
  addSubPostReducer,
  getModelPostListReducer,
});

const mapDispatchToProps = { getSingleComplicationAction, addPostResetAction, addSubPostResetAction };

ComplicationPageContainer.getInitialProps = async ({
  req, res, match, history, location, store, ...ctx
}) => await Promise.all([
  await fetchInitialData(
    store,
    () => getSingleComplicationCall({ slug: getSlug(match.url) }),
    GET_SINGLE_COMPLICATION_SUCCESS,
    GET_SINGLE_COMPLICATION_FAIL,
    res,
  ),
  await fetchInitialData(
    store,
    () => getModelPostListCall({ slug: getSlug(match.url), modelEnum: modelEnums.Complication }),
    GET_MODEL_POST_LIST_SUCCESS,
    GET_MODEL_POST_LIST_FAIL,
    res,
  ),
]);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ComplicationPageContainer);
