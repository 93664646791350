import axios from 'axios';

const API_URL = process.env.NODE_ENV === 'development' ? process.env.RAZZLE_API_DEVELOPMENT : process.env.RAZZLE_API_PROD;

export default axios.create({
  baseURL: API_URL,
  responseType: 'json',
  params: {
    lang: process.env.RAZZLE_LANGUAGE_CODE,
  },
});
