import API from 'coreModule/infrastructure/api';

export const getDiseaseListCall = ({ shouldGetFullData = false }) => API.request({
  method: 'get',
  url: '/diseases',
  params: {
    shouldGetFullData,
  },
})
  .then(response => ({ response }))
  .catch(error => ({ error: error?.response?.data?.error }));
