import React from 'react';
import Layout from 'commonModule/components/Layout';
import { Helmet } from 'react-helmet';
import { Jumbotron } from 'reactstrap';
import TileList from 'commonModule/components/TileList';
import AddPostContainer from 'postModule/containers/AddPostContainer';
import PostList from 'postModule/components/PostList';
import { useTranslation } from 'react-i18next';
import ShowAllPostsButtonContainer from 'postModule/containers/ShowAllPostsButtonContainer';

import './styles.scss';
import modelEnums from 'commonModule/infrastructure/enums/modelEnums';
import TargetModelPrefix from 'commonModule/components/TargetModelPrefix';

const ModelPageContent = ({
  name, diseases, posts, _id, targetModel, slug, related = [], relatedTitle = '',
}) => {
  const { t } = useTranslation();
  const mTitle = () => `${name} - ${targetModel}`;

  const mDescription = () => `${t('generic.relatedDiseases')}${diseases?.map(el => ` ${el?.name}`)}`;

  const renderRelatedModels = () => {
    if (!related?.length) {
      return null;
    }
    return (
      <p className="text-center pt-4 pb-4">
        {`${relatedTitle} ${related?.map(el => ` ${el?.name}`)}`}
      </p>
    );
  };

  return (
    <>
      <Helmet>
        <title>{mTitle()}</title>
        <meta name="description" content={mDescription()} />
      </Helmet>
      <div className="model-page-content">
        <Layout>
          <Jumbotron>
            <div className="container">
              <TargetModelPrefix modelEnum={targetModel} />
              <h1 className="text-center pt-4 pb-4 display-4">{name}</h1>
              {renderRelatedModels()}
              <div className="row">
                <div className="col-md-8 offset-md-2">
                  <h2 className="disease__section-title">
                    {t('generic.diseases')}
                    :
                  </h2>
                  <TileList data={diseases} isLink modelType={modelEnums.Disease} />
                </div>
              </div>

            </div>
          </Jumbotron>
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2 mt-5">
                <div className="featured">
                  <h2 className="mb-3">
                    {t('generic.askQuestion', { variable: name })}
                    :
                  </h2>
                  <p>{t('generic.postIncentive', { variable: name })}</p>
                  <AddPostContainer targetId={_id} targetModel={targetModel} />
                </div>
                <ShowAllPostsButtonContainer targetSlug={slug} targetModel={targetModel} />
                <PostList data={posts} />
              </div>
            </div>
          </div>
        </Layout>
      </div>
    </>
  );
};

export default ModelPageContent;
