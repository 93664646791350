import React from 'react';
import { put } from 'redux-saga/effects';
import { Alert } from 'reactstrap';
import { addPopupAction } from 'commonModule/redux/modules/popupsModule';
import { freezeAction } from 'authModule/redux/modules/actionFreezeUntilLoggedModule';
import SignInGoogleContainer from 'authModule/containers/SignInGoogleContainer';
import SignInFacebookContainer from 'authModule/containers/SignInFacebookContainer';
import i18n from '../../../i18n';

function* loginRequiredPopupFork(action) {
  yield put(addPopupAction({
    frontendId: new Date().getTime(),
    title: i18n.t('authGeneric.requiredLogIn'),
    closeText: i18n.t('generic.cancel'),
    primaryButtonAction: {
      type: null,
      data: null,
    },
    content: (
      <>
        <Alert color="primary">{i18n.t('logInPage.privacyDisclaimer')}</Alert>
        <SignInFacebookContainer />
        <SignInGoogleContainer />
      </>
    ),
  }));
  yield put(freezeAction(action));
}

export default loginRequiredPopupFork;
