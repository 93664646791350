import { combineReducers } from 'redux';
import getComplicationListReducer from 'complicationModule/redux/modules/getComplicationListModule';
import addComplicationReducer from 'complicationModule/redux/modules/addComplicationModule';
import updateComplicationReducer from 'complicationModule/redux/modules/updateComplicationModule';
import getSingleComplicationAdminReducer from 'complicationModule/redux/modules/getSingleComplicationAdminModule';
import getSingleComplicationReducer from 'complicationModule/redux/modules/getSingleComplicationModule';


const complication = combineReducers({
  getComplicationListReducer,
  addComplicationReducer,
  updateComplicationReducer,
  getSingleComplicationAdminReducer,
  getSingleComplicationReducer,
});

export default complication;
