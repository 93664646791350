const modelEnums = {
  Disease: 'Disease',
  Symptom: 'Symptom',
  Complication: 'Complication',
  Category: 'Category',
  Post: 'Post',
  User: 'User',
  Vote: 'Vote',
  Treatment: 'Treatment',
  Diagnosis: 'Diagnosis',
  Cause: 'Cause',
  Prevention: 'Prevention',
  StaticPage: 'StaticPage',
};

export default modelEnums;
