import { put, takeEvery, call } from 'redux-saga/effects';
import {
  GET_CAUSE_LIST,
  GET_CAUSE_LIST_SUCCESS,
  GET_CAUSE_LIST_FAIL,
} from 'causeModule/redux/modules/getCauseListModule';
import { getCauseListCall } from 'causeModule/infrastructure/getCauseListLogic';

function* getCauseList(action) {
  const { response, error } = yield call(getCauseListCall, action?.data || {});

  if (response) {
    yield put({ type: GET_CAUSE_LIST_SUCCESS, data: response.data });
  } else {
    yield put({ type: GET_CAUSE_LIST_FAIL, data: error });
  }
}

function* getCauseListSaga() {
  yield takeEvery(GET_CAUSE_LIST, getCauseList);
}
export default getCauseListSaga;
