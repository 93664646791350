import React from 'react';
import { connect } from 'react-redux';

import withAuth from 'authModule/hocs/withAuth';
import AdminUpdateCategoryPage from 'categoryModule/components/pages/AdminUpdateCategoryPage';
import { updateCategoryAction } from 'categoryModule/redux/modules/updateCategoryModule';
import { getSingleCategoryAdminAction } from 'categoryModule/redux/modules/getSingleCategoryAdminModule';

const AdminUpdateCategoryPageContainer = props => <AdminUpdateCategoryPage {...props} />;

const mapStateToProps = ({ category: { getSingleCategoryAdminReducer, updateCategoryReducer } }) => ({
  getSingleCategoryAdminReducer,
  updateCategoryReducer,
});

const mapDispatchToProps = {
  updateCategoryAction,
  getSingleCategoryAdminAction,
};

export default withAuth(connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminUpdateCategoryPageContainer));
