export const GET_SINGLE_TREATMENT_ADMIN = 'treatment/GET_SINGLE_TREATMENT_ADMIN';
export const GET_SINGLE_TREATMENT_ADMIN_SUCCESS = 'treatment/GET_SINGLE_TREATMENT_ADMIN_SUCCESS';
export const GET_SINGLE_TREATMENT_ADMIN_FAIL = 'treatment/GET_SINGLE_TREATMENT_ADMIN_FAIL';

const initialState = {
  isProcessing: false,
  isProcessed: false,
  data: {},
  message: '',
  error: '',
};

const getSingleTreatmentAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SINGLE_TREATMENT_ADMIN: {
      const newTreatment = {
        slug: action.data.slug,
        data: {},
      };
      return {
        ...state,
        isProcessing: true,
        isProcessed: false,
        data: newTreatment,
      };
    }
    case GET_SINGLE_TREATMENT_ADMIN_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        isProcessed: true,
        data: action.data,
      };
    }
    default:
      return state;
  }
};

export const getSingleTreatmentAdminAction = data => ({
  type: GET_SINGLE_TREATMENT_ADMIN,
  data,
});

export default getSingleTreatmentAdminReducer;
