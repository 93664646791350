import { put, takeEvery, call } from 'redux-saga/effects';
import {
  GET_SINGLE_CAUSE,
  GET_SINGLE_CAUSE_SUCCESS,
  GET_SINGLE_CAUSE_FAIL,
} from '../modules/getSingleCauseModule';
import { getSingleCauseCall } from '../../infrastructure/getSingleCauseLogic';

function* getSingleCause(action) {
  const { response, error } = yield call(getSingleCauseCall, action.data, action);

  if (response) {
    yield put({ type: GET_SINGLE_CAUSE_SUCCESS, data: response.data });
  } else {
    yield put({ type: GET_SINGLE_CAUSE_FAIL, data: error });
  }
}

function* getSingleCauseSaga() {
  yield takeEvery(GET_SINGLE_CAUSE, getSingleCause);
}
export default getSingleCauseSaga;
