import { combineReducers } from 'redux';
import getPreventionListReducer from 'preventionModule/redux/modules/getPreventionListModule';
import addPreventionReducer from 'preventionModule/redux/modules/addPreventionModule';
import updatePreventionReducer from 'preventionModule/redux/modules/updatePreventionModule';
import getSinglePreventionAdminReducer from 'preventionModule/redux/modules/getSinglePreventionAdminModule';
import getSinglePreventionReducer from 'preventionModule/redux/modules/getSinglePreventionModule';


const prevention = combineReducers({
  getPreventionListReducer,
  addPreventionReducer,
  updatePreventionReducer,
  getSinglePreventionAdminReducer,
  getSinglePreventionReducer,
});

export default prevention;
