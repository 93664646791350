import React from 'react';
import { connect } from 'react-redux';
import SignupForm from 'authModule/components/SignupForm';
import { signUpUserAction } from 'authModule/redux/modules/signUpUserModule';

const SignUpFormContainer = props => (
  <SignupForm {...props} />
);

const mapStateToProps = ({ auth: { signUpUserReducer } }) => ({
  signUpUserReducer,
});

const mapDispatchToProps = {
  signUpUserAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignUpFormContainer);

