import { put, takeEvery, call } from 'redux-saga/effects';
import {
  UPDATE_COMPLICATION,
  UPDATE_COMPLICATION_SUCCESS,
  UPDATE_COMPLICATION_FAIL,
} from 'complicationModule/redux/modules/updateComplicationModule';
import { updateComplicationCall } from 'complicationModule/infrastructure/updateComplicationLogic';

function* updateComplication(action) {
  const { response, error } = yield call(updateComplicationCall, action.data, action);

  if (response) {
    yield put({ type: UPDATE_COMPLICATION_SUCCESS, data: response.data });
  } else {
    yield put({ type: UPDATE_COMPLICATION_FAIL, data: error });
  }
}

function* updateComplicationSaga() {
  yield takeEvery(UPDATE_COMPLICATION, updateComplication);
}
export default updateComplicationSaga;
