import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Jumbotron } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Layout from 'commonModule/components/Layout';
import PostList from 'postModule/components/PostList';
import TileList from 'commonModule/components/TileList';
import modelEnums from 'commonModule/infrastructure/enums/modelEnums';
import AddPostContainer from 'postModule/containers/AddPostContainer';
import getSlug from 'commonModule/helpers/getSlug';
import { shortenDescription, getPropertiesMetaDescription } from 'commonModule/helpers/seo';
import ShowAllPostsButtonContainer from 'postModule/containers/ShowAllPostsButtonContainer';

import './styles.scss';
import TargetModelPrefix from 'commonModule/components/TargetModelPrefix';

const DiseasePage = (
  {
    getSingleDiseaseReducer,
    getModelPostListReducer,
    addPostReducer,
    addSubPostReducer,
    getSingleDiseaseAction,
    history,
    addPostResetAction,
    addSubPostResetAction,
  },
) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (addPostReducer.isProcessed || addSubPostReducer.isProcessed) {
      getSingleDiseaseAction({ slug: getSlug(history.location.pathname) });
      return () => {
        addPostResetAction();
        addSubPostResetAction();
      };
    }
  }, []);

  const {
    _id = '', slug = '', name = '', description = '', categories = [], symptoms = [], complications = [], treatments = [], preventions = [], causes = [], diagnoses = [], relatedDiseases = [], synonyms = '',
  } = getSingleDiseaseReducer.data;
  const renderTileList = (sectionName, arrayData, modelType, isLink) => {
    if (!arrayData.length) {
      return null;
    }

    return (
      <div className="col-md-8 offset-md-2" key={sectionName}>
        <h2 className="disease__section-title">
          {sectionName}
          :
        </h2>
        <TileList data={arrayData} modelType={modelType} isLink={isLink} />
      </div>
    );
  };

  const renderSynonymsString = () => {
    if (!synonyms?.length) {
      return '';
    }

    return ` (${synonyms})`;
  };

  const propertiesArray = [{
    modelEnum: modelEnums.Category,
    name: t('generic.categories'),
    tilesTitle: t('generic.categories'),
    data: categories,
    isLink: false,
  }, {
    modelEnum: modelEnums.Symptom,
    name: t('generic.symptoms'),
    tilesTitle: t('generic.symptomsQuestion', { variable: name }),
    data: symptoms,
    isLink: true,
  }, {
    modelEnum: modelEnums.Complication,
    name: t('generic.complications'),
    tilesTitle: t('generic.complicationsQuestion', { variable: name }),
    data: complications,
    isLink: true,
  }, {
    modelEnum: modelEnums.Treatment,
    name: t('generic.treatments'),
    tilesTitle: t('generic.treatmentsQuestion', { variable: name }),
    data: treatments,
    isLink: true,
  }, {
    modelEnum: modelEnums.Prevention,
    name: t('generic.preventions'),
    tilesTitle: t('generic.preventionsQuestion', { variable: name }),
    data: preventions,
    isLink: true,
  }, {
    modelEnum: modelEnums.Cause,
    name: t('generic.causes'),
    tilesTitle: t('generic.causesQuestion', { variable: name }),
    data: causes,
    isLink: true,
  }, {
    modelEnum: modelEnums.Diagnosis,
    name: t('generic.diagnoses'),
    tilesTitle: t('generic.diagnosisQuestion', { variable: name }),
    data: diagnoses,
    isLink: true,
  }, {
    modelEnum: modelEnums.Disease,
    name: t('generic.relatedDiseases'),
    tilesTitle: t('generic.symptomsQuestion', { variable: name }),
    data: relatedDiseases,
    isLink: true,
  }];

  const mTitle = () => `${name}${renderSynonymsString()} - ${modelEnums.Disease}`;

  return (
    <>
      <Helmet>
        <title>{mTitle()}</title>
        <meta name="description" content={`${shortenDescription(description)}${getPropertiesMetaDescription(propertiesArray)}`} />
      </Helmet>
      <Layout>
        <div className="disease">
          <Jumbotron>
            <div className="container">
              <TargetModelPrefix modelEnum={modelEnums.Disease} />
              <h1 className="text-center pt-4 pb-4 display-4">{`${name}${renderSynonymsString()}`}</h1>
              <div className="row">
                {description && (
                  <div className="col-md-8 offset-md-2">
                    <p>{description}</p>
                  </div>
                )}
                {propertiesArray.map(el => renderTileList(el.name, el.data, el.modelEnum, el.isLink))}
              </div>
            </div>
          </Jumbotron>

          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2 mt-5">
                <div className="featured">
                  <h2 className="mb-3">{t('generic.askQuestionOrShare', { variable: name })}</h2>
                  <p>{t('generic.postIncentive', { variable: name })}</p>
                  <AddPostContainer targetId={_id} targetModel={modelEnums.Disease} />
                </div>
                <ShowAllPostsButtonContainer targetSlug={slug} targetModel={modelEnums.Disease} />
                <PostList data={getModelPostListReducer?.data || []} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default DiseasePage;
