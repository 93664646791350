import React from 'react';
import { connect } from 'react-redux';
import { getModelPostListAction } from 'postModule/redux/modules/getModelPostListModule';
import ShowAllPostsButton from 'postModule/components/ShowAllPostsButton';

const ShowAllPostsButtonContainer = (props) => <ShowAllPostsButton {...props} />;

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  getModelPostListAction,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  getModelPostListActionWithParam: () => dispatchProps.getModelPostListAction({ slug: ownProps.targetSlug, modelEnum: ownProps.targetModel, lang: null }),

});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(ShowAllPostsButtonContainer);
