import React from 'react';
import { connect } from 'react-redux';

import AddPostPage from 'postModule/components/pages/AddPostPage';
import { addPostAction } from 'postModule/redux/modules/addPostModule';
import { getDiseaseListAction } from 'diseaseModule/redux/modules/getDiseaseListModule';
import { getCategoryListAction } from 'categoryModule/redux/modules/getCategoryListModule';
import { getSymptomListAction } from 'symptomModule/redux/modules/getSymptomListModule';
import { getComplicationListAction } from 'complicationModule/redux/modules/getComplicationListModule';
import { getTreatmentListAction } from 'treatmentModule/redux/modules/getTreatmentListModule';
import { getCauseListAction } from 'causeModule/redux/modules/getCauseListModule';
import { getDiagnosisListAction } from 'diagnosisModule/redux/modules/getDiagnosisListModule';
import { getPreventionListAction } from 'preventionModule/redux/modules/getPreventionListModule';

const AddPostPageContainer = props => <AddPostPage {...props} />;

const mapStateToProps = (
  {
    category: { getCategoryListReducer },
    disease: { getDiseaseListReducer },
    symptom: { getSymptomListReducer },
    post: { addPostReducer },
    complication: { getComplicationListReducer },
    diagnosis: { getDiagnosisListReducer },
    prevention: { getPreventionListReducer },
    treatment: { getTreatmentListReducer },
    cause: { getCauseListReducer },
  },
) => (
  {
    getCategoryListReducer,
    addPostReducer,
    getSymptomListReducer,
    getDiseaseListReducer,
    getComplicationListReducer,
    getDiagnosisListReducer,
    getPreventionListReducer,
    getTreatmentListReducer,
    getCauseListReducer,
  }
);

const mapDispatchToProps = {
  addPostAction,
  getCategoryListAction,
  getSymptomListAction,
  getDiseaseListAction,
  getComplicationListAction,
  getTreatmentListAction,
  getCauseListAction,
  getDiagnosisListAction,
  getPreventionListAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddPostPageContainer);
