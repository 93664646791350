import addComplicationSaga from './addComplicationSaga';
import getComplicationListSaga from './getComplicationListSaga';
import getSingleComplicationAdminSaga from './getSingleComplicationAdminSaga';
import updateComplicationSaga from './updateComplicationSaga';
import getSingleComplicationSaga from './getSingleComplicationSaga';

export default [
  addComplicationSaga(),
  getComplicationListSaga(),
  getSingleComplicationAdminSaga(),
  updateComplicationSaga(),
  getSingleComplicationSaga(),
];
