import React from 'react';
import Layout from 'commonModule/components/Layout';
import SignUpFormContainer from 'authModule/containers/SignUpFormContainer';
import { useTranslation } from 'react-i18next';

const SignupPage = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <h2 className="text-center pt-4 pb-4">{t('signUpPage.title')}</h2>
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <SignUpFormContainer />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SignupPage;
