import { truncate } from 'lodash';

export const shortenDescription = (description) => {
  if (!description) {
    return '';
  }

  return `${truncate(description, { length: 180 })} `;
};

const getPropertiesList = (elName, data, i) => {
  if (!data?.length) {
    return null;
  }

  const addSpacing = i > 0 ? ' ' : '';

  return `${addSpacing}${elName}:${data.map(el => ` ${el.name}`)}`;
};

export const getPropertiesMetaDescription = (propertiesArray) => propertiesArray
  .filter(el => el.data.length)
  .map((el, i) => `${getPropertiesList(el.name, el.data, i)}`);
