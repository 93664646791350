import React from 'react';
import { connect } from 'react-redux';

import withAuth from 'authModule/hocs/withAuth';
import AdminCauseListPage from 'causeModule/components/pages/AdminCauseListPage';
import { getCauseListAction } from 'causeModule/redux/modules/getCauseListModule';


const AdminCauseListPageContainer = props => <AdminCauseListPage {...props} />;

const mapStateToProps = ({ cause: { getCauseListReducer } }) => ({
  getCauseListReducer,
});

const mapDispatchToProps = { getCauseListAction };

export default withAuth(connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminCauseListPageContainer));
