import { combineReducers } from 'redux';

import addSubPostReducer from 'postModule/redux/modules/addSubPostModule';
import addPostReducer from 'postModule/redux/modules/addPostModule';
import getSinglePostReducer from 'postModule/redux/modules/getSinglePostModule';
import getPostListReducer from 'postModule/redux/modules/getPostListModule';
import getModelPostListReducer from 'postModule/redux/modules/getModelPostListModule';

const post = combineReducers({
  addPostReducer,
  addSubPostReducer,
  getSinglePostReducer,
  getPostListReducer,
  getModelPostListReducer,
});

export default post;
