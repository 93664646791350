import redirect404 from 'commonModule/helpers/redirect404';

export default (store, apiCall, successResponseDispatch, failResponseDispatch, res) => {
  return new Promise(async (resolve) => {
    try {
      const { response, error } = await apiCall();
      if (response) {
        await store.dispatch({ type: successResponseDispatch, data: response.data });
        return resolve(true);
      }
      console.log('fetchInitialData error: ', error);
      redirect404(res, error);
      await store.dispatch({ type: failResponseDispatch, data: error });
      return resolve(false);
    } catch (error) {
      console.log('catch fetchInitialData error: ', error);
      redirect404(res, error);
      await store.dispatch({ type: failResponseDispatch, data: error });
      return resolve(false);
    }
  });
};
