import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import Layout from 'commonModule/components/Layout';
import getSlug from 'commonModule/helpers/getSlug';

const AdminUpdateCausePage = (
  {
    updateCauseAction,
    getSingleCauseAdminAction,
    history,
    getSingleCauseAdminReducer,
    updateCauseReducer,
  },
) => {
  const initialState = {
    formData: new FormData(),
    processedLanguageCode: '',
    cause: {},
    slug: getSlug(history.location.pathname),
  };
  const [values, setValues] = useState(initialState);
  useEffect(() => {
    getSingleCauseAdminAction({ slug: getSlug(history.location.pathname), shouldGetFullData: true });
  }, [updateCauseReducer.isProcessed]);

  useEffect(() => {
    if (getSingleCauseAdminReducer.isProcessed || updateCauseReducer.isProcessed) {
      setValues({ ...values, cause: getSingleCauseAdminReducer.data });
    }
  }, [getSingleCauseAdminReducer.isProcessed, updateCauseReducer.isProcessed]);

  const {
    formData, cause, slug, processedLanguageCode,
  } = values;

  if (!getSingleCauseAdminReducer.isProcessed || isEmpty(cause)) {
    return null;
  }

  const handleChange = (name, value, languageCode) => {
    if (languageCode) {
      const isExistingLanguageData = cause.language.find(causeLanguageData => causeLanguageData.languageCode === languageCode);
      if (!isExistingLanguageData) {
        const newLanguageData = {
          languageCode,
          [name]: value,
        };
        return setValues({ ...values, cause: { ...cause, language: [...cause.language, newLanguageData] } });
      }

      const causeLanguages = cause.language.map(languageData => {
        if (languageData.languageCode === languageCode) {
          return {
            ...languageData,
            [name]: value,
          };
        }

        return languageData;
      });
      return setValues({ ...values, cause: { ...cause, language: causeLanguages } });
    }
  };

  const handleSubmit = (e, languageCode) => {
    e.preventDefault();
    const causeLanguageDataToUpdate = cause.language.find(causeLanguageData => causeLanguageData.languageCode === languageCode);
    Object.keys(causeLanguageDataToUpdate).forEach(key => {
      formData.set([key], causeLanguageDataToUpdate[key]);
    });
    setValues({ ...values, processedLanguageCode: languageCode });
    updateCauseAction({ slug, updatedCauseData: formData });
  };

  const showError = () => (updateCauseReducer.error ? (
    <div
      className="alert alert-danger"
    >
      {updateCauseReducer.error}
    </div>
  ) : '');

  const renderFormForEachLanguage = () => {
    const supportedLanguagesArray = process.env.RAZZLE_SUPPORTED_LANGUAGES.split(',');
    return supportedLanguagesArray.map(languageCode => renderNewForm(cause?.language
      ?.find(cause => cause.languageCode === languageCode), languageCode));
  };

  const renderNewForm = (causeData = {}, languageCode) => (
    <>
      <form onSubmit={e => handleSubmit(e, languageCode)}>
        <div className="form-group">
          <label className="text-muted">Language code</label>
          <input
            type="text"
            disabled
            className="form-control"
            value={languageCode}
            required
          />
        </div>
        <div className="form-group">
          <label className="text-muted">{`${languageCode} Cause name`}</label>
          <input
            type="text"
            className="form-control"
            onChange={e => handleChange('name', e.target.value, languageCode)}
            value={causeData.name}
            required
          />
        </div>
        {(updateCauseReducer.isProcessed && processedLanguageCode === languageCode)
        && <p className="text-success">Cause is updated</p>}
        <div>
          <button type="submit" className="btn btn-primary">
            Update
          </button>
        </div>
      </form>
      <hr />
    </>
  );

  return (
    <Layout>
      <h2 className="text-center pt-4 pb-4">Update cause</h2>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            {showError()}
            {renderFormForEachLanguage()}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AdminUpdateCausePage;
