import React from 'react';
import withAuth from 'authModule/hocs/withAuth';
import AdminAddSymptomPage from 'symptomModule/components/pages/AdminAddSymptomPage';
import { getSymptomListAction } from 'symptomModule/redux/modules/getSymptomListModule';
import { addSymptomAction } from 'symptomModule/redux/modules/addSymptomModule';
import { connect } from 'react-redux';

const AdminAddSymptomPageContainer = props => <AdminAddSymptomPage {...props} />;

const mapStateToProps = ({ symptom: { getSymptomListReducer, addSymptomReducer } }) => ({
  getSymptomListReducer,
  addSymptomReducer,
});

const mapDispatchToProps = {
  getSymptomListAction,
  addSymptomAction,
};

export default withAuth(connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminAddSymptomPageContainer));
