export const SIGN_IN_USER = 'auth/SIGN_IN_USER';
export const SIGN_IN_USER_SUCCESS = 'auth/SIGN_IN_USER_SUCCESS';
export const SIGN_IN_USER_FAIL = 'auth/SIGN_IN_USER_FAIL';

export const SIGN_OUT_USER = 'auth/SIGN_OUT_USER';
export const SIGN_OUT_USER_SUCCESS = 'auth/SIGN_OUT_USER_SUCCESS';
export const SIGN_OUT_USER_FAIL = 'auth/SIGN_OUT_USER_FAIL';

export const SIGN_IN_GOOGLE_USER = 'auth/SIGN_IN_GOOGLE_USER';
export const SIGN_IN_FACEBOOK_USER = 'auth/SIGN_IN_FACEBOOK_USER';


const initialState = {
  userDetails: {
    role: 0,
  },
  isAuth: false,
  signIn: {
    isProcessing: false,
    isProcessed: false,
    error: '',
  },
};

const signInUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_IN_USER:
      return {
        ...state,
        signIn: {
          ...state.signIn,
          isProcessing: true,
        },
      };
    case SIGN_IN_USER_SUCCESS: {
      return {
        ...state,
        userDetails: action.data.user,
        isAuth: true,
        signIn: {
          ...state.signIn,
          isProcessing: false,
          isProcessed: true,
          error: '',
        },
      };
    }
    case SIGN_IN_USER_FAIL:
      return {
        ...state,
        signIn: {
          ...state.signIn,
          isProcessing: false,
          isProcessed: false,
          message: '',
          error: action.data,
        },
      };
    case SIGN_OUT_USER_SUCCESS:
      return {
        ...state,
        userDetails: {},
        isAuth: false,
      };
    default:
      return state;
  }
};


export const signInUserAction = user => ({
  type: SIGN_IN_USER,
  data: user,
});

export const signInGoogleUserAction = user => ({
  type: SIGN_IN_GOOGLE_USER,
  data: user,
});

export const signInFacebookAction = user => ({
  type: SIGN_IN_FACEBOOK_USER,
  data: user,
});

export const signOutUserAction = () => ({
  type: SIGN_OUT_USER,
});

export default signInUserReducer;
