import React from 'react';
import { connect } from 'react-redux';

import withAuth from 'authModule/hocs/withAuth';
import AdminUpdateCausePage from 'causeModule/components/pages/AdminUpdateCausePage';
import { updateCauseAction } from 'causeModule/redux/modules/updateCauseModule';
import { getSingleCauseAdminAction } from 'causeModule/redux/modules/getSingleCauseAdminModule';


const AdminUpdateCausePageContainer = props => <AdminUpdateCausePage {...props} />;

const mapStateToProps = ({ cause: { getSingleCauseAdminReducer, updateCauseReducer } }) => ({
  getSingleCauseAdminReducer,
  updateCauseReducer,
});

const mapDispatchToProps = {
  updateCauseAction,
  getSingleCauseAdminAction,
};

export default withAuth(connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminUpdateCausePageContainer));
