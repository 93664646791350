import { put, takeEvery, call } from 'redux-saga/effects';
import {
  SIGN_OUT_USER,
  SIGN_OUT_USER_SUCCESS,
  SIGN_OUT_USER_FAIL,
} from 'authModule/redux/modules/signInUserModule';
import history from 'commonModule/helpers/history';
import { signOutUserCall, removeCookie, removeLocalStorage } from 'authModule/infrastructure/signOutUserLogic';

function* signOutUser() {
  const { response } = yield call(signOutUserCall);
  removeCookie('token');
  removeLocalStorage('user');
  if (response) {
    yield call(history.push, process.env.RAZZLE_LOG_IN_SLUG);
    yield put({ type: SIGN_OUT_USER_SUCCESS });
  } else {
    yield put({ type: SIGN_OUT_USER_FAIL });
  }
}

function* signOutUserSaga() {
  yield takeEvery(SIGN_OUT_USER, signOutUser);
}
export default signOutUserSaga;
