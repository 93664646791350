import { put, takeEvery, call } from 'redux-saga/effects';
import {
  UPDATE_TREATMENT,
  UPDATE_TREATMENT_SUCCESS,
  UPDATE_TREATMENT_FAIL,
} from 'treatmentModule/redux/modules/updateTreatmentModule';
import { updateTreatmentCall } from 'treatmentModule/infrastructure/updateTreatmentLogic';

function* updateTreatment(action) {
  const { response, error } = yield call(updateTreatmentCall, action.data, action);

  if (response) {
    yield put({ type: UPDATE_TREATMENT_SUCCESS, data: response.data });
  } else {
    yield put({ type: UPDATE_TREATMENT_FAIL, data: error });
  }
}

function* updateTreatmentSaga() {
  yield takeEvery(UPDATE_TREATMENT, updateTreatment);
}
export default updateTreatmentSaga;
