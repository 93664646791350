import 'commonModule/theme/index.scss';
import './i18n';
import React from 'react';
import { hydrate } from 'react-dom';
import { Router } from 'react-router-dom';
import { useSSR } from 'react-i18next';
import { ensureReady, After, getSerializedData } from '@jaredpalmer/after';
import routes from 'coreModule/routes';
import { Provider } from 'react-redux';
import history from 'commonModule/helpers/history';
import { initializeStore } from 'coreModule/redux/store';
import { isAuth } from 'authModule/infrastructure/signInUserLogic';
import PopupsListContainer from 'commonModule/containers/PopupsListContainer';


const authData = isAuth();

const preLoadedState = getSerializedData('preloaded_state');
const auth = {
  ...preLoadedState.auth,
  signInUserReducer: {
    ...preLoadedState.auth.signInUserReducer,
    isAuth: !!authData,
    userDetails: authData || {},
  },
};

const store = initializeStore({ ...preLoadedState, auth });

const BaseApp = ({ data }) => {
  useSSR(window.initialI18nStore, window.initialLanguage);
  return (
    <Provider store={store}>
      <PopupsListContainer />
      <Router history={history}>
        <After data={data} routes={routes} store={store} transitionBehavior="instant" />
      </Router>
    </Provider>
  );
};

function renderApp() {
  ensureReady(routes).then(data => hydrate(
    <BaseApp data={data} />,
    document.getElementById('root'),
  ));
}

renderApp();

if (module.hot) {
  module.hot.accept('coreModule/routes', renderApp);
}
