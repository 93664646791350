import { put, takeEvery, call } from 'redux-saga/effects';
import {
  GET_SINGLE_POST,
  GET_SINGLE_POST_SUCCESS,
  GET_SINGLE_POST_FAIL,
} from 'postModule/redux/modules/getSinglePostModule';
import { getSinglePostCall } from 'postModule/infrastructure/getSinglePostLogic';

function* getSinglePost(action) {
  const { response, error } = yield call(getSinglePostCall, action.data, action);

  if (response) {
    yield put({ type: GET_SINGLE_POST_SUCCESS, data: response.data });
  } else {
    yield put({ type: GET_SINGLE_POST_FAIL, data: error });
  }
}

function* getSinglePostSaga() {
  yield takeEvery(GET_SINGLE_POST, getSinglePost);
}
export default getSinglePostSaga;
