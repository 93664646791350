import { put, takeEvery, call } from 'redux-saga/effects';
import {
  ADD_STATIC_PAGE,
  ADD_STATIC_PAGE_SUCCESS,
  ADD_STATIC_PAGE_FAIL,
} from 'staticPageModule/redux/modules/addStaticPageModule';
import { addStaticPageCall } from 'staticPageModule/infrastructure/addStaticPageLogic';

function* addStaticPage(action) {
  const { response, error } = yield call(addStaticPageCall, action.data);

  if (response) {
    yield put({ type: ADD_STATIC_PAGE_SUCCESS, data: response.data });
  } else {
    yield put({ type: ADD_STATIC_PAGE_FAIL, data: error });
  }
}

function* addStaticPageSaga() {
  yield takeEvery(ADD_STATIC_PAGE, addStaticPage);
}
export default addStaticPageSaga;
