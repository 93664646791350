import React from 'react';
import Post from 'postModule/components/Post';
import { ListGroup } from 'reactstrap';
import AddSubPostContainer from 'postModule/containers/AddSubPostContainer';
import PostLevelEnum from 'postModule/infrastructure/postLevelEnum';

const PostList = ({
  data, parentId, isSubPostVisible, hideVoteButtons, showAnswersCounter,
}) => {
  const renderPost = (post, parentIdn, callback) => {
    const targetId = post.postLevel !== PostLevelEnum.Post ? parentIdn : post._id;
    const isSubPostId = post.postLevel === PostLevelEnum.Post ? false : post._id;

    if (!isSubPostVisible && post.postLevel !== PostLevelEnum.Post) {
      return null;
    }

    return (
      <Post postData={post} key={post._id} targetId={targetId} isSubPostId={isSubPostId} postLevel={post.postLevel} showAnswersCounter={showAnswersCounter} hideVoteButtons={hideVoteButtons}>
        {callback(post, targetId, callback)}
        {(post.postLevel !== PostLevelEnum.SubSubPost && isSubPostVisible) && (
          <AddSubPostContainer targetId={targetId} isSubPostId={isSubPostId} postLevel={post.postLevel} />
        )}
      </Post>
    );
  };

  const renderPostList = (data) => {
    const postsArray = data.subPosts || data;
    const parentIdn = data.postLevel === PostLevelEnum.Post ? data._id : parentId;

    if (!postsArray?.length) {
      return null;
    }

    return (
      <ListGroup>
        {postsArray.map(post => (
          renderPost(post, parentIdn, renderPostList)
        ))}
      </ListGroup>
    );
  };

  return renderPostList(data);
};

export default PostList;
