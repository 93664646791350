import { put, takeEvery, call } from 'redux-saga/effects';
import {
  ADD_TREATMENT,
  ADD_TREATMENT_SUCCESS,
  ADD_TREATMENT_FAIL,
} from 'treatmentModule/redux/modules/addTreatmentModule';
import { addTreatmentCall } from 'treatmentModule/infrastructure/addTreatmentLogic';

function* addTreatment(action) {
  const { response, error } = yield call(addTreatmentCall, action.data);

  if (response) {
    yield put({ type: ADD_TREATMENT_SUCCESS, data: response.data });
  } else {
    yield put({ type: ADD_TREATMENT_FAIL, data: error });
  }
}

function* addTreatmentSaga() {
  yield takeEvery(ADD_TREATMENT, addTreatment);
}
export default addTreatmentSaga;
