export const ADD_DISEASE = 'disease/ADD_DISEASE';
export const ADD_DISEASE_SUCCESS = 'disease/ADD_DISEASE_SUCCESS';
export const ADD_DISEASE_FAIL = 'disease/ADD_DISEASE_FAIL';

const initialState = {
  isProcessing: false,
  isProcessed: false,
  data: [],
  message: '',
  error: '',
};

const addDiseaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_DISEASE:
      return {
        ...state,
        isProcessed: false,
        isProcessing: true,
      };
    case ADD_DISEASE_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isProcessing: false,
        isProcessed: true,
        message: action.data.message,
        error: '',
      };
    }
    case ADD_DISEASE_FAIL:
      return {
        ...state,
        isProcessing: false,
        isProcessed: false,
        message: '',
        error: action.data,
      };
    default:
      return state;
  }
};

export const addDiseaseAction = diseaseData => ({
  type: ADD_DISEASE,
  data: diseaseData,
});

export default addDiseaseReducer;
