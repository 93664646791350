export const ADD_POPUP = 'common/ADD_POPUP';
export const CLOSE_POPUP = 'common/CLOSE_POPUP';
export const CLOSE_ALL_POPUPS = 'common/CLOSE_ALL_POPUPS';

const initialState = {
  popupsList: [],
};

const popupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_POPUP: {
      return {
        ...state,
        popupsList: [...state.popupsList, action.data],
      };
    }
    case CLOSE_POPUP: {
      const currentPopupId = action.data.popupFrontendId || action.data;
      return {
        ...state,
        popupsList: state.popupsList.filter(({ frontendId }) => frontendId !== currentPopupId),
      };
    }
    case CLOSE_ALL_POPUPS: {
      return {
        ...state,
        popupsList: [],
      };
    }
    default:
      return state;
  }
};

export const addPopupAction = post => ({
  type: ADD_POPUP,
  data: post,
});

export const closePopupAction = frontendId => ({
  type: CLOSE_POPUP,
  data: frontendId,
});

export const closeAllPopupsAction = () => ({
  type: CLOSE_ALL_POPUPS,
});

export default popupsReducer;
