import React from 'react';
import { connect } from 'react-redux';
import SignInForm from 'authModule/components/SignInForm';
import { signInUserAction } from 'authModule/redux/modules/signInUserModule';

const SignInFormContainer = props => (
  <SignInForm {...props} />
);

const mapStateToProps = ({ auth: { signInUserReducer } }) => ({
  ...signInUserReducer,
});

const mapDispatchToProps = {
  signInUserAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignInFormContainer);

