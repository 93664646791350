import React, { useState, useEffect } from 'react';
import Layout from 'commonModule/components/Layout';

const AdminAddCategoryPage = ({
  getCategoryListAction, getCategoryListReducer, addCategoryAction, addCategoryReducer,
}) => {
  const [values, setValues] = useState({
    newCategoryName: '',
  });
  const { newCategoryName } = values;

  useEffect(() => {
    getCategoryListAction();
  }, []);

  useEffect(() => {
    if (addCategoryReducer.isProcessed) {
      getCategoryListAction();
    }
  }, [addCategoryReducer.isProcessed]);

  const showCategories = () => {
    if (getCategoryListReducer.isProcessing || !getCategoryListReducer.isProcessed) {
      return null;
    }

    return getCategoryListReducer.data.map(category => (
      <button
        key={category._id}
        className="btn btn-outline-primary mr-1 ml-1 mt-3"
      >
        {category.name}
      </button>
    ));
  };

  const handleChange = e => {
    setValues({ ...values, newCategoryName: e.target.value });
  };

  const clickSubmit = e => {
    e.preventDefault();
    setValues({ ...values, newCategoryName: '' });
    addCategoryAction({ name: newCategoryName });
  };

  const newCategoryForm = () => (
    <form onSubmit={clickSubmit}>
      <div className="form-group">
        <label className="text-muted">Add new category name</label>
        <input type="text" className="form-control" onChange={handleChange} value={newCategoryName} required />
      </div>
      <div>
        <button type="submit" className="btn btn-primary">
                    Create
        </button>
      </div>
    </form>
  );


  return (
    <Layout>
      <h2 className="text-center pt-4 pb-4">Manage Category</h2>
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            {newCategoryForm()}
            {showCategories()}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AdminAddCategoryPage;
