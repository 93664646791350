import { put, takeEvery, call } from 'redux-saga/effects';
import {
  GET_SINGLE_PREVENTION_ADMIN,
  GET_SINGLE_PREVENTION_ADMIN_SUCCESS,
  GET_SINGLE_PREVENTION_ADMIN_FAIL,
} from 'preventionModule/redux/modules/getSinglePreventionAdminModule';
import { getSinglePreventionCall } from 'preventionModule/infrastructure/getSinglePreventionLogic';

function* getSinglePreventionAdmin(action) {
  const { response, error } = yield call(getSinglePreventionCall, action.data);

  if (response) {
    yield put({ type: GET_SINGLE_PREVENTION_ADMIN_SUCCESS, data: response.data });
  } else {
    yield put({ type: GET_SINGLE_PREVENTION_ADMIN_FAIL, data: error });
  }
}

function* getSinglePreventionAdminSaga() {
  yield takeEvery(GET_SINGLE_PREVENTION_ADMIN, getSinglePreventionAdmin);
}
export default getSinglePreventionAdminSaga;
