import { put, takeEvery, call } from 'redux-saga/effects';
import {
  ADD_SYMPTOM,
  ADD_SYMPTOM_SUCCESS,
  ADD_SYMPTOM_FAIL,
} from 'symptomModule/redux/modules/addSymptomModule';
import { addSymptomCall } from 'symptomModule/infrastructure/addSymptomLogic';

function* addSymptom(action) {
  const { response, error } = yield call(addSymptomCall, action.data);

  if (response) {
    yield put({ type: ADD_SYMPTOM_SUCCESS, data: response.data });
  } else {
    yield put({ type: ADD_SYMPTOM_FAIL, data: error });
  }
}

function* addSymptomSaga() {
  yield takeEvery(ADD_SYMPTOM, addSymptom);
}
export default addSymptomSaga;
