import { put, takeEvery, call } from 'redux-saga/effects';
import {
  GET_SINGLE_COMPLICATION,
  GET_SINGLE_COMPLICATION_SUCCESS,
  GET_SINGLE_COMPLICATION_FAIL,
} from '../modules/getSingleComplicationModule';
import { getSingleComplicationCall } from '../../infrastructure/getSingleComplicationLogic';

function* getSingleComplication(action) {
  const { response, error } = yield call(getSingleComplicationCall, action.data, action);

  if (response) {
    yield put({ type: GET_SINGLE_COMPLICATION_SUCCESS, data: response.data });
  } else {
    yield put({ type: GET_SINGLE_COMPLICATION_FAIL, data: error });
  }
}

function* getSingleComplicationSaga() {
  yield takeEvery(GET_SINGLE_COMPLICATION, getSingleComplication);
}
export default getSingleComplicationSaga;
