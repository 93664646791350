import { put, takeEvery, call } from 'redux-saga/effects';
import {
  ADMIN_GET_SINGLE_DISEASE,
  ADMIN_GET_SINGLE_DISEASE_SUCCESS,
  ADMIN_GET_SINGLE_DISEASE_FAIL,
} from '../modules/adminGetSingleDiseaseModule';
import { getSingleDiseaseCall } from '../../infrastructure/getSingleDiseaseLogic';

function* getSingleDiseaseAdmin(action) {
  const { response, error } = yield call(getSingleDiseaseCall, action.data);

  if (response) {
    yield put({ type: ADMIN_GET_SINGLE_DISEASE_SUCCESS, data: response.data });
  } else {
    yield put({ type: ADMIN_GET_SINGLE_DISEASE_FAIL, data: error });
  }
}

function* getSingleDiseaseAdminSaga() {
  yield takeEvery(ADMIN_GET_SINGLE_DISEASE, getSingleDiseaseAdmin);
}
export default getSingleDiseaseAdminSaga;
