import cookie from 'js-cookie';
import API from 'coreModule/infrastructure/api';


export const signOutUserCall = () => API.request({
  method: 'get',
  url: '/signout',
})
  .then(response => ({ response }))
  .catch(error => console.log(error));

export const removeCookie = key => {
  if (process.browser) {
    cookie.remove(key, {
      expires: 1,
    });
  }
};

export const removeLocalStorage = key => {
  if (process.browser) {
    localStorage?.removeItem(key);
  }
};
