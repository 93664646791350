export const ADD_SUB_POST = 'post/ADD_SUB_POST';
export const ADD_SUB_POST_SUCCESS = 'post/ADD_SUB_POST_SUCCESS';
export const ADD_SUB_POST_FAIL = 'post/ADD_SUB_POST_FAIL';
export const ADD_SUB_POST_RESET = 'post/ADD_SUB_POST_RESET';

const initialState = {
  isProcessing: false,
  isProcessed: false,
  message: '',
  error: '',
};

const addSubPostReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SUB_POST:
      return {
        ...state,
        isProcessing: true,
        isProcessed: false,
      };
    case ADD_SUB_POST_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        isProcessed: true,
        error: '',
      };
    }
    case ADD_SUB_POST_RESET:
    case ADD_SUB_POST_FAIL:
      return {
        ...state,
        isProcessing: false,
        isProcessed: false,
        message: '',
        error: action.data || '',
      };
    default:
      return state;
  }
};

export const addSubPostAction = subPost => ({
  type: ADD_SUB_POST,
  data: subPost,
});

export const addSubPostResetAction = () => ({
  type: ADD_SUB_POST_RESET,
});

export default addSubPostReducer;
