import React from 'react';
import { connect } from 'react-redux';

import withAuth from 'authModule/hocs/withAuth';
import AdminDiagnosisListPage from 'diagnosisModule/components/pages/AdminDiagnosisListPage';
import { getDiagnosisListAction } from 'diagnosisModule/redux/modules/getDiagnosisListModule';


const AdminDiagnosisListPageContainer = props => <AdminDiagnosisListPage {...props} />;

const mapStateToProps = ({ diagnosis: { getDiagnosisListReducer } }) => ({
  getDiagnosisListReducer,
});

const mapDispatchToProps = { getDiagnosisListAction };

export default withAuth(connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminDiagnosisListPageContainer));
