import React from 'react';
import { Nav, NavLink, NavItem } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { SLUGS } from 'coreModule/infrastructure/multiLanguageSlugs';
import './styles.scss';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      <Nav>
        <NavItem>
          <NavLink disabled>{`${process.env.RAZZLE_APP_NAME} ${new Date().getFullYear()}`}</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href={`/${SLUGS[process.env.RAZZLE_LANGUAGE_CODE].PRIVACY_POLICY_SLUG}`}>
            {t('generic.privacyPolicy')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink href={`/${SLUGS[process.env.RAZZLE_LANGUAGE_CODE].CONTACT_SLUG}`}>
            {t('generic.contact')}
          </NavLink>
        </NavItem>
      </Nav>
    </footer>
  );
};

export default Footer;
