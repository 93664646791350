import React from 'react';
import { connect } from 'react-redux';

import withAuth from 'authModule/hocs/withAuth';
import { getSymptomListAction } from 'symptomModule/redux/modules/getSymptomListModule';
import AdminSymptomListPage from '../components/pages/AdminSymptomListPage';


const AdminSymptomListPageContainer = props => <AdminSymptomListPage {...props} />;

const mapStateToProps = ({ symptom: { getSymptomListReducer } }) => ({
  getSymptomListReducer,
});

const mapDispatchToProps = { getSymptomListAction };

export default withAuth(connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminSymptomListPageContainer));
