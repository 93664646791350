import { put, takeEvery, call } from 'redux-saga/effects';
import {
  GET_SINGLE_SYMPTOM,
  GET_SINGLE_SYMPTOM_SUCCESS,
  GET_SINGLE_SYMPTOM_FAIL,
} from '../modules/getSingleSymptomModule';
import { getSingleSymptomCall } from '../../infrastructure/getSingleSymptomLogic';

function* getSingleSymptom(action) {
  const { response, error } = yield call(getSingleSymptomCall, action.data, action);

  if (response) {
    yield put({ type: GET_SINGLE_SYMPTOM_SUCCESS, data: response.data });
  } else {
    yield put({ type: GET_SINGLE_SYMPTOM_FAIL, data: error });
  }
}

function* getSingleSymptomSaga() {
  yield takeEvery(GET_SINGLE_SYMPTOM, getSingleSymptom);
}
export default getSingleSymptomSaga;
