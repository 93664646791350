import { combineReducers } from 'redux';

import addDiseaseReducer from './addDiseaseModule';
import updateDiseaseReducer from './updateDiseaseModule';
import getDiseaseListReducer from './getDiseaseListModule';
import getDiseaseSearchResultsReducer from './getDiseaseSearchResultsModule';
import getSingleDiseaseReducer from './getSingleDiseaseModule';
import adminGetSingleDiseaseReducer from './adminGetSingleDiseaseModule';


const disease = combineReducers({
  addDiseaseReducer,
  updateDiseaseReducer,
  getDiseaseListReducer,
  getSingleDiseaseReducer,
  adminGetSingleDiseaseReducer,
  getDiseaseSearchResultsReducer,
});

export default disease;
