import addCategorySaga from './addCategorySaga';
import getCategoryListSaga from './getCategoryListSaga';
import getSingleCategoryAdminSaga from './getSingleCategoryAdminSaga';
import updateCategorySaga from './updateCategorySaga';

export default [
  addCategorySaga(),
  getCategoryListSaga(),
  getSingleCategoryAdminSaga(),
  updateCategorySaga(),
];
