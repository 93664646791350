import React, { useEffect } from 'react';
import getSlug from 'commonModule/helpers/getSlug';

import './styles.scss';
import modelEnums from 'commonModule/infrastructure/enums/modelEnums';
import ModelPageContent from 'commonModule/components/ModelPageContent';


const ComplicationPage = (
  {
    getSingleComplicationReducer,
    addPostReducer, addSubPostReducer,
    getSingleComplicationAction,
    history,
    addPostResetAction,
    addSubPostResetAction,
    getModelPostListReducer,
  },
) => {
  useEffect(() => {
    if (addPostReducer.isProcessed || addSubPostReducer.isProcessed) {
      getSingleComplicationAction({ slug: getSlug(history.location.pathname) });
      return () => {
        addPostResetAction();
        addSubPostResetAction();
      };
    }
  }, []);
  const {
    _id = '', name = '', diseases = [], slug = '',
  } = getSingleComplicationReducer.data;

  return (
    <ModelPageContent slug={slug} _id={_id} name={name} diseases={diseases} posts={getModelPostListReducer.data || []} targetModel={modelEnums.Complication} />
  );
};

export default ComplicationPage;
