import { put, takeEvery, call } from 'redux-saga/effects';
import {
  GET_SINGLE_PREVENTION,
  GET_SINGLE_PREVENTION_SUCCESS,
  GET_SINGLE_PREVENTION_FAIL,
} from '../modules/getSinglePreventionModule';
import { getSinglePreventionCall } from '../../infrastructure/getSinglePreventionLogic';

function* getSinglePrevention(action) {
  const { response, error } = yield call(getSinglePreventionCall, action.data, action);

  if (response) {
    yield put({ type: GET_SINGLE_PREVENTION_SUCCESS, data: response.data });
  } else {
    yield put({ type: GET_SINGLE_PREVENTION_FAIL, data: error });
  }
}

function* getSinglePreventionSaga() {
  yield takeEvery(GET_SINGLE_PREVENTION, getSinglePrevention);
}
export default getSinglePreventionSaga;
