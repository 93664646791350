import React from 'react';
import { connect } from 'react-redux';

import withAuth from 'authModule/hocs/withAuth';
import AdminUpdateStaticPagePage from 'staticPageModule/components/pages/AdminUpdateStaticPage';
import { updateStaticPageAction } from 'staticPageModule/redux/modules/updateStaticPageModule';
import { getSingleStaticPageAdminAction } from 'staticPageModule/redux/modules/getSingleStaticPageAdminModule';


const AdminUpdateStaticPageContainer = props => <AdminUpdateStaticPagePage {...props} />;

const mapStateToProps = ({ staticPage: { getSingleStaticPageAdminReducer, updateStaticPageReducer } }) => ({
  getSingleStaticPageAdminReducer,
  updateStaticPageReducer,
});

const mapDispatchToProps = {
  updateStaticPageAction,
  getSingleStaticPageAdminAction,
};

export default withAuth(connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminUpdateStaticPageContainer));
