import { put, takeEvery, call } from 'redux-saga/effects';
import {
  GET_SYMPTOM_LIST,
  GET_SYMPTOM_LIST_SUCCESS,
  GET_SYMPTOM_LIST_FAIL,
} from 'symptomModule/redux/modules/getSymptomListModule';
import { getSymptomListCall } from 'symptomModule/infrastructure/getSymptomListLogic';

function* getSymptomList(action) {
  const { response, error } = yield call(getSymptomListCall, action?.data || {});

  if (response) {
    yield put({ type: GET_SYMPTOM_LIST_SUCCESS, data: response.data });
  } else {
    yield put({ type: GET_SYMPTOM_LIST_FAIL, data: error });
  }
}

function* getSymptomListSaga() {
  yield takeEvery(GET_SYMPTOM_LIST, getSymptomList);
}
export default getSymptomListSaga;
