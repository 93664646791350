import React, { useState } from 'react';
import VoteButtonContainer from 'voteModule/containers/VoteButtonContainer';
import VoteCounter from 'voteModule/components/VoteCounter';

const VoteButtonsWrapper = ({
  isSubPostId, targetModel, targetId, votes = [], postLevel, hideVoteButtons,
}) => {
  const initialState = {
    runningPositiveVote: 0,
    runningNegativeVote: 0,
  };
  const [values, setValues] = useState(initialState);
  if (hideVoteButtons) {
    return null;
  }
  const { runningPositiveVote, runningNegativeVote } = values;
  const positiveVotesCounter = votes.filter(vote => vote.isPositive).length + runningPositiveVote;
  const negativeVotesCounter = votes.filter(vote => !vote.isPositive).length + runningNegativeVote;

  const handleRunningValues = (e) => {
    setValues({ ...values, runningPositiveVote: e ? 1 : 0, runningNegativeVote: !e ? 1 : 0 });
  };

  const renderVoteButton = (isPositive) => {
    if (postLevel === 'SubSubPost') {
      return null;
    }

    return (
      <VoteButtonContainer
        handleRunningValues={handleRunningValues}
        {...{
          isPositive, targetModel, targetId, isSubPostId,
        }}
      />
    );
  };

  const renderVoteCounter = (isPositive) => {
    if (postLevel === 'SubSubPost') {
      return null;
    }

    if (isPositive) {
      return <VoteCounter voteCounter={positiveVotesCounter} />;
    }

    return <VoteCounter voteCounter={negativeVotesCounter} />;
  };

  return (
    <div className="d-flex flex-column p-3">
      <div className="d-flex flex-column align-items-center">
        {renderVoteCounter(true)}
        {renderVoteButton(true)}
      </div>
      <div className="d-flex flex-column align-items-center">
        {renderVoteButton(false)}
        {renderVoteCounter(false)}
      </div>
    </div>
  );
};

export default VoteButtonsWrapper;
