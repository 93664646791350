import React from 'react';
import { connect } from 'react-redux';
import withAuth from 'authModule/hocs/withAuth';
import AdminAddPreventionPage from 'preventionModule/components/pages/AdminAddPreventionPage';
import { getPreventionListAction } from 'preventionModule/redux/modules/getPreventionListModule';
import { addPreventionAction } from 'preventionModule/redux/modules/addPreventionModule';


const AdminAddPreventionPageContainer = props => <AdminAddPreventionPage {...props} />;

const mapStateToProps = ({ prevention: { getPreventionListReducer, addPreventionReducer } }) => ({
  getPreventionListReducer,
  addPreventionReducer,
});

const mapDispatchToProps = {
  getPreventionListAction,
  addPreventionAction,
};

export default withAuth(connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminAddPreventionPageContainer));
