export const ADD_POST = 'post/ADD_POST ';
export const ADD_POST_SUCCESS = 'post/ADD_POST_SUCCESS ';
export const ADD_POST_FAIL = 'post/ADD_POST_FAIL';
export const ADD_POST_RESET = 'post/ADD_POST_RESET';

const initialState = {
  isProcessing: false,
  isProcessed: false,
  message: '',
  error: '',
};

const addPostReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_POST:
      return {
        ...state,
        isProcessing: true,
        isProcessed: false,
      };
    case ADD_POST_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        isProcessed: true,
        message: action.data,
        error: '',

      };
    }
    case ADD_POST_FAIL:
    case ADD_POST_RESET:
      return {
        ...state,
        isProcessing: false,
        isProcessed: false,
        message: '',
        error: action.data || '',
      };
    default:
      return state;
  }
};

export const addPostAction = (post, isFormData) => ({
  type: ADD_POST,
  data: post,
  isFormData,
});

export const addPostResetAction = () => ({
  type: ADD_POST_RESET,
});

export default addPostReducer;
