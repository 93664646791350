import addStaticPageSaga from './addStaticPageSaga';
import getStaticPageListSaga from './getStaticPageListSaga';
import getSingleStaticPageAdminSaga from './getSingleStaticPageAdminSaga';
import updateStaticPageSaga from './updateStaticPageSaga';
import getSingleStaticPageSaga from './getSingleStaticPageSaga';

export default [
  addStaticPageSaga(),
  getStaticPageListSaga(),
  getSingleStaticPageAdminSaga(),
  updateStaticPageSaga(),
  getSingleStaticPageSaga(),
];
