import {
  put,
  takeEvery,
  call,
  fork,
  select,
} from 'redux-saga/effects';
import {
  ADD_SUB_POST,
  ADD_SUB_POST_SUCCESS,
  ADD_SUB_POST_FAIL,
} from 'postModule/redux/modules/addSubPostModule';
import { addSubPostCall } from 'postModule/infrastructure/addSubPostLogic';
import loginRequiredPopupFork from 'authModule/redux/sagas/loginRequiredPopupFork';

function* addSubPost(action) {
  const { isAuth } = yield select(({ auth }) => auth.signInUserReducer);
  if (!isAuth) {
    yield fork(loginRequiredPopupFork, action);
    return;
  }

  const { response, error } = yield call(addSubPostCall, action.data);

  if (response) {
    yield put({ type: ADD_SUB_POST_SUCCESS, data: response.data });
  } else {
    yield put({ type: ADD_SUB_POST_FAIL, data: error });
  }
}

function* addSubPostSaga() {
  yield takeEvery(ADD_SUB_POST, addSubPost);
}
export default addSubPostSaga;
