import React from 'react';
import { connect } from 'react-redux';
import withAuth from 'authModule/hocs/withAuth';
import AdminAddCausePage from 'causeModule/components/pages/AdminAddCausePage';
import { getCauseListAction } from 'causeModule/redux/modules/getCauseListModule';
import { addCauseAction } from 'causeModule/redux/modules/addCauseModule';


const AdminAddCausePageContainer = props => <AdminAddCausePage {...props} />;

const mapStateToProps = ({ cause: { getCauseListReducer, addCauseReducer } }) => ({
  getCauseListReducer,
  addCauseReducer,
});

const mapDispatchToProps = {
  getCauseListAction,
  addCauseAction,
};

export default withAuth(connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminAddCausePageContainer));
