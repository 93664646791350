import React from 'react';
import { connect } from 'react-redux';

import withAuth from 'authModule/hocs/withAuth';
import AdminUpdateDiagnosisPage from 'diagnosisModule/components/pages/AdminUpdateDiagnosisPage';
import { updateDiagnosisAction } from 'diagnosisModule/redux/modules/updateDiagnosisModule';
import { getSingleDiagnosisAdminAction } from 'diagnosisModule/redux/modules/getSingleDiagnosisAdminModule';


const AdminUpdateDiagnosisPageContainer = props => <AdminUpdateDiagnosisPage {...props} />;

const mapStateToProps = ({ diagnosis: { getSingleDiagnosisAdminReducer, updateDiagnosisReducer } }) => ({
  getSingleDiagnosisAdminReducer,
  updateDiagnosisReducer,
});

const mapDispatchToProps = {
  updateDiagnosisAction,
  getSingleDiagnosisAdminAction,
};

export default withAuth(connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminUpdateDiagnosisPageContainer));
