import { put, takeEvery, call } from 'redux-saga/effects';
import {
  GET_DISEASE_LIST,
  GET_DISEASE_LIST_SUCCESS,
  GET_DISEASE_LIST_FAIL,
} from '../modules/getDiseaseListModule';
import { getDiseaseListCall } from '../../infrastructure/getDiseaseListLogic';

function* getDiseaseList(action) {
  const { response, error } = yield call(getDiseaseListCall, action?.data || {});

  if (response) {
    yield put({ type: GET_DISEASE_LIST_SUCCESS, data: response.data });
  } else {
    yield put({ type: GET_DISEASE_LIST_FAIL, data: error });
  }
}

function* getDiseaseListSaga() {
  yield takeEvery(GET_DISEASE_LIST, getDiseaseList);
}
export default getDiseaseListSaga;
