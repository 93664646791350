import addPreventionSaga from 'preventionModule/redux/sagas/addPreventionSaga';
import getPreventionListSaga from 'preventionModule/redux/sagas/getPreventionListSaga';
import getSinglePreventionAdminSaga from 'preventionModule/redux/sagas/getSinglePreventionAdminSaga';
import updatePreventionSaga from 'preventionModule/redux/sagas/updatePreventionSaga';
import getSinglePreventionSaga from 'preventionModule/redux/sagas/getSinglePreventionSaga';

export default [
  addPreventionSaga(),
  getPreventionListSaga(),
  getSinglePreventionAdminSaga(),
  updatePreventionSaga(),
  getSinglePreventionSaga(),
];
