import React, { useEffect, useState } from 'react';
import Select from 'commonModule/components/Select';
import { isEmpty } from 'lodash';
import Layout from 'commonModule/components/Layout';
import getSlug from 'commonModule/helpers/getSlug';
import modelEnums from 'commonModule/infrastructure/enums/modelEnums';
import createUrl from 'commonModule/helpers/createUrl';

const AdminUpdateDiseasePage = (
  {
    getCategoryListAction,
    getSymptomListAction,
    getCategoryListReducer,
    getSymptomListReducer,
    updateDiseaseAction,
    getSingleDiseaseAdminAction,
    history,
    adminGetSingleDiseaseReducer,
    updateDiseaseReducer,
  },
) => {
  const initialState = {
    categories: [],
    symptoms: [],
    formData: new FormData(),
    processedLanguageCode: '',
    disease: {},
    slug: getSlug(history.location.pathname),
  };
  const [values, setValues] = useState(initialState);
  const {
    formData, disease, slug, processedLanguageCode,
  } = values;

  useEffect(() => {
    setValues({ ...values, slug: getSlug(history.location.pathname) });
    getSingleDiseaseAdminAction({ slug: getSlug(history.location.pathname), shouldGetFullDiseaseData: true });
    getCategoryListAction();
    getSymptomListAction();
  }, [updateDiseaseReducer.isProcessed]);

  useEffect(() => {
    if (adminGetSingleDiseaseReducer.isProcessed || updateDiseaseReducer.isProcessed) {
      setValues({ ...values, disease: adminGetSingleDiseaseReducer.data });
    }
  }, [adminGetSingleDiseaseReducer.isProcessed, updateDiseaseReducer.isProcessed]);

  if (!adminGetSingleDiseaseReducer.isProcessed || !getSymptomListReducer.isProcessed || !getCategoryListReducer.isProcessed || isEmpty(disease)) {
    return null;
  }

  const handleChange = (name, value, languageCode) => {
    if (languageCode) {
      const isExistingLanguageData = disease.language.find(diseaseLanguageData => diseaseLanguageData.languageCode === languageCode);
      if (!isExistingLanguageData) {
        const newLanguageData = {
          languageCode,
          [name]: value,
        };
        return setValues({ ...values, disease: { ...disease, language: [...disease.language, newLanguageData] } });
      }

      const diseaseLanguages = disease.language.map(languageData => {
        if (languageData.languageCode === languageCode) {
          return {
            ...languageData,
            [name]: value,
          };
        }

        return languageData;
      });
      return setValues({ ...values, disease: { ...disease, language: diseaseLanguages } });
    }

    handleSelectChange(name, value);
  };

  const handleSelectChange = (name, value) => {
    let formDataValue = value;
    if (Array.isArray(value)) {
      formDataValue = value.map(option => option._id);
    }
    formData.set(name, formDataValue);
    disease[name] = value;
    setValues({ ...values, disease, formData });
  };

  const handleSubmit = (e, languageCode) => {
    e.preventDefault();
    const diseaseLanguageDataToUpdate = disease.language.find(diseaseLanguageData => diseaseLanguageData.languageCode === languageCode);
    Object.keys(diseaseLanguageDataToUpdate).forEach(key => {
      formData.set([key], diseaseLanguageDataToUpdate[key]);
    });
    setValues({ ...values, processedLanguageCode: languageCode });
    updateDiseaseAction({ slug, updatedDiseaseData: formData });
  };

  const showError = () => (updateDiseaseReducer.error ? <div className="alert alert-danger">{updateDiseaseReducer.error}</div> : '');

  const renderFormForEachLanguage = () => {
    const supportedLanguagesArray = process.env.RAZZLE_SUPPORTED_LANGUAGES.split(',');
    return supportedLanguagesArray.map(languageCode => renderNewForm(disease?.language
      ?.find(disease => disease.languageCode === languageCode), languageCode));
  };

  const renderNewForm = (diseaseData = {}, languageCode) => (
    <>
      <a
        target="_blank"
        rel="noreferrer"
        href={createUrl(
          modelEnums.Disease,
          false,
          diseaseData.slug,
          languageCode !== 'en' && languageCode,
        )}
      >
        {diseaseData.name}
      </a>
      <form onSubmit={e => handleSubmit(e, languageCode)} key={languageCode}>
        <div className="form-group">
          <label className="text-muted">Language code</label>
          <input
            type="text"
            disabled
            className="form-control"
            value={languageCode}
            required
          />
        </div>
        <div className="form-group">
          <label className="text-muted">{`${languageCode} Disease name`}</label>
          <input
            type="text"
            className="form-control"
            onChange={e => handleChange('name', e.target.value, languageCode)}
            value={diseaseData.name}
            required
          />
        </div>
        <div className="form-group">
          <label className="text-muted">{`${languageCode} Disease synonyms`}</label>
          <input
            type="text"
            className="form-control"
            onChange={e => handleChange('synonyms', e.target.value, languageCode)}
            value={diseaseData.synonyms}
          />
        </div>
        <div className="form-group">
          <label className="text-muted">{`${languageCode} Disease description`}</label>
          <textarea
            className="form-control"
            onChange={e => handleChange('description', e.target.value, languageCode)}
            value={diseaseData.description}
          />
        </div>
        {diseaseData.languageCode === 'en' && (
          <>
            <div className="form-group">
              <label className="text-muted">Disease categories</label>
              <Select
                value={disease.categories}
                options={getCategoryListReducer.data}
                getOptionLabel={option => option.name}
                getOptionValue={option => option._id}
                isMulti
                onChange={options => handleChange('categories', options)}
              />
            </div>
            <div className="form-group">
              <label className="text-muted">Disease symptoms</label>
              <Select
                value={disease.symptoms}
                options={getSymptomListReducer.data}
                getOptionLabel={option => option.name}
                getOptionValue={option => option._id}
                isMulti
                onChange={options => handleChange('symptoms', options)}
              />
            </div>
          </>
        )}
        {(updateDiseaseReducer.isProcessed && processedLanguageCode === languageCode) && <p className="text-success">Disease is updated</p>}
        <div>
          <button type="submit" className="btn btn-primary">
						Update
          </button>
        </div>
      </form>
      <hr />
    </>
  );

  return (
    <Layout>
      <h2 className="text-center pt-4 pb-4">Update disease</h2>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            {showError()}
            {renderFormForEachLanguage()}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AdminUpdateDiseasePage;
