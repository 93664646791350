import { put, takeEvery, call } from 'redux-saga/effects';
import {
  GET_DIAGNOSIS_LIST,
  GET_DIAGNOSIS_LIST_SUCCESS,
  GET_DIAGNOSIS_LIST_FAIL,
} from 'diagnosisModule/redux/modules/getDiagnosisListModule';
import { getDiagnosisListCall } from 'diagnosisModule/infrastructure/getDiagnosisListLogic';

function* getDiagnosisList(action) {
  const { response, error } = yield call(getDiagnosisListCall, action?.data || {});

  if (response) {
    yield put({ type: GET_DIAGNOSIS_LIST_SUCCESS, data: response.data });
  } else {
    yield put({ type: GET_DIAGNOSIS_LIST_FAIL, data: error });
  }
}

function* getDiagnosisListSaga() {
  yield takeEvery(GET_DIAGNOSIS_LIST, getDiagnosisList);
}
export default getDiagnosisListSaga;
