import React from 'react';
import { connect } from 'react-redux';

import withAuth from 'authModule/hocs/withAuth';
import AdminTreatmentListPage from 'treatmentModule/components/pages/AdminTreatmentListPage';
import { getTreatmentListAction } from 'treatmentModule/redux/modules/getTreatmentListModule';


const AdminTreatmentListPageContainer = props => <AdminTreatmentListPage {...props} />;

const mapStateToProps = ({ treatment: { getTreatmentListReducer } }) => ({
  getTreatmentListReducer,
});

const mapDispatchToProps = { getTreatmentListAction };

export default withAuth(connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminTreatmentListPageContainer));
