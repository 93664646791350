import classNames from 'classnames';

// https://gist.github.com/penguinboy/762197#gistcomment-1919044
const flattenObject = ob => Object.keys(ob).reduce((toReturn, k) => {
  const newReturn = { ...toReturn };
  if (Object.prototype.toString.call(ob[k]) === '[object Date]') {
    newReturn[k] = ob[k].toString();
  } else if ((typeof ob[k]) === 'object' && ob[k]) {
    newReturn[k] = true;
    const flatObject = flattenObject(ob[k]);
    Object.keys(flatObject).forEach((k2) => {
      newReturn[`${k}${k2}`] = flatObject[k2];
    });
  } else {
    newReturn[k] = ob[k];
  }
  return newReturn;
}, {});
/*
* example:
    {
        'lb-message-content': {
            '--system': isSystemMessage,
        }
    }
    is flattened to:
    {
        'lb-message-content': true,
        'lb-message-content--system': isSystemMessage
    }
* */

const deepClassNames = (data) => {
  if (typeof data !== 'object' || Array.isArray(data)) {
    return classNames(data);
  }
  const flatObject = flattenObject(data);
  return classNames(flatObject);
};

export default deepClassNames;
