import React, { useState } from 'react';
import Layout from 'commonModule/components/Layout';

const AdminAddStaticPage = ({ addStaticPageAction }) => {
  const [values, setValues] = useState({
    newTitle: '',
    newContent: '',
  });
  const { newTitle, newContent } = values;
  const handleChange = (name, event) => {
    const value = event?.target?.value || event?.target?.innerHTML;
    setValues({ ...values, [name]: value });
  };

  const clickSubmit = e => {
    e.preventDefault();
    setValues({ ...values, newTitle: '', newContent: '' });
    addStaticPageAction({ title: newTitle, content: newContent });
  };

  const newPageForm = () => (
    <form onSubmit={clickSubmit}>
      <div className="form-group">
        <label className="text-muted">Add new single page title</label>
        <input type="text" className="form-control" onChange={e => handleChange('newTitle', e)} value={newTitle} required />
      </div>
      <div className="form-group">
        <label className="text-muted">Add new single page content</label>
        <textarea
          className="form-control"
          onChange={e => handleChange('newContent', e)}
          value={newContent}
          required
        />
      </div>
      <div>
        <button type="submit" className="btn btn-primary">
					Create
        </button>
      </div>
    </form>
  );

  return (
    <Layout>
      <h2 className="text-center pt-4 pb-4">Create Single Pages</h2>
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            {newPageForm()}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AdminAddStaticPage;
