import React, { useEffect, useState } from 'react';
import Select from 'commonModule/components/Select';
import { useForm } from 'react-hook-form';
import deepClassNames from 'commonModule/helpers/deepClassNames';
import Layout from 'commonModule/components/Layout';
import { useTranslation } from 'react-i18next';
import {
  Button, Form, Label, Input, Jumbotron,
} from 'reactstrap';

import './styles.scss';

const AddPostPage = (
  {
    getCategoryListAction,
    getSymptomListAction,
    getCategoryListReducer,
    getComplicationListReducer,
    getSymptomListReducer,
    addPostReducer,
    addPostAction,
    getDiseaseListAction,
    getDiseaseListReducer,
    getComplicationListAction,
    getTreatmentListAction,
    getCauseListAction,
    getDiagnosisListAction,
    getPreventionListAction,
    getTreatmentListReducer,
    getCauseListReducer,
    getDiagnosisListReducer,
    getPreventionListReducer,
  },
) => {
  const { t } = useTranslation();
  const initialState = {
    title: '',
    content: '',
    categories: [],
    diseases: [],
    symptoms: [],
    complications: [],
    preventions: [],
    diagnoses: [],
    causes: [],
    treatments: [],
    formData: '',
    languageCode: process.env.RAZZLE_LANGUAGE_CODE,
    isAtLeastOnePropertySelected: undefined,
    errors: [],
  };
  const [values, setValues] = useState(initialState);
  const {
    register, handleSubmit, errors, setValue,
  } = useForm();
  const {
    title, content, diseases, categories, complications, symptoms, formData, languageCode, preventions, diagnoses, causes, treatments, isAtLeastOnePropertySelected,
  } = values;
  useEffect(() => {
    setValues({ ...values, formData: new FormData() });
    getCategoryListAction();
    getSymptomListAction();
    getDiseaseListAction();
    getComplicationListAction();
    getTreatmentListAction();
    getCauseListAction();
    getDiagnosisListAction();
    getPreventionListAction();
  }, []);

  useEffect(() => {
    if (addPostReducer.isProcessed) {
      setValues({ ...initialState, formData: new FormData() });
    }
  }, [addPostReducer.isProcessed]);
  useEffect(() => {
    if (typeof isAtLeastOnePropertySelected !== 'undefined') {
      setValues({ ...values, isAtLeastOnePropertySelected: checkIfAnyPropertySelected() });
    }
  }, [diseases, categories, complications, symptoms, preventions, diagnoses, causes, treatments]);

  const selectWrapperClasses = deepClassNames({
    'add-post-page__select-wrapper': {
      '--err': (typeof isAtLeastOnePropertySelected !== 'undefined' && !isAtLeastOnePropertySelected),
    },
  });

  const checkIfAnyPropertySelected = () => {
    if (!categories.length && !diseases.length && !complications.length
			&& !symptoms.length && !preventions.length && !diagnoses.length
			&& !causes.length && !treatments.length) {
      return false;
    }
    return true;
  };

  const handleChange = (name, value) => {
    let formDataValue = value;
    if (Array.isArray(value)) {
      formDataValue = value.map(option => option._id);
    }
    formData.set(name, formDataValue);
    setValues({ ...values, [name]: value, formData });
    setValue(name, value, { shouldValidate: true });
  };

  const handleOnSubmit = e => {
    if (!checkIfAnyPropertySelected()) {
      setValues({ ...values, isAtLeastOnePropertySelected: false });
      return null;
    }
    setValues({ ...values, isAtLeastOnePropertySelected: true });
    formData.set('languageCode', languageCode);
    addPostAction(formData, true);
  };

  const showError = () => {
    const isError = (typeof isAtLeastOnePropertySelected !== 'undefined' && !isAtLeastOnePropertySelected) || addPostReducer.error;
    if (!isError) {
      return null;
    }

    return <div className="alert alert-danger">{addPostReducer.error || t('addQuestionPage.atLeastOnePropertyRequired')}</div>;
  };

  const renderForm = () => (
    <Form onSubmit={handleSubmit(handleOnSubmit)}>
      <div className="form-group d-none">
        <Input
          name="lang"
          type="text"
          disabled
          className="form-control"
          value={languageCode}
          required
        />
      </div>
      <div className="form-group">
        <Label className="text-muted">{t('addQuestionPage.titleField')}</Label>
        {errors.title?.type === 'required' && <div className="alert alert-danger">{t('generic.thisFieldIsRequired')}</div>}
        <input
          type="text"
          className="form-control"
          name="title"
          onChange={e => handleChange('title', e.target.value)}
          value={title}
          ref={register({ required: true })}
        />

        <Label className="text-muted mt-3">{t('addQuestionPage.contentField')}</Label>
        {errors.content?.type === 'required' && <div className="alert alert-danger">{t('generic.thisFieldIsRequired')}</div>}
        <textarea
          className="form-control"
          onChange={e => handleChange('content', e.target.value)}
          value={content}
          name="content"
          ref={register({ required: true })}
        />
      </div>
      <div className={selectWrapperClasses}>
        <Label className="text-muted mt-2">{t('generic.diseases')}</Label>
        <Select
          value={diseases}
          options={getDiseaseListReducer.data}
          getOptionLabel={option => option.name}
          getOptionValue={option => option._id}
          isMulti
          onChange={options => handleChange('diseases', options || [])}
        />
        <Label className="text-muted mt-3">{t('generic.categories')}</Label>
        <Select
          value={categories}
          options={getCategoryListReducer.data}
          getOptionLabel={option => option.name}
          getOptionValue={option => option._id}
          isMulti
          onChange={options => handleChange('categories', options || [])}
        />
        <Label className="text-muted mt-3">{t('generic.complications')}</Label>
        <Select
          value={complications}
          options={getComplicationListReducer.data}
          getOptionLabel={option => option.name}
          getOptionValue={option => option._id}
          isMulti
          onChange={options => handleChange('complications', options || [])}
        />

        <Label className="text-muted mt-3">{t('generic.treatments')}</Label>
        <Select
          value={treatments}
          options={getTreatmentListReducer.data}
          getOptionLabel={option => option.name}
          getOptionValue={option => option._id}
          isMulti
          onChange={options => handleChange('treatments', options || [])}
        />
        <Label className="text-muted mt-3">{t('generic.preventions')}</Label>
        <Select
          value={preventions}
          options={getPreventionListReducer.data}
          getOptionLabel={option => option.name}
          getOptionValue={option => option._id}
          isMulti
          onChange={options => handleChange('preventions', options || [])}
        />
        <Label className="text-muted mt-3">{t('generic.causes')}</Label>
        <Select
          value={causes}
          options={getCauseListReducer.data}
          getOptionLabel={option => option.name}
          getOptionValue={option => option._id}
          isMulti
          onChange={options => handleChange('causes', options || [])}
        />
        <Label className="text-muted mt-3">{t('generic.diagnoses')}</Label>
        <Select
          value={diagnoses}
          options={getDiagnosisListReducer.data}
          getOptionLabel={option => option.name}
          getOptionValue={option => option._id}
          isMulti
          onChange={options => handleChange('diagnoses', options || [])}
        />
        <Label className="text-muted mt-3">{t('generic.symptoms')}</Label>
        {/*	<Controller
					render={() => <Select />}
					name="symptoms"
					control={control}
					rules={{ required: true }}
					defaultValue=""
					/> */}
        <Select
          value={symptoms}
          options={getSymptomListReducer.data}
          getOptionLabel={option => option.name}
          getOptionValue={option => option._id}
          isMulti
          onChange={options => handleChange('symptoms', options || [])}
        />
      </div>
      <div>
        {showError()}
        <Button type="submit" color="primary" block>
          {t('generic.add')}
        </Button>
      </div>
    </Form>
  );

  return (
    <Layout>
      <Jumbotron>
        <div className="container">
          <div className="row">
            <div className="col-8 offset-2">
              <h1 className="pt-4 pb-4 display-4 text-center">{t('addQuestionPage.title')}</h1>
            </div>
          </div>
        </div>
      </Jumbotron>
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            {renderForm()}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddPostPage;
