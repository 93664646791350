import { put, takeEvery, call } from 'redux-saga/effects';
import {
  GET_COMPLICATION_LIST,
  GET_COMPLICATION_LIST_SUCCESS,
  GET_COMPLICATION_LIST_FAIL,
} from 'complicationModule/redux/modules/getComplicationListModule';
import { getComplicationListCall } from 'complicationModule/infrastructure/getComplicationListLogic';

function* getComplicationList(action) {
  const { response, error } = yield call(getComplicationListCall, action?.data || {});

  if (response) {
    yield put({ type: GET_COMPLICATION_LIST_SUCCESS, data: response.data });
  } else {
    yield put({ type: GET_COMPLICATION_LIST_FAIL, data: error });
  }
}

function* getComplicationListSaga() {
  yield takeEvery(GET_COMPLICATION_LIST, getComplicationList);
}
export default getComplicationListSaga;
