export const GET_MODEL_POST_LIST = 'post/GET_MODEL_POST_LIST';
export const GET_MODEL_POST_LIST_SUCCESS = 'post/GET_MODEL_POST_LIST_SUCCESS';
export const GET_MODEL_POST_LIST_FAIL = 'post/GET_MODEL_POST_LIST_FAIL';

const initialState = {
  isProcessing: false,
  isProcessed: false,
  data: [],
  message: '',
  error: '',
};

const getModelPostListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MODEL_POST_LIST: {
      return {
        ...state,
        ...initialState,
        isProcessing: true,
        isProcessed: false,
      };
    }
    case GET_MODEL_POST_LIST_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        isProcessed: true,
        data: action.data,
      };
    }
    default:
      return state;
  }
};

export const getModelPostListAction = (data) => ({
  type: GET_MODEL_POST_LIST,
  data,
});

export default getModelPostListReducer;
