import React from 'react';
import {
  put, takeEvery, call, select, fork,
} from 'redux-saga/effects';
import {
  ADD_VOTE,
  ADD_VOTE_SUCCESS,
  ADD_VOTE_FAIL,
} from 'voteModule/redux/modules/addVoteModule';
import { addVoteCall } from 'voteModule/infrastructure/addVoteLogic';
import loginRequiredPopupFork from 'authModule/redux/sagas/loginRequiredPopupFork';


function* addVote(action) {
  const { isAuth } = yield select(state => state.auth.signInUserReducer);
  if (!isAuth) {
    yield fork(loginRequiredPopupFork, action);
    return;
  }

  const { response, error } = yield call(addVoteCall, action.data);
  if (response) {
    yield put({ type: ADD_VOTE_SUCCESS, data: response.data });
  } else {
    yield put({ type: ADD_VOTE_FAIL, data: error });
  }
}

function* addVoteSaga() {
  yield takeEvery(ADD_VOTE, addVote);
}
export default addVoteSaga;
