import React, { useState, useEffect } from 'react';
import Layout from 'commonModule/components/Layout';

const AdminAddComplicationPage = ({
  getComplicationListAction, getComplicationListReducer, addComplicationAction, addComplicationReducer,
}) => {
  const [values, setValues] = useState({
    newComplicationName: '',
  });
  const { newComplicationName } = values;

  useEffect(() => {
    getComplicationListAction();
  }, []);

  useEffect(() => {
    if (addComplicationReducer.isProcessed) {
      getComplicationListAction();
    }
  }, [addComplicationReducer.isProcessed]);

  const showComplications = () => {
    if (getComplicationListReducer.isProcessing || !getComplicationListReducer.isProcessed) {
      return null;
    }

    return getComplicationListReducer.data.map(complication => (
      <button
        title="Double click to delete"
        key={complication._id}
        className="btn btn-outline-primary mr-1 ml-1 mt-3"
      >
        {complication.name}
      </button>
    ));
  };

  const handleChange = e => {
    setValues({ ...values, newComplicationName: e.target.value });
  };

  const clickSubmit = e => {
    e.preventDefault();
    setValues({ ...values, newComplicationName: '' });
    addComplicationAction({ name: newComplicationName });
  };

  const newComplicationForm = () => (
    <form onSubmit={clickSubmit}>
      <div className="form-group">
        <label className="text-muted">Add new complication name</label>
        <input type="text" className="form-control" onChange={handleChange} value={newComplicationName} required />
      </div>
      <div>
        <button type="submit" className="btn btn-primary">
                    Create
        </button>
      </div>
    </form>
  );

  return (
    <Layout>
      <h2 className="text-center pt-4 pb-4">Manage Complication</h2>
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            {newComplicationForm()}
            {showComplications()}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AdminAddComplicationPage;
