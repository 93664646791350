import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Jumbotron } from 'reactstrap';
import Layout from 'commonModule/components/Layout';
import getSlug from 'commonModule/helpers/getSlug';
import AddSubPostContainer from 'postModule/containers/AddSubPostContainer';
import PostList from 'postModule/components/PostList';
import VoteButtonsWrapper from 'voteModule/components/VoteButtonsWrapper';
import modelEnums from 'commonModule/infrastructure/enums/modelEnums';
import PostLevelEnum from 'postModule/infrastructure/postLevelEnum';
import BadgeList from 'commonModule/components/BadgeList';
import { SLUGS } from 'coreModule/infrastructure/multiLanguageSlugs';
import { useTranslation } from 'react-i18next';
import './styles/styles.scss';
import Avatar from 'commonModule/components/Avatar/Avatar';

const PostPage = (
  {
    addPostReducer,
    addSubPostReducer,
    getSinglePostAction,
    history,
    addSubPostResetAction,
    getSinglePostReducer,
  },
) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (addPostReducer.isProcessed || addSubPostReducer.isProcessed) {
      getSinglePostAction({ slug: getSlug(history.location.pathname, SLUGS[process.env.RAZZLE_LANGUAGE_CODE][modelEnums.Post]) });
      return () => {
        addSubPostResetAction();
      };
    }
  }, [addSubPostReducer.isProcessed]);

  const {
    _id = '', title = '', content = '', subPosts = [], votes, relatedModels = [], postedBy,
  } = getSinglePostReducer.data;
  const mTitle = () => {
    const renderPropertiesCounter = () => {
      if (relatedModels?.length < 2) {
        return '';
      }

      return `and ${relatedModels.length - 1} other related properties`;
    };
    return ``;
  };

  const renderAvatar = (authorInitials) => {
    if (!authorInitials) {
      return null;
    }

    return <Avatar text={authorInitials} className="mr-2" />;
  };

  return (
    <>
      <Helmet>
        <title>{mTitle()}</title>
        <meta name="description" content={content} />
      </Helmet>
      <Layout>
        <Jumbotron>
          <div className="container">
            <div className="row">
              <div className="col-2">
                <VoteButtonsWrapper postLevel={PostLevelEnum.Post} targetModel={modelEnums.Post} targetId={_id} votes={votes} />
              </div>
              <div className="col-10">
                <h1 className="pt-4 pb-4 display-4">{title}</h1>
              </div>
            </div>
            <div className="row">
              <div className="offset-2 col-10">
                <p>{content}</p>
                <BadgeList data={relatedModels} />
                <span className="post-page__user-name align-items-center d-flex">
                  {renderAvatar(postedBy?.initials)}
                  {' '}
                  {postedBy?.username}
                </span>
              </div>
            </div>
          </div>
        </Jumbotron>
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2 mt-5">
              <div className="featured mb-4">
                <h2>
                  {t('postPage.replies')}
:
                </h2>
                <p>{t('generic.answerIncentive')}</p>
                <AddSubPostContainer targetId={_id} postLevel={modelEnums.Post} />
              </div>
              <PostList data={subPosts} parentId={_id} isSubPostVisible />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default PostPage;
