export const ADD_PREVENTION = 'prevention/ADD_PREVENTION';
export const ADD_PREVENTION_SUCCESS = 'prevention/ADD_PREVENTION_SUCCESS';
export const ADD_PREVENTION_FAIL = 'prevention/ADD_PREVENTION_FAIL';


const initialState = {
  isProcessing: false,
  isProcessed: false,
  data: null,
  message: '',
  error: '',
};

const addPreventionReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PREVENTION:
      return {
        ...state,
        isProcessed: false,
        isProcessing: true,
      };
    case ADD_PREVENTION_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isProcessing: false,
        isProcessed: true,
        message: action.data.message,
        error: '',
      };
    }
    case ADD_PREVENTION_FAIL:
      return {
        ...state,
        isProcessing: false,
        isProcessed: false,
        message: '',
        error: action.data,
      };
    default:
      return state;
  }
};

export const addPreventionAction = preventionName => ({
  type: ADD_PREVENTION,
  data: preventionName,
});


export default addPreventionReducer;
