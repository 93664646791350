export const UPDATE_TREATMENT = 'treatment/UPDATE_TREATMENT';
export const UPDATE_TREATMENT_SUCCESS = 'treatment/UPDATE_TREATMENT_SUCCESS';
export const UPDATE_TREATMENT_FAIL = 'treatment/UPDATE_TREATMENT_FAIL';


const initialState = {
  isProcessing: false,
  isProcessed: false,
  message: '',
  error: '',
};

const updateTreatmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_TREATMENT:
      return {
        ...state,
        isProcessed: false,
        isProcessing: true,
      };
    case UPDATE_TREATMENT_SUCCESS: {
      return {
        ...state,
        isProcessed: true,
        isProcessing: false,
      };
    }
    case UPDATE_TREATMENT_FAIL:
      return {
        ...state,
        isProcessing: false,
        isProcessed: false,
        message: '',
        error: action.data,
      };
    default:
      return state;
  }
};

export const updateTreatmentAction = data => ({
  type: UPDATE_TREATMENT,
  data,
});


export default updateTreatmentReducer;
