import { all } from 'redux-saga/effects';
import postSagas from 'postModule/redux/sagas';
import diseaseSagas from 'diseaseModule/redux/sagas';
import authSagas from 'authModule/redux/sagas';
import categorySagas from 'categoryModule/redux/sagas';
import symptomSagas from 'symptomModule/redux/sagas';
import voteSagas from 'voteModule/redux/sagas';
import userSagas from 'userModule/redux/sagas';
import complicationSagas from 'complicationModule/redux/sagas';
import diagnosisSagas from 'diagnosisModule/redux/sagas';
import causeSagas from 'causeModule/redux/sagas';
import treatmentSagas from 'treatmentModule/redux/sagas';
import preventionSagas from 'preventionModule/redux/sagas';
import staticPageSagas from 'staticPageModule/redux/sagas';

export default function* rootSaga() {
  yield all([
    ...authSagas,
    ...categorySagas,
    ...symptomSagas,
    ...diseaseSagas,
    ...postSagas,
    ...voteSagas,
    ...userSagas,
    ...complicationSagas,
    ...diagnosisSagas,
    ...causeSagas,
    ...treatmentSagas,
    ...preventionSagas,
    ...staticPageSagas,
  ]);
}
