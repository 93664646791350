import { put, takeEvery, call } from 'redux-saga/effects';
import {
  GET_SINGLE_COMPLICATION_ADMIN,
  GET_SINGLE_COMPLICATION_ADMIN_SUCCESS,
  GET_SINGLE_COMPLICATION_ADMIN_FAIL,
} from 'complicationModule/redux/modules/getSingleComplicationAdminModule';
import { getSingleComplicationCall } from 'complicationModule/infrastructure/getSingleComplicationLogic';

function* getSingleComplicationAdmin(action) {
  const { response, error } = yield call(getSingleComplicationCall, action.data);

  if (response) {
    yield put({ type: GET_SINGLE_COMPLICATION_ADMIN_SUCCESS, data: response.data });
  } else {
    yield put({ type: GET_SINGLE_COMPLICATION_ADMIN_FAIL, data: error });
  }
}

function* getSingleComplicationAdminSaga() {
  yield takeEvery(GET_SINGLE_COMPLICATION_ADMIN, getSingleComplicationAdmin);
}
export default getSingleComplicationAdminSaga;
