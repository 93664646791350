export const GET_SINGLE_COMPLICATION_ADMIN = 'complication/GET_SINGLE_COMPLICATION_ADMIN';
export const GET_SINGLE_COMPLICATION_ADMIN_SUCCESS = 'complication/GET_SINGLE_COMPLICATION_ADMIN_SUCCESS';
export const GET_SINGLE_COMPLICATION_ADMIN_FAIL = 'complication/GET_SINGLE_COMPLICATION_ADMIN_FAIL';

const initialState = {
  isProcessing: false,
  isProcessed: false,
  data: {},
  message: '',
  error: '',
};

const getSingleComplicationAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SINGLE_COMPLICATION_ADMIN: {
      const newComplication = {
        slug: action.data.slug,
        data: {},
      };
      return {
        ...state,
        isProcessing: true,
        isProcessed: false,
        data: newComplication,
      };
    }
    case GET_SINGLE_COMPLICATION_ADMIN_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        isProcessed: true,
        data: action.data,
      };
    }
    default:
      return state;
  }
};

export const getSingleComplicationAdminAction = data => ({
  type: GET_SINGLE_COMPLICATION_ADMIN,
  data,
});

export default getSingleComplicationAdminReducer;
