import React from 'react';
import { ButtonToggle } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const ShowAllPostsButton = ({ getModelPostListActionWithParam }) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex align-items-center justify-content-center p-4">
      <ButtonToggle outline color="secondary" size="sm" onClick={getModelPostListActionWithParam}>
        {t('showAllPostsButton.showPostsInOtherLanguages')}
      </ButtonToggle>
    </div>
  );
};

export default ShowAllPostsButton;
