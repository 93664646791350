import API from 'coreModule/infrastructure/api';
import convertRelatedModelsToBadgeFriendly from 'postModule/helpers/convertRelatedModelsToBadgeFriendly';

export const getPostListCall = () => (
  API.request({
    method: 'get',
    url: '/posts',
  })
    .then((response) => {
      if (response.data.relatedModels) {
        response.data.relatedModels = convertRelatedModelsToBadgeFriendly(response.data.relatedModels);
      }

      return ({ response });
    })
    .catch(error => ({ error: error?.response?.data?.error })));
