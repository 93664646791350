import React, { useEffect } from 'react';
import getSlug from 'commonModule/helpers/getSlug';
import modelEnums from 'commonModule/infrastructure/enums/modelEnums';
import ModelPageContent from 'commonModule/components/ModelPageContent';

import './styles.scss';


const PreventionPage = ({
  getSinglePreventionReducer, addPostReducer, addSubPostReducer, getSinglePreventionAction, history, addPostResetAction, addSubPostResetAction, getModelPostListReducer,
}) => {
  useEffect(() => {
    if (addPostReducer.isProcessed || addSubPostReducer.isProcessed) {
      getSinglePreventionAction({ slug: getSlug(history.location.pathname) });
      return () => {
        addPostResetAction();
        addSubPostResetAction();
      };
    }
  }, []);

  const {
    _id = '', name = '', diseases = [], slug = '',
  } = getSinglePreventionReducer.data;

  return (
    <ModelPageContent slug={slug} _id={_id} name={name} diseases={diseases} posts={getModelPostListReducer.data || []} targetModel={modelEnums.Prevention} />
  );
};

export default PreventionPage;
