import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import Layout from 'commonModule/components/Layout';
import getSlug from 'commonModule/helpers/getSlug';

const AdminUpdatePreventionPage = (
  {
    updatePreventionAction,
    getSinglePreventionAdminAction,
    history,
    getSinglePreventionAdminReducer,
    updatePreventionReducer,
  },
) => {
  const initialState = {
    formData: new FormData(),
    processedLanguageCode: '',
    prevention: {},
    slug: getSlug(history.location.pathname),
  };
  const [values, setValues] = useState(initialState);
  useEffect(() => {
    getSinglePreventionAdminAction({ slug: getSlug(history.location.pathname), shouldGetFullData: true });
  }, [updatePreventionReducer.isProcessed]);

  useEffect(() => {
    if (getSinglePreventionAdminReducer.isProcessed || updatePreventionReducer.isProcessed) {
      setValues({ ...values, prevention: getSinglePreventionAdminReducer.data });
    }
  }, [getSinglePreventionAdminReducer.isProcessed, updatePreventionReducer.isProcessed]);

  const {
    formData, prevention, slug, processedLanguageCode,
  } = values;

  if (!getSinglePreventionAdminReducer.isProcessed || isEmpty(prevention)) {
    return null;
  }

  const handleChange = (name, value, languageCode) => {
    if (languageCode) {
      const isExistingLanguageData = prevention.language.find(preventionLanguageData => preventionLanguageData.languageCode === languageCode);
      if (!isExistingLanguageData) {
        const newLanguageData = {
          languageCode,
          [name]: value,
        };
        return setValues({ ...values, prevention: { ...prevention, language: [...prevention.language, newLanguageData] } });
      }

      const preventionLanguages = prevention.language.map(languageData => {
        if (languageData.languageCode === languageCode) {
          return {
            ...languageData,
            [name]: value,
          };
        }

        return languageData;
      });
      return setValues({ ...values, prevention: { ...prevention, language: preventionLanguages } });
    }
  };

  const handleSubmit = (e, languageCode) => {
    e.preventDefault();
    const preventionLanguageDataToUpdate = prevention.language.find(preventionLanguageData => preventionLanguageData.languageCode === languageCode);
    Object.keys(preventionLanguageDataToUpdate).forEach(key => {
      formData.set([key], preventionLanguageDataToUpdate[key]);
    });
    setValues({ ...values, processedLanguageCode: languageCode });
    updatePreventionAction({ slug, updatedPreventionData: formData });
  };

  const showError = () => (updatePreventionReducer.error ? <div className="alert alert-danger">{updatePreventionReducer.error}</div> : '');

  const renderFormForEachLanguage = () => {
    const supportedLanguagesArray = process.env.RAZZLE_SUPPORTED_LANGUAGES.split(',');
    return supportedLanguagesArray.map(languageCode => renderNewForm(prevention?.language
      ?.find(prevention => prevention.languageCode === languageCode), languageCode));
  };

  const renderNewForm = (preventionData = {}, languageCode) => (
    <>
      <form onSubmit={e => handleSubmit(e, languageCode)}>
        <div className="form-group">
          <label className="text-muted">Language code</label>
          <input
            type="text"
            disabled
            className="form-control"
            value={languageCode}
            required
          />
        </div>
        <div className="form-group">
          <label className="text-muted">{`${languageCode} Prevention name`}</label>
          <input
            type="text"
            className="form-control"
            onChange={e => handleChange('name', e.target.value, languageCode)}
            value={preventionData.name}
            required
          />
        </div>
        {(updatePreventionReducer.isProcessed && processedLanguageCode === languageCode) && <p className="text-success">Prevention is updated</p>}
        <div>
          <button type="submit" className="btn btn-primary">
						Update
          </button>
        </div>
      </form>
      <hr />
    </>
  );

  return (
    <Layout>
      <h2 className="text-center pt-4 pb-4">Update prevention</h2>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            {showError()}
            {renderFormForEachLanguage()}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AdminUpdatePreventionPage;
