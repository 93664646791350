export const GET_SINGLE_PREVENTION = 'disease/GET_SINGLE_PREVENTION';
export const GET_SINGLE_PREVENTION_SUCCESS = 'disease/GET_SINGLE_PREVENTION_SUCCESS';
export const GET_SINGLE_PREVENTION_FAIL = 'disease/GET_SINGLE_PREVENTION_FAIL';
export const GET_SINGLE_PREVENTION_RESET = 'disease/GET_SINGLE_PREVENTION_RESET';

const initialState = {
  isProcessing: false,
  isProcessed: false,
  data: {},
  message: '',
  error: '',
};

const getSinglePreventionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SINGLE_PREVENTION: {
      const newPrevention = {
        slug: action.data.slug,
        data: [],
      };

      return {
        ...state,
        isProcessing: true,
        isProcessed: false,
        data: newPrevention,
      };
    }
    case GET_SINGLE_PREVENTION_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
        isProcessed: true,
        data: action.data,
      };
    }
    case GET_SINGLE_PREVENTION_FAIL:
    case GET_SINGLE_PREVENTION_RESET: {
      return {
        ...state,
        isProcessing: false,
        isProcessed: false,
        data: {},
        message: '',
        error: action?.data || '',
      };
    }
    default:
      return state;
  }
};

export const getSinglePreventionAction = data => ({
  type: GET_SINGLE_PREVENTION,
  data,
});

export const getSinglePreventionResetAction = () => ({
  type: GET_SINGLE_PREVENTION_RESET,
});

export default getSinglePreventionReducer;
