import { put, takeEvery, call } from 'redux-saga/effects';
import {
  GET_MODEL_POST_LIST,
  GET_MODEL_POST_LIST_SUCCESS,
  GET_MODEL_POST_LIST_FAIL,
} from 'postModule/redux/modules/getModelPostListModule';
import { getModelPostListCall } from 'postModule/infrastructure/getModelPostListLogic';

function* getModelPostList(action) {
  const { response, error } = yield call(getModelPostListCall, action.data);

  if (response) {
    yield put({ type: GET_MODEL_POST_LIST_SUCCESS, data: response.data });
  } else {
    yield put({ type: GET_MODEL_POST_LIST_FAIL, data: error });
  }
}

function* getModelPostListSaga() {
  yield takeEvery(GET_MODEL_POST_LIST, getModelPostList);
}
export default getModelPostListSaga;
