import modelEnums from 'commonModule/infrastructure/enums/modelEnums';

export const SLUGS = {
  en: {
    [modelEnums.Cause]: process.env.RAZZLE_CAUSE_SLUG__EN,
    [modelEnums.Diagnosis]: process.env.RAZZLE_DIAGNOSIS_SLUG__EN,
    [modelEnums.Prevention]: process.env.RAZZLE_PREVETION_SLUG__EN,
    [modelEnums.Complication]: process.env.RAZZLE_COMPLICATION_SLUG__EN,
    [modelEnums.Symptom]: process.env.RAZZLE_SYMPTOM_SLUG__EN,
    [modelEnums.Category]: process.env.RAZZLE_CATEGORY_SLUG__EN,
    [modelEnums.Post]: process.env.RAZZLE_POST_SLUG__EN,
    [modelEnums.Disease]: process.env.RAZZLE_DISEASE_SLUG__EN,
    [modelEnums.Treatment]: process.env.RAZZLE_TREATMENT_SLUG__EN,
    ADD_POST_SLUG: process.env.RAZZLE_ADD_POST_SLUG__EN,
    SIGN_UP_SLUG: process.env.RAZZLE_SIGN_UP_SLUG__EN,
    LOG_IN_SLUG: process.env.RAZZLE_LOG_IN_SLUG__EN,
    PROFILE_SLUG: process.env.RAZZLE_PROFILE_SLUG__EN,
    SEARCH_RESULTS_SLUG: process.env.RAZZLE_SEARCH_RESULTS_SLUG__EN,
    PRIVACY_POLICY_SLUG: process.env.RAZZLE_PRIVACY_POLICY_SLUG__EN,
    CONTACT_SLUG: process.env.RAZZLE_CONTACT_SLUG__EN,
  },
  pl: {
    [modelEnums.Cause]: process.env.RAZZLE_CAUSE_SLUG__PL,
    [modelEnums.Diagnosis]: process.env.RAZZLE_DIAGNOSIS_SLUG__PL,
    [modelEnums.Prevention]: process.env.RAZZLE_PREVETION_SLUG__PL,
    [modelEnums.Complication]: process.env.RAZZLE_COMPLICATION_SLUG__PL,
    [modelEnums.Symptom]: process.env.RAZZLE_SYMPTOM_SLUG__PL,
    [modelEnums.Category]: process.env.RAZZLE_CATEGORY_SLUG__PL,
    [modelEnums.Post]: process.env.RAZZLE_POST_SLUG__PL,
    [modelEnums.Disease]: process.env.RAZZLE_DISEASE_SLUG__PL,
    [modelEnums.Treatment]: process.env.RAZZLE_TREATMENT_SLUG__PL,
    ADD_POST_SLUG: process.env.RAZZLE_ADD_POST_SLUG__PL,
    SIGN_UP_SLUG: process.env.RAZZLE_SIGN_UP_SLUG__PL,
    LOG_IN_SLUG: process.env.RAZZLE_LOG_IN_SLUG__PL,
    PROFILE_SLUG: process.env.RAZZLE_PROFILE_SLUG__PL,
    SEARCH_RESULTS_SLUG: process.env.RAZZLE_SEARCH_RESULTS_SLUG__PL,
    PRIVACY_POLICY_SLUG: process.env.RAZZLE_PRIVACY_POLICY_SLUG__PL,
    CONTACT_SLUG: process.env.RAZZLE_CONTACT_SLUG__PL,
  },
};
