import React from 'react';
import withAuth from 'authModule/hocs/withAuth';
import AdminAddCategoryPage from 'categoryModule/components/pages/AdminAddCategoryPage';
import { getCategoryListAction } from 'categoryModule/redux/modules/getCategoryListModule';
import { addCategoryAction } from 'categoryModule/redux/modules/addCategoryModule';
import { connect } from 'react-redux';

const AdminAddCategoryPageContainer = props => <AdminAddCategoryPage {...props} />;

const mapStateToProps = ({ category: { getCategoryListReducer, addCategoryReducer } }) => ({
  getCategoryListReducer,
  addCategoryReducer,
});

const mapDispatchToProps = {
  getCategoryListAction,
  addCategoryAction,
};

export default withAuth(connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminAddCategoryPageContainer));
